import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Form, FormGroup, Col, Input, Button } from 'reactstrap';
import {
    getLatestEvents,
    putActionProof,
    getSearchresults,
    passwordlessSignup,
    getUserCredits,
    registerProof,
    recaptchaCheck,
} from '../../api';
import { GridApi } from 'ag-grid-community';
import { format } from 'date-fns';

import device from '../../images/device.svg';
import documentIcon from '../../images/document.svg';
import polygon from '../../images/polygon.svg';
import lock from '../../images/lock.svg';
import userCheck from '../../images/user-check.svg';
import chevron from '../../images/chevrons-right.svg';
import dollarSign from '../../images/dollar-sign.svg';
import arrow from '../../images/Arrow.png';

import UserContext from '../../contexts/user';
import AuthPanel from '../Auth/panel';
import Modal from 'reactstrap/lib/Modal';
import { AuthContainerSelfControlled } from '../Auth';

import Grid from '../Grid';
import { IProof } from '../../types';
import appLoginDispatcher from '../../contexts/app-dispatch';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import InputGroup from 'reactstrap/lib/InputGroup';
import CanRegisterProofContext from '../../contexts/can-register-proof';

import './styles.css';
import WithHeaderAndFooter from '../WithHeaderAndFooter';
import { DeviceRegisteredPopup } from '../DeviceRegisteredPopup';
import { withRouter } from 'react-router';
import NotEnoughFundsPopup from '../NotEnoughFundsPopup';
import { PROOF_ACTION_PRICE, RECAPTCHA_SITE_KEY, NETWORK } from '../../constants';
import UserCreditContext from '../../contexts/user-credit';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import ReactGA from 'react-ga';

loadReCaptcha(RECAPTCHA_SITE_KEY);
function useFormField(initialValue: string = '') {
    const [value, setValue] = useState(initialValue);

    function onChange(event: any) {
        setValue(event.target.value);
    }

    return {
        value,
        onChange,
    };
}
TimeAgo.locale(en);
let timeout: any;

const latestProofsColumnDefs = [
    {
        headerName: 'Transaction hash',
        field: 'txnHash',
        cellRenderer: (params: any) => {
            const a = document.createElement('a');
            let href;
            if (NETWORK === 'mainnet') {
                href = `https://etherscan.io/tx/${params.value}`;
            } else {
                href = `https://${params.data.network}.etherscan.io/tx/${params.value}`;
            }
            a.setAttribute('href', href);
            a.setAttribute('target', 'blank');
            a.classList.add('truncate-hash');
            a.textContent = params.value;
            return a;
        },
    },
    {
        headerName: 'TimeStamp',
        field: 'timestamp',
        cellRenderer: (params: any) => {
            const timeAgo = new TimeAgo('en-US');
            const time = timeAgo.format(params.value * 1000);
            const div = document.createElement('div');
            const fullDate = format(new Date(params.value * 1000), 'MMM-DD-YYYY hh:mm:ss A ');
            div.setAttribute('title', new Date().toDateString());
            div.textContent = `${time} (${fullDate})`;
            return div;
        },
    },
];

export function Home(props: any) {
    const user = useContext(UserContext);
    const credit = useContext(UserCreditContext);
    let canRegisterProof = useContext(CanRegisterProofContext);
    const email = useFormField('');
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [showRegistrationPopup, setShowRegistrationPopup] = useState(false);
    const [showCheck, setShowCheck] = useState(true);
    const [canRegister, setCanRegister] = useState(canRegisterProof);
    const [showAddon, setShowAddon] = useState(false);
    const [chalangingUser, setChalangingUser] = useState();
    const [afterSignupProofCreationParams, setAfterSignupProofCreationParams] = useState();
    const [showDeviceRegisteredPopup, setShowDeviceRegisteredPopup] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [showNotEnoughFundsPopup, setShowNotEnoughFundsPopup] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState();
    const [showLoaderMask, setShowLoaderMask] = useState(false);

    const dispatch = useContext(appLoginDispatcher)!;
    const registrationProofId = useFormFieldReg();

    let recaptchaInstance: any;
    let params: Partial<IProof> = {};
    const executeCaptcha = async function() {
        const a = await recaptchaInstance.execute(RECAPTCHA_SITE_KEY, { action: 'registerProof' });
    };

    function onLoadRecaptcha() {
        executeCaptcha();
    }



    ReactGA.event({
      category: 'User',
      action: 'homePage'
    });

    async function verifyCallback(response: any) {
        setRecaptchaToken(response);
    }

    function useFormFieldReg(initialValue: string = '') {
        const [value, setValue] = useState(initialValue);

        function onChange(event: any) {
            setValue(event.target.value);
            const val = event.target.value;
            clearTimeout(timeout);
            setShowAddon(false);
            setShowSpinner(true);

            timeout = setTimeout(function() {
                console.log('Input Value:', val);
                doSearch(val);
            }, 2000);
        }

        return {
            value,
            onChange,
        };
    }

    async function doSearch(proofId: string) {
        if (proofId == '') {
            setShowAddon(false);
            setShowSpinner(false);
            return;
        }

        const searchResult = await getSearchresults(proofId);
        setShowCheck(searchResult.Count === 0);
        setShowAddon(true);
        setShowSpinner(false);
    }

    function onGridReady(params: any) {
        setGridApi(params.api);
    }

    function onGetStartedButtonClick() {
      if (user) {

        props.history.push('/my-proofs');

      } else {

        props.history.push('/sign-up');
      }

    }


    async function onProofRegisterSubmit(event: any) {
        event.preventDefault();

        if (user) {
            if (!validateForm()) {
                return;
            }
            if (credit.availableCredit! < PROOF_ACTION_PRICE) {
                setShowNotEnoughFundsPopup(true);
                return;
            }
            params = { reToken: recaptchaToken, txStatus: 'created', txMessage: registrationProofId.value };
            dispatch({ type: 'SHOW_LOADER' });
            try {
                try{
                    const checkRecaptcha = await recaptchaCheck(RECAPTCHA_SITE_KEY!,recaptchaToken);
                    await registerProof(params);
                }
                catch(e){
                    setShowLoaderMask(false);
                    dispatch({
                        type: 'SHOW_MESSAGE',
                        payload: { message: 'Something went wrong...Please contact support', error: true },
                    });
                }

                setCanRegister(false);
                setShowDeviceRegisteredPopup(true);
                const userCredit = await getUserCredits();
                dispatch({ type: 'SET_USER_CREDITS', payload: userCredit });
            } catch (e) {
                setShowLoaderMask(false);
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: { message: 'Something went wrong...Please contact support', error: true },
                });
            }
        } else {
            setShowLoaderMask(true);
            signUpUser();
        }
    }

    async function signUpUser() {
        if (!validateForm()) {
            return;
        }
        const params: Partial<IProof> = {
            txStatus: 'created',
            txHash: 'Pending',
            txMessage: registrationProofId.value,
        };
        setAfterSignupProofCreationParams(params);
        try {
            const result = await passwordlessSignup(email.value, 'John Doe');
            setChalangingUser(result);
            setShowLoaderMask(false);
            setShowRegistrationPopup(true);
        } catch (e) {
            setShowLoaderMask(false);
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Account with this email address already exist. Please login with your email address. ', error: true },
            });
        }
    }

    function validateForm() {
        let isFormValid = true;
        if (registrationProofId.value == '') {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'All fields are required', error: true },
            });
            isFormValid = false;
        }
        if (!showCheck) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Device with the same Serial Number already exist', error: true },
            });
            isFormValid = false;
        }

        return isFormValid;
    }

    useEffect(() => {
        (async () => {
            if (!gridApi) {
                return;
            }
            try {
                const result = await getLatestEvents();
                gridApi.setRowData(result.Items as any);
            } catch (e) {
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: { message: 'Error while getting data', error: true },
                });
                console.log('error');
            }
        })();
    }, [gridApi]);

    function toggleRegistrationModal() {
        setShowRegistrationPopup(value => !value);
    }

    const options = {
        onGridReady: onGridReady,
        columnDefs: latestProofsColumnDefs,
    };
    console.log(arrow);
    return (
        <WithHeaderAndFooter>



          <div className="container text-center banner-div">
                     <h1>
                         <span className="h1-prove">Create </span><span className="small-text gray">any certificate in blockchain secured format.</span>
                     </h1 >
          </div>
            <div className="container align-items-stretch d-flex justify-content-center mx-auto mb-3">
                <Col className="text-center" lg="2" md="3" sm="3" xs="3">
                    <div className="image-container">
                        <img className="mx-auto d-block" src={device} />
                    </div>
                </Col>
                <Col
                    className="image-container"
                    lg="3"
                    md="1"
                    sm="1"
                    xs="1"
                    style={{ backgroundImage: `url(${arrow})` }}
                />
                <Col className="text-center" lg="2" md="3" sm="3" xs="3">
                    <div className="image-container">
                        <img className="mx-auto d-block" src={polygon} />
                    </div>
                </Col>
                <Col
                    className="image-container"
                    lg="3"
                    md="1"
                    sm="1"
                    xs="1"
                    style={{ backgroundImage: `url(${arrow})` }}
                />
                <Col className="text-center" lg="2" md="3" sm="3" xs="3">
                    <div className="image-container">
                        <img className="mx-auto d-block" src={documentIcon} />
                    </div>
                </Col>
            </div>
            <div className="container align-items-stretch d-flex justify-content-center mx-auto mb-3">
                <Col className="text-center" lg="2" md="4" sm="4" xs="4">
                    <span className="small-text blue-text">1. Drag & drop your certificate in pdf or image format</span>
                </Col>

                <Col className="d-none d-lg-block" lg="3" />

                <Col className="text-center" lg="2" md="4" sm="4" xs="4">
                    <span className="small-text blue-text">2. Digital certificate created and anchored on the blockchain</span>
                </Col>

                <Col lg="3" className="d-none d-lg-block" />

                <Col className="text-center" lg="2" md="4" sm="4" xs="4">
                    <span className="small-text blue-text">3. Easily sharable and instantly verifiable for lifetime</span>
                </Col>
            </div>



            <div className="container mt-5">
                            <AuthPanel notAuthRegisterMode>
                                <Form
                                    onSubmit={onGetStartedButtonClick}
                                    className={user ? 'justify-content-center mx-auto row pt-3' : 'justify-content-center mx-auto row pt-3'}
                                >


                                    <Col xs={12} sm={12} md={12} lg={4}>
                                        <FormGroup>
                                                    <Button type="submit" className="btn btn-primary mx-auto w-100">
                                                        {user ? 'Upload' : 'Get Started for Free'}
                                                    </Button>



                                        </FormGroup>
                                    </Col>
                                </Form>
                            </AuthPanel>
            </div>

            <div className="text-center mt-3" >
              <span className="small-text gray" >
                  Have you seen a blockchain verifiable certificate? <a href="https://verify.proofplum.com/sample">Click here </a> to see one.
              </span>
            </div>

            <div className="container proof-benefits row mx-auto landing-page-bottom" >
                <Col className="pt-5" lg="6" md="12" sm="12" xs="12">
                    <img src={dollarSign} />

                    <h2>No monthly fee.</h2>
                    <ul>
                        <li className="mb-3 gray-background-element">First 5 certificates are created for FREE.</li>
                        <li className="mb-3 gray-background-element">Each additional certificate costs $1.</li>
                        <li className="mb-3 gray-background-element">
                            No monthly or annual subscriptions and no hidden fees.
                        </li>
                    </ul>
                </Col>

                <Col className="pt-5" lg="6" md="12" sm="12" xs="12">
                    <img src={chevron} />
                    <h2>Easy to use.</h2>
                    <ul>
                        <li className="mb-3 gray-background-element">
                            No cryptocurrency or wallets required.
                        </li>
                        <li className="mb-3 gray-background-element">
                            Organize and store all your certificates in one place.
                        </li>
                        <li className="mb-3 gray-background-element">
                            No password to forget. Use login code everytime.{' '}
                        </li>
                    </ul>
                </Col>



            </div>






            {showRegistrationPopup ? (
                <Modal toggle={toggleRegistrationModal} isOpen={true}>
                    <AuthContainerSelfControlled
                        isPopup
                        modalToggle={toggleRegistrationModal}
                        notAuthRegisterMode
                        defaultState="signup"
                        initialEmail={email.value}
                        returnedUser={chalangingUser}
                        afterSignupProofCreationParams={afterSignupProofCreationParams}
                        shouldRedirectAfterSignUp={false}
                    />
                </Modal>
            ) : (
                ''
            )}
            {showDeviceRegisteredPopup ? (
                <DeviceRegisteredPopup
                    name={registrationProofId.value}
                    modalToggle={() => {
                        setShowDeviceRegisteredPopup(false);
                        props.history.push('/my-proofs');
                    }}
                />
            ) : (
                ''
            )}
            {showNotEnoughFundsPopup ? <NotEnoughFundsPopup /> : ''}
            {showLoaderMask ? (
                <div className="attachment-mask">
                    <div className="text-center fa-5x px-2 pt-1">
                        <i className="fas fa-circle-notch fa-spin primary blue-text" />
                    </div>
                </div>
            ) : (
                ''
            )}
        </WithHeaderAndFooter>
    );
}

export default withRouter(Home);
