import React, { useState } from 'react';
import AuthPanel from './panel';
import LoginForm from './login-form';
import SignupForm from './signup-form';
import ViewState from './types';
import { changePassword } from '../../api';
import AnswerChalage from './answer-chalange';
import { IProof } from '../../types';

export function AuthContainer({
    viewState,
    changeState,
    initialEmail,
    notAuthRegisterMode,
    modalToggle,
    returnedUser,
    afterSignupProofCreationParams,
    shouldRedirectAfterSignUp,
    isPopup,
}: {
    viewState: ViewState;
    initialEmail?: string;
    changeState: (state: ViewState) => void;
    notAuthRegisterMode?: boolean;
    modalToggle?: () => void;
    returnedUser: any;
    afterSignupProofCreationParams: Partial<IProof>;
    shouldRedirectAfterSignUp?: boolean;
    isPopup?: boolean;
}) {
    if (viewState === 'signin') {
        return (
            <AuthPanel notAuthRegisterMode={notAuthRegisterMode} title="Login">
                <LoginForm initialEmail={initialEmail} />
            </AuthPanel>
        );
    }

    if (viewState === 'signup') {
        return (
            <AuthPanel isPopup={isPopup} notAuthRegisterMode={notAuthRegisterMode} title="Sign Up">
                <SignupForm
                    shouldRedirectAfterSignUp={shouldRedirectAfterSignUp}
                    afterSignupProofCreationParams={afterSignupProofCreationParams}
                    returnedUser={returnedUser}
                    modalToggle={modalToggle}
                    changeState={changeState}
                    initialEmail={initialEmail}
                />
            </AuthPanel>
        );
    }
    return null;
}

export function AuthContainerSelfControlled({
    defaultState,
    initialEmail,
    notAuthRegisterMode,
    modalToggle,
    returnedUser,
    afterSignupProofCreationParams,
    shouldRedirectAfterSignUp,
    isPopup,
}: {
    defaultState?: ViewState;
    initialEmail?: string;
    notAuthRegisterMode?: boolean;
    modalToggle?: () => void;
    returnedUser?: any;
    afterSignupProofCreationParams?: any;
    shouldRedirectAfterSignUp?: boolean;
    isPopup?: boolean;
}) {
    const [viewState, setViewState] = useState<ViewState>(defaultState || 'signin');
    function changeState(state: ViewState) {
        setViewState(state);
    }

    return (
        <AuthContainer
            notAuthRegisterMode={notAuthRegisterMode}
            viewState={viewState}
            returnedUser={returnedUser}
            changeState={changeState}
            initialEmail={initialEmail}
            modalToggle={modalToggle}
            afterSignupProofCreationParams={afterSignupProofCreationParams}
            shouldRedirectAfterSignUp={shouldRedirectAfterSignUp}
            isPopup={isPopup}
        />
    );
}
