import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default function WithHeaderAndFooter(props: any) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                minHeight: '100vh',
            }}
        >
            <Header />
            <div
                className="page-wrapper"
                style={{
                    flex: '1',
                }}
            >
                {props.children}
            </div>
            <Footer />
        </div>
    );
}
