import React, { useContext, useState } from 'react';
import Form from 'reactstrap/lib/Form';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import UserContext from '../../contexts/user';
import Modal from 'reactstrap/lib/Modal';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { userUpdate } from '../../api';
import AppLoginDispatcherContext from '../../contexts/app-dispatch';

function useFormField(initialValue: string = '') {
    const [value, setValue] = useState(initialValue);
    function onChange(event: any) {
        setValue(event.target.value);
    }
    return {
        value,
        onChange,
    };
}

export default function UserAccount() {
    const user = useContext(UserContext);
    const email = useFormField(user!.email);
    let savedUserFirstName = '';
    let savedUserLastName = '';
    if (user!.fullName) {
        savedUserFirstName = user!.fullName.split(' ')[0];
        savedUserLastName = user!.fullName.split(' ')[1];
    }
    const firstName = useFormField(savedUserFirstName);
    const lastName = useFormField(savedUserLastName);
    const emailForDeleteUser = useFormField();
    const dispatch = useContext(AppLoginDispatcherContext)!;
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);


    function validateForm() {
        let isFormValid = true;
        if (firstName.value.length == 0 || lastName.value.length == 0) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Please fill all fields', error: true },
            });
            isFormValid = false;
        }
        return isFormValid;
    }

    async function onSubmit(event: any) {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        const response = await userUpdate(firstName.value, lastName.value);
        dispatch({ type: 'UPDATE_USER', payload: { email: email.value, username: user!.username, fullName: `${firstName.value} ${lastName.value}`} }  );

    }

    function toggle() {
        setShowDeleteUserModal(prevState => !prevState);
    }

    function onDeleteUserAccountSubmin() {}

    return (
        <Row>
            <Col sm="12" md="6" lg="4">
                <div className="mt-5">
                    <span className="grid-request-info gray">PHOTO</span>
                    <br />
                    <i className="far fa-user fa-5x mt-3 mb-3" />
                    <br />
                    <input className="btn gray-background-btn mb-3" type="file" />
                    <Form onSubmit={onSubmit} className="mb-4">
                        <FormGroup>
                            <Label className="grid-request-info gray">EMAIL</Label>
                            <Input value={email.value} onChange={email.onChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label className="grid-request-info gray">FIRST NAME</Label>
                            <Input value={firstName.value} onChange={firstName.onChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label className="grid-request-info gray">LAST NAME</Label>
                            <Input value={lastName.value} onChange={lastName.onChange} />
                        </FormGroup>
                        <FormGroup>
                            <Button color="primary" type="submit" className="mr-3">
                                Update
                            </Button>
                            <Button color="btn gray-background-btn">Cancel</Button>
                        </FormGroup>
                    </Form>
                    
                </div>
                {showDeleteUserModal ? (
                    <Modal toggle={toggle} isOpen={true}>
                        <ModalBody>
                            <span className="small-gray mb-3">
                                This action can’t be undone. It will permanently delete your account, data, and keys for
                                your data. That makes the encrypted data stored in blockchain non-decryptable.
                            </span>
                            <br />
                            <span className="small-gray font-weight-bold ">
                                Please type in your email address to confirm.
                            </span>
                            <Form onSubmit={onDeleteUserAccountSubmin} className="w-100 mx-auto mt-4">
                                <FormGroup>
                                    <Label className="grid-request-info gray">YOUR EMAIL</Label>
                                    <Input value={emailForDeleteUser.value} onChange={emailForDeleteUser.onChange} />
                                </FormGroup>
                                <FormGroup>
                                    <Button type="submit" className="btn grid-btn-red mr-3 border-0">
                                        Delete my account
                                    </Button>
                                    <Button className="btn gray-background-btn" onClick={toggle}>
                                        Cancel
                                    </Button>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                    </Modal>
                ) : (
                    ''
                )}
            </Col>
        </Row>
    );
}
