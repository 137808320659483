import { Button } from 'reactstrap';
import React from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { BlokchainDetails } from '../BlockchainDetails';
import { withRouter, RouteComponentProps } from 'react-router';

export interface INotEnoughFundsProps extends RouteComponentProps {}
function NotEnoughFundsPopup({ history }: RouteComponentProps) {
    return (
        <Modal isOpen={true} size="xs">
            <ModalBody>
                <div className="container justify-content-center">
                    <div
                        className="row justify-content-center text-center"
                        style={{
                            fontSize: '24px',
                        }}
                    >
                        You do not have credit to execute this operation.
                        
                    </div>

                    <div className="row mt-2 justify-content-center">
                        <Button
                            color="primary"
                            onClick={() => {
                                history.push('/billing');
                            }}
                        >
                            Go to billing
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default withRouter(NotEnoughFundsPopup);
