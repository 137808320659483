import React, { ReactElement, useContext, useMemo, useState } from 'react';
import { updateProof, updateTransfer, getUserCredits } from '../../api';

import ShadowTitle from '../ShadowText';
import Grid from '../Grid';
import UserProofsContext from '../../contexts/user-proofs';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import Header from '../Header';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import FormGroup from 'reactstrap/lib/FormGroup';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import appLoginDispatcher from '../../contexts/app-dispatch';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import { GridApi, RowClickedEvent } from 'ag-grid-community';
import WithHeaderAndFooter from '../WithHeaderAndFooter';
import UserCreditContext from '../../contexts/user-credit';
import { PROOF_ACTION_PRICE } from '../../constants';
import NotEnoughFundsPopup from '../NotEnoughFundsPopup';
import { DeviceDetails } from '../DeviceDetails';
import { RequestedProofDetails } from './requested-proof-details';
import { IProof } from '../../types';
import ReactGA from 'react-ga';

TimeAgo.locale(en);

export default function MyPendingRequests() {
    const userData = useContext(UserProofsContext);
    const credit = useContext(UserCreditContext);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [approveModalText, setApproveModalText] = useState<ReactElement>(<></>);
    const [requestParams, setRequestParams] = useState();
    const dispatch = useContext(appLoginDispatcher)!;
    const filterInputIncoming = useFormFieldFilterIncoming();
    const [incomingGridApi, setIncomingGridApi] = useState<GridApi | null>(null);
    const filterInputOutgoing = useFormFieldFilterOutgoing();
    const [outgoingGridApi, setOutgoingGridApi] = useState<GridApi | null>(null);
    const [showNotEnoughFundsPopup, setShowNotEnoughFundsPopup] = useState(false);
    const [deviceDetailModal, setDeviceDetailModal] = useState(false);
    const [selectedProof, setSelectedProof] = useState(null);

    ReactGA.event({
      category: 'User',
      action: 'requests'
    });

    function toggle() {
        setShowApproveModal(prevState => !prevState);
    }

    function onGridReadyIncoming(params: any) {
        setIncomingGridApi(params.api);
    }

    function onGridReadyOutgoing(params: any) {
        setOutgoingGridApi(params.api);
    }

    function deviceModalToggle() {
        setDeviceDetailModal(prevState => !prevState);
    }

    function useFormFieldFilterIncoming(initialValue: string = '') {
        const [value, setValue] = useState(initialValue);

        function onChange(event: any) {
            setValue(event.target.value);
            if (!incomingGridApi) {
                return;
            }
            incomingGridApi.setQuickFilter(event.target.value);
        }

        return {
            value,
            onChange,
        };
    }

    function useFormFieldFilterOutgoing(initialValue: string = '') {
        const [value, setValue] = useState(initialValue);

        function onChange(event: any) {
            setValue(event.target.value);
            if (!outgoingGridApi) {
                return;
            }
            outgoingGridApi.setQuickFilter(event.target.value);
        }

        return {
            value,
            onChange,
        };
    }

    async function onApproveTransferOrProof(event: any) {
        event.preventDefault();

        if (requestParams.value == 'proofRequested') {
            try {
                updateProof({
                    txMessage: requestParams.data.txMessage,
                    txStatus: 'proofApproved',
                    timestamp: requestParams.data.timestamp,
                    username: requestParams.data.username,
                });
                dispatch({ type: 'SHOW_MESSAGE', payload: { message: 'Details request approved', error: false } });
            } catch (e) {
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: { message: 'Problem while approving transfer', error: true },
                });
            }
        } else {
            try {
                updateTransfer({
                    txMessage: requestParams.data.txMessage,
                    txStatus: 'transferApproved',
                    timestamp: requestParams.data.timestamp,
                    username: requestParams.data.username,
                });
                dispatch({ type: 'SHOW_MESSAGE', payload: { message: 'Transfer approved', error: false } });
            } catch (e) {
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: { message: 'Problem while approving details request', error: true },
                });
            }
        }
        const userCredit = await getUserCredits();
        dispatch({ type: 'SET_USER_CREDITS', payload: userCredit });
        toggle();
    }

    const incomingData = useMemo(
        () =>
            userData.filter(proof => {
                return (
                    (proof.txType === 'requestProof' || proof.txType === 'requestTransfer') &&
                    (proof.txStatus === 'proofRequested' || proof.txStatus === 'transferRequested')
                );
            }),

        [userData]
    );
    const outgoingData = useMemo(() => {
      const approvedDetails = userData.filter(proof => {
          return ( proof.txType === 'approveProof' ) &&
          proof.txStatus !== 'proofRequested' &&
          proof.txStatus !== 'transferRequested'
      });
      const approvedDetailIds = approvedDetails.map(proof => {
          return proof.txMessage;
      })
      const requestedDetail = userData.filter(proof => {
        return approvedDetailIds.indexOf(proof.txMessage) === -1 && ( proof.txType === 'requestProof' ||  proof.txType === 'requestTransfer') &&
        proof.txStatus !== 'proofRequested' &&
        proof.txStatus !== 'transferRequested'
    });

     console.log([...approvedDetails, ...requestedDetail]);
      return [...approvedDetails, ...requestedDetail];
  }, [userData]);

    const incomingRequestsColumnDefs: any = [
        {
            headerName: 'Request Type',
            field: 'txStatus',
            cellRenderer: (params: any) => {
                var div = document.createElement('div');
                if (params.value === 'proofRequested') {
                    // check if pending
                    div.innerText = 'DETAILS';
                    div.classList.add('grid-request-info');
                    div.classList.add('type');
                    div.classList.add('mt-3');

                    return div;
                } else if (params.value === 'transferRequested') {
                    div.innerText = 'TRANSFER';
                    div.classList.add('grid-request-info');
                    div.classList.add('type');
                    div.classList.add('mt-3');

                    return div;
                }
            },
        },
        { headerName: 'Requestor Email', field: 'requestByEmail' },

        { headerName: 'ID', field: 'txMessage' },
        { headerName: 'Name', field: 'txName' },

        {
            headerName: '',
            field: 'txStatus',
            cellRenderer: (params: any) => {
                var button = document.createElement('button');
                if (params.value == 'proofRequested') {
                    button.innerText = 'Approve';
                    button.classList.add('btn');
                    button.classList.add('btn-sm');
                    button.classList.add('grid-btn-green');
                    button.classList.add('btn-block');
                    button.classList.add('mt-3');

                    button.addEventListener('click', e => {
                        if (credit.availableCredit! < PROOF_ACTION_PRICE) {
                            setShowNotEnoughFundsPopup(true);
                            return;
                        }
                        setShowApproveModal(true);
                        const modalContent = (
                            <div className="pt-3">
                                This will give access to {' '}
                                <strong>{params.data.name || params.data.requestByEmail} to view device name, details and add new attachments. Are you sure?</strong>
                                <br/>$1 will be debited from your account.
                            </div>
                        );
                        setApproveModalText(modalContent);
                        setRequestParams(params);
                        (e.target as HTMLTextAreaElement).setAttribute('disabled', 'true');
                    });
                    return button;
                } else if (params.value == 'transferRequested') {
                    button.innerText = 'Approve';
                    button.classList.add('btn');
                    button.classList.add('grid-btn-green');
                    button.classList.add('btn-sm');
                    button.classList.add('btn-block');
                    button.classList.add('mt-3');

                    button.addEventListener('click', e => {
                        if (credit.availableCredit! < PROOF_ACTION_PRICE) {
                            setShowNotEnoughFundsPopup(true);
                            return;
                        }
                        setShowApproveModal(true);
                        const modalContent = (
                            <div className="pt-3">
                                This will permenently transfer current device to{' '}
                                <strong>{params.data.name || params.data.requestByEmail}. Are you sure?</strong>
                            </div>
                        );
                        setApproveModalText(modalContent);
                        setRequestParams(params);
                        (e.target as HTMLTextAreaElement).setAttribute('disabled', 'true');
                    });
                    return button;
                }
            },
        },
    ];

    const outgoingRequestsColumnDefs: any = [
        {
            headerName: 'Request Type',
            field: 'txType',
            width: 70,
            cellRenderer: (params: any) => {
                var div = document.createElement('div');
                if (params.value === 'requestProof' || params.value === 'approveProof') {
                    // check if pending
                    div.innerText = 'DETAILS';
                    div.classList.add('grid-request-info');
                    div.classList.add('type');
                    div.classList.add('mt-3');
                    return div;
                } else if (params.value === 'requestTransfer') {
                    div.innerText = 'TRANSFER';
                    div.classList.add('grid-request-info');
                    div.classList.add('type');
                    div.classList.add('mt-3');

                    return div;
                }
            },
        },
        { headerName: 'ID', field: 'txMessage' },
        { headerName: 'Device', field: 'txName' },
        { headerName: 'Details', field: 'txDetails' },

        {
            headerName: '',
            field: 'txType',
            cellRenderer: (params: any) => {
                var div = document.createElement('div');
                if (
                    params.value === 'requestProof' ||
                    params.value === 'requestTransfer' ||
                    params.value === 'processing'
                ) {
                    // check if pending
                    div.innerText = 'PENDING';
                    div.classList.add('grid-request-info');
                    div.classList.add('orange');
                    div.classList.add('mt-3');

                } else if(params.value === 'approveProof'){
                    div.innerText = 'APPROVED';
                    div.classList.add('grid-request-info');
                    div.classList.add('green');
                    div.classList.add('mt-3');
                }
                    return div;

            },
        },
    ];

    let incomingRequests = {
        data: incomingData,
        columnDefs: incomingRequestsColumnDefs,
    };

    const outgoingRequests = {
        data: outgoingData,
        columnDefs: outgoingRequestsColumnDefs,
    };

    return (
        <>
            <WithHeaderAndFooter>
                <div className="container-fluid proof mt-5">
                    <div className="container mb-5">
                        <h2>Requests</h2>
                    </div>
                    <div className="container">
                        <div className="row  border-bottom">
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <h5 className="">Incoming</h5>
                            </Col>
                            <Col className="text-right align-self-center row" lg={6} md={6} sm={12} xs={12}>
                                <Input
                                    className="border-0 col-4 ml-auto text-right"
                                    type="text"
                                    id="filter-text-box"
                                    placeholder="Search"
                                    value={filterInputIncoming.value}
                                    onChange={filterInputIncoming.onChange}
                                />
                            </Col>
                        </div>
                        <div
                            className="ag-theme-balham mt-3 mb-5"
                            style={{
                                height: '250px',
                            }}
                        >
                            <Grid options={incomingRequests} onGridReady={onGridReadyIncoming} />
                        </div>
                    </div>

                    <div className="container mt-5">
                        <div className="row  border-bottom">
                            <Col lg={6} md={6} sm={6} xs={6}>
                                <h5 className="">Outgoing</h5>
                            </Col>
                            <Col className="text-right align-self-center row" lg={6} md={6} sm={12} xs={12}>
                                <Input
                                    className="border-0 col-4 ml-auto text-right"
                                    type="text"
                                    id="filter-text-box"
                                    placeholder="Search"
                                    value={filterInputOutgoing.value}
                                    onChange={filterInputOutgoing.onChange}
                                />
                            </Col>
                        </div>
                        <div
                            className="ag-theme-balham mt-3 mb-5"
                            style={{
                                height: '250px',
                            }}
                        >
                            <Grid
                                options={outgoingRequests}
                                onRowClicked={(event: RowClickedEvent) => {
                                    if (event.data.txType === 'approveProof') {
                                        setSelectedProof(event.data);
                                        deviceModalToggle();
                                    }
                                }}
                                onGridReady={onGridReadyOutgoing}
                            />
                        </div>
                    </div>
                </div>
            </WithHeaderAndFooter>
            {showApproveModal ? (
                <Modal toggle={toggle} isOpen={true}>
                    <ModalBody>
                        <span className="small-text">{approveModalText}</span>
                        <Form onSubmit={onApproveTransferOrProof} className="w-100 mx-auto text-center mt-4">
                            <FormGroup>
                                <Button type="submit" className="btn grid-btn-green mr-3">
                                    Approve
                                </Button>
                                <Button className="btn gray-background-btn" onClick={toggle}>
                                    Cancel
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            ) : (
                ''
            )}
            {showNotEnoughFundsPopup ? <NotEnoughFundsPopup /> : ''}
            {deviceDetailModal ? <RequestedProofDetails proof={selectedProof} modalToggle={deviceModalToggle} /> : ''}
        </>
    );
}
