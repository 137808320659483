import React from 'react';
import Nav from 'reactstrap/lib/Nav';
import NavItem from 'reactstrap/lib/NavItem';
import NavLink from 'reactstrap/lib/NavLink';
import { withRouter } from 'react-router';

import UserAccount from './account';
import Billing from './billing';
import WithHeaderAndFooter from '../WithHeaderAndFooter';

function AccountSettings({ activeTab = 1, history }: { activeTab?: number; history: any }) {
    return (
        <WithHeaderAndFooter>
            <div className="container mt-4">
                <Nav>
                    <NavItem className="mr-3">
                        <NavLink
                            className={activeTab === 1 ? 'account-settings-tab active' : 'account-settings-tab'}
                            onClick={() => {
                                history.push('/account');
                            }}
                        >
                            Account
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === 2 ? 'account-settings-tab active' : 'account-settings-tab'}
                            onClick={() => {
                                history.push('/billing');
                            }}
                        >
                            Billing
                        </NavLink>
                    </NavItem>
                </Nav>
                {activeTab === 1 ? <UserAccount /> : activeTab === 2 ? <Billing /> : ''}
            </div>
        </WithHeaderAndFooter>
    );
}

export default withRouter(AccountSettings);
