import React, { useContext, useState } from 'react';
import Button from 'reactstrap/lib/Button';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

export interface IFirstTimeMaskProps {
    firstTimeModalToggle?: () => void;
}
export default function FirstTimeMask(props: IFirstTimeMaskProps) {
    return (
        <div className="my-proof-mask">
            <div className="container">
                <Row className="mb-3 ">
                    <Col xs="3" sm="3" md="3" lg="2" className="white-background-text text-center pt-2">
                        <span>Your device name</span>
                    </Col>
                    <Col xs="3" sm="3" md="3" lg="2" className="mx-3" />
                    <Col xs="3" sm="3" md="3" lg="2" className="white-background-text text-center pt-2">
                        <span>Your attachments</span>
                    </Col>
                </Row>
                <div className="row">
                    <span style={{ color: '#ffffff' }}>Add more details to your device so it would be easier</span>
                </div>
                <div className="row">
                    <span style={{ color: '#ffffff' }}>to navigate through them in future.</span>
                </div>
                <Button
                    className="first-time-mask-button mt-3 row"
                    color="primary"
                    onClick={() => props.firstTimeModalToggle!()}
                >
                    Got It!
                </Button>
            </div>
        </div>
    );
}
