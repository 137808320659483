

export const REGION = "us-east-1"
export const USER_POOL_ID = "us-east-1_kwEBQ70fk"
export const APP_CLIENT_ID = "5e5rdq6rfdug5h9hami5lbk32f"
export const IDENTYTY_POOL_ID = "us-east-1:3dde3b2e-3bc3-4861-bbba-fa8bdc88404e"
export const LOGINS = 'cognito-idp.'+REGION+'.amazonaws.com/'+USER_POOL_ID
export const USER_POOL_WEB_CLIENT_ID = 'djokedflq9hnbn48l7n9iujb2';

export const POOL_DATA = {
    UserPoolId : USER_POOL_ID,
    ClientId : APP_CLIENT_ID
};

export const NETWORK = process.env.REACT_APP_NETWORK
export const TABLE_NAME = 'ProofBoxEvents'
export const INDEX_NAME = 'network-timestamp-index'
export const TIME_STAMP = 'timestamp'
