import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import React, { useState, useContext, useMemo, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import {
    uploadAttachments,
    getUserCredits,
    downloadAttachment,
    deleteAttachment,
    uploadAttachmentNew,
    getAttachmentsNew,
} from '../../api';
import validator from 'email-validator';
import appLoginDispatcher from '../../contexts/app-dispatch';
import UserProofsContext from '../../contexts/user-proofs';
import { labelStyle } from '../inline-styles';
import UserCreditContext from '../../contexts/user-credit';
import NotEnoughFundsPopup from '../NotEnoughFundsPopup';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ModalBody from 'reactstrap/lib/ModalBody';
import Modal from 'reactstrap/lib/Modal';
import { NETWORK, PROOF_ACTION_PRICE } from '../../constants';
import { BlokchainDetails } from '../BlockchainDetails';
import { IAttachment } from '../../types';
import UserContext from '../../contexts/user';
import CreatableSelect, { makeCreatableSelect } from 'react-select/creatable';
import ReactTooltip from 'react-tooltip';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
} from 'react-share';

export interface IAttachmentProps {
    modalToggle?: () => void;
    // proofId: string;
    uploadFiles?: any;
}

TimeAgo.locale(en);

function useFormField(initialValue: string = '') {
    const [value, setValue] = useState(initialValue);

    function onChange(event: any) {
        setValue(event.target.value);
    }

    return {
        value,
        onChange,
    };
}

export function AttachmentsForAllProofs(props: IAttachmentProps) {
    const user = useContext(UserContext);
    const userData = useContext(UserProofsContext);
    const credit = useContext(UserCreditContext);
    const attachmentDescription = useFormField();
    const attachmentGivenName = useFormField();
    const [attachmentName, setAttachmentName] = useState();
    const [uploadFiles, setUploadFiles] = useState();
    const dispatch = useContext(appLoginDispatcher)!;
    const [showNotEnoughFundsPopup, setShowNotEnoughFundsPopup] = useState(false);
    const [updateAttachments, setUpdateAttachments] = useState(false);
    const [showLoaderMask, setShowLoaderMask] = useState(false);
    const [showAttachmentDetails, setShowAttachmentDetails] = useState(false);
    const [showDeleteAttachmentPopup, setShowDeleteAttachmentPopup] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState();
    const [showUploadAttachmentDetail, setShowUploadAttachmentDetail] = useState(false);
    const [uploadingAttachmentName, setUploadingAttachmentName] = useState();
    const [attachmentUploadButtonDisabled, setAttachmentUploadButtonDisabled] = useState(false);
    const [attachmentError, setAttachmentError] = useState();
    const [attachmentUploadedPopup, setAttachmentUploadedPopup] = useState(false);
    const [onlyAttachments, setOnlyAttachments] = useState();
    const [attachmentsData, setAttachmentsData] = useState<IAttachment[]>([]);
    const [attachmentsInitialData, setAttachmentsInitialData] = useState<IAttachment[]>([]);
    const recipientOwnerEmail = useFormField(user!.email || '');
    const recipientOwnerName = useFormField(user!.fullName || '');
    const filterInput = useFormFieldFilter();
    const [additionalData, setAdditionalData] = useState([{ title: '', value: '' }]);

    const ONE_HOUR = 60 * 60 * 1000; /* ms */

    async function onAttachmentUploadClick(event: any) {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        setAttachmentUploadButtonDisabled(true);

        setShowLoaderMask(true);
        try {
            await uploadAttachmentNew(
                attachmentGivenName.value,
                uploadFiles[0],
                attachmentDescription.value,
                recipientOwnerEmail.value,
                recipientOwnerName.value,
                additionalData
            );
            setAttachmentUploadedPopup(true);
            setAttachmentUploadButtonDisabled(false);
            setShowLoaderMask(false);
            showAttachmentUploadModalToggle();
            const newAttachment: IAttachment = {
                s3FileDescription: attachmentDescription.value,
                txName: attachmentGivenName.value,
                txStatus: 'processing',
                s3FileName: uploadFiles[0].name,
            };
            attachmentsData.push(newAttachment);
            setAttachmentsData(attachmentsData);
        } catch (e) {
            setShowLoaderMask(false);
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Something went wrong', error: true },
            });
        }

        const userCredit = await getUserCredits();
        dispatch({ type: 'SET_USER_CREDITS', payload: userCredit });
    }

    function validateForm() {
        let isFormValid = true;
        if (
            attachmentDescription.value == '' ||
            attachmentGivenName.value == '' ||
            recipientOwnerEmail.value == '' ||
            recipientOwnerName.value == ''
        ) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'All input fields are required', error: true },
            });
            isFormValid = false;
        }
        if (!validator.validate(recipientOwnerEmail.value)) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Invalid Email', error: true },
            });
            isFormValid = false;
        }

        return isFormValid;
    }

    function useFormFieldFilter(initialValue: string = '') {
        const [value, setValue] = useState(initialValue);

        function onChange(event: any) {
            setValue(event.target.value);

            let filteredAttachments = attachmentsInitialData.filter((attachment: IAttachment) => {
                if (attachment) {
                    return attachment.txName.toLowerCase().indexOf(event.target.value) !== -1;
                }
            });
            setAttachmentsData(filteredAttachments);
        }

        return {
            value,
            onChange,
        };
    }

    function onDrop(acceptedFiles: any) {
        if (credit.availableCreditDecimal! < acceptedFiles.length) {
            setShowNotEnoughFundsPopup(true);
        }
        setShowUploadAttachmentDetail(true);

        let reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        let type = acceptedFiles[0].name.substring(
            acceptedFiles[0].name.lastIndexOf('.') + 1,
            acceptedFiles[0].name.length
        );
        type = type.toLocaleLowerCase();
        setAttachmentError(null);

        if (acceptedFiles.length > 1) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Only upload one file at a time. Please try again. ', error: true },
            });
            setShowUploadAttachmentDetail(false);
            return;
        }

        let allowedExtensions = ['pdf', 'png', 'jpg', 'gif', 'jpeg'];

        if (allowedExtensions.indexOf(type.toLowerCase()) === -1) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: {
                    message: 'Only PDF, JPEG or PNG files are allowed. Please try again with valid file. ',
                    error: true,
                },
            });
            setShowUploadAttachmentDetail(false);
            return;
        }

        if (acceptedFiles[0].size > 10023317) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: {
                    message: 'File size cannot exceed 10Mb. Please resize the file and try again. ',
                    error: true,
                },
            });
            setShowUploadAttachmentDetail(false);
            return;
        }

        setAttachmentUploadButtonDisabled(false);
        setTimeout(() => {
            setAttachmentName(reader.result);
            setUploadingAttachmentName(acceptedFiles[0].name);
        }, 1000);
        setUploadFiles(acceptedFiles);
    }

    useEffect(() => {
        (async () => {
            try {
                setShowLoaderMask(true);
                let dataResult = await getAttachmentsNew();
                setShowLoaderMask(false);
                let filteredAttachments = dataResult.result.filter((attachment: { txType: string }) => {
                    return attachment.txType === 'publishAttachment';
                });
                setAttachmentsData(filteredAttachments);
                setAttachmentsInitialData(filteredAttachments);
                setOnlyAttachments(dataResult);
            } catch (e) {
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: { message: 'Something went wrong.', error: true },
                });
            }
        })();
    }, [updateAttachments]);

    function handleTitleChange(i: any, event: any) {
        const values = [...additionalData];
        values[i].title = event.value;
        setAdditionalData(values);
    }

    function handleValueChange(i: any, event: any) {
        const values = [...additionalData];
        values[i].value = event.target.value;
        setAdditionalData(values);
    }

    function handleAdd() {
        const values = [...additionalData];
        values.push({ value: '', title: '' });
        setAdditionalData(values);
    }

    function handleRemove(i: any) {
        const values = [...additionalData];
        values.splice(i, 1);
        setAdditionalData(values);
    }

    function attachmentUploadedPopupModalToggle() {
        setAttachmentUploadedPopup(prevState => !prevState);
    }

    function showAttachmentDetailModalToggle() {
        if (credit.availableCreditDecimal! < PROOF_ACTION_PRICE) {
            setShowNotEnoughFundsPopup(true);
            return;
        }
        setShowAttachmentDetails(false);
    }

    function showAttachmentUploadModalToggle() {
        setShowUploadAttachmentDetail(false);
    }

    function attachmentDeleteModalToggle() {
        setShowDeleteAttachmentPopup(false);
    }

    function onAttachmentDeleteButtonClick() {
        setShowDeleteAttachmentPopup(true);
    }

    async function attachmentDeleteConfirmation() {
        attachmentDeleteModalToggle();
        showAttachmentDetailModalToggle();
        setShowLoaderMask(true);
        try {
            var resultMessage = await deleteAttachment(selectedAttachment.txMessage);
            setUpdateAttachments(prevState => !prevState);
            setShowLoaderMask(false);
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Certificate successfully deleted.', error: false },
            });
        } catch (e) {
            setShowLoaderMask(false);
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Error in deletion. Please contact support.', error: true },
            });
        }
    }

    function viewCertificate(attachment: IAttachment) {
        const a = document.createElement('a');
        a.setAttribute('href', `https://verify.proofplum.com/${attachment.txMessage}.pdf`);
        a.setAttribute('target', '_blank');
        a.click();
    }

    async function downloadAttachementHandler(attachment: any) {
        setShowLoaderMask(true);
        try {
            const url = await downloadAttachment({
                txMessage: attachment.txMessage,
                timestamp: attachment.timestamp,
                username: attachment.username,
                s3FileName: attachment.s3FileName,
            });
            console.log(url);
            setShowLoaderMask(false);
            const a = document.createElement('a');
            a.setAttribute('href', url as string);
            a.setAttribute('target', '_blank');
            a.click();
        } catch (e) {
            setShowLoaderMask(false);
        }
    }

    let attachmentType = '';
    if (uploadingAttachmentName) {
        attachmentType = uploadingAttachmentName.substring(
            uploadingAttachmentName.lastIndexOf('.') + 1,
            uploadingAttachmentName.length
        );
    }
    let img = (
        <div className="text-center fa-5x px-2 pt-1">
            <i className="fas fa-circle-notch fa-spin primary blue-text" />
        </div>
    );

    if (attachmentName) {
        switch (attachmentType.toLocaleLowerCase()) {
            case 'pdf':
                img = (
                    <div className="text-center px-2 pt-1">
                        <i className="far fa-file-pdf fa-7x" />
                    </div>
                );
                break;

            case 'png':
            case 'jpg':
            case 'bmp':
            case 'gif':
            case 'jpeg':
                img = (
                    <img
                        src={attachmentName}
                        alt="Image preview..."
                        style={{
                            maxWidth: '100%',
                            maxHeight: '122px',
                        }}
                    />
                );
                break;
            default:
                img = (
                    <div className="text-center px-2 pt-1">
                        <i className="far fa-file fa-7x" />
                    </div>
                );
        }
    }

    let href;
    if (selectedAttachment) {
        href = `https://verify.proofplum.com/${selectedAttachment.txMessage}`;
    }

    const options = [
        { value: 'Score', label: 'Score' },
        { value: 'Dimension', label: 'Dimension' },
        { value: 'Grade', label: 'Grade' },
        { value: 'Weight', label: 'Weight' },
        { value: 'Length', label: 'Length' },
        { value: '', label: '<Type your own>' },
    ];
    function convertTime(timestamp: any) {
        const timeAgo = new TimeAgo('en-US');
        const time = timeAgo.format(timestamp);
        const fullDate = new Date(timestamp).toDateString();
        return `${time} (${fullDate})`;
    }

    return (
        <>
            <div className="container-fluid proof landing-page-bottom">
                <div className="container pt-5">
                    <div className="row  border-bottom">
                        <Col lg={6} md={6} sm={6} xs={6}>

                        <p data-tip="Drag and Drop any PDF or image to create a blockchain certificate">
                        <h5>My Certificates  <i className="fas fa-info-circle"></i></h5></p>
                        <ReactTooltip />

                        </Col>

                        <Col className="text-right align-self-center row" lg={6} md={6} sm={12} xs={12}>
                            <Input
                                className="border-0 col-4 ml-auto"
                                type="text"
                                id="filter-text-box"
                                placeholder="Search"
                                value={filterInput.value}
                                onChange={filterInput.onChange}
                            />

                            <Button
                                onClick={() => setUpdateAttachments(prevState => !prevState)}
                                className="btn btn-primary ml-3"
                            >
                                <i className="fas fa-sync" />
                            </Button>
                        </Col>
                    </div>

                    <div style={labelStyle} className="pt-4" />

                    <div className="container" />
                    <div className="mt-1 container">
                        <div
                            className="row"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                            }}
                        >
                            {attachmentsData.map((attachment: any, index: number) => {
                                const a = attachment.s3FileName.substring(
                                    attachment.s3FileName.lastIndexOf('.') + 1,
                                    attachment.s3FileName.length
                                );
                                let icon;
                                if ((a as String).toLowerCase() === 'pdf') {
                                    icon = <i className="far fa-file-pdf fa-7x grey-text" />;
                                } else {
                                    icon = <i className="fa fa-file-image fa-7x grey-text" />;
                                }
                                let pending = <div />;
                                if (attachment.txStatus !== 'Success') {
                                    pending = <div className="grid-request-info orange mt-1">PENDING</div>;
                                }

                                if (attachment.txStatus === 'Error') {
                                    pending = <div className="grid-request-info red mt-1">ERROR</div>;
                                }

                                if (attachment.txStatus === 'Success' && Date.now() - attachment.timestamp < ONE_HOUR) {
                                    pending = <div className="grid-request-info green mt-1">SUCCESS</div>;
                                }

                                return (
                                    <div
                                        className="mt-4"
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            cursor: 'pointer',
                                        }}
                                        onClick={event => {
                                            if (attachment.txStatus === 'processing') {
                                                event.preventDefault();
                                                dispatch({
                                                    type: 'SHOW_MESSAGE',
                                                    payload: {
                                                        message: 'Wait until the processing is complete',
                                                        error: true,
                                                    },
                                                });
                                            } else {
                                                setSelectedAttachment(attachment);
                                                setShowAttachmentDetails(true);
                                            }
                                        }}
                                    >
                                        {icon}
                                        <span className="truncate-attachment-name text-center" style={labelStyle}>
                                            {attachment.txName}
                                        </span>
                                        {pending}
                                    </div>
                                );
                            })}
                            <Dropzone onDrop={onDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div
                                            className="drop-zone grey-text"
                                            {...getRootProps({
                                                style: {
                                                    height: 111,
                                                    margin: '0 5px',
                                                    border: '2px dashed #2F80ED',
                                                    borderRadius: '5px',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    display: 'flex',
                                                    width: '162px',
                                                    cursor: 'pointer',
                                                    marginTop: '30px',
                                                },
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <div>
                                                <span>Add Certificate</span>
                                                <span
                                                    className="badge-primary rounded-circle ml-2"
                                                    style={{
                                                        width: '1.125rem',
                                                        height: '1.125rem',
                                                        padding: '0.2rem',
                                                    }}
                                                >
                                                    $1
                                                </span>
                                                <div>
                                                    <small className="grey-text">
                                                        <i className="fas fa-upload" /> Drop or click to upload
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>

                        <div className="col-4" />
                    </div>
                </div>
            </div>

            {showAttachmentDetails ? (
                <Modal toggle={showAttachmentDetailModalToggle} isOpen={true} size="lg">
                    <i
                        onClick={showAttachmentDetailModalToggle}
                        className="float-right fas fa-times popup-close-icon"
                    />

                    <ModalBody>
                        <span style={labelStyle}> {selectedAttachment.txName}</span>
                        <div
                            style={{
                                borderTop: '1px solid rgba(0,0,0,0.2)',
                                marginTop: '1rem',
                                marginBottom: '1rem',
                            }}
                        >
                            <Container className="mt-4 py-4 px-4">
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <p data-tip="System generated unique certificate identifier">
                                        <span style={labelStyle}>DOCUMENT ID &nbsp;
                                        <i className="fas fa-info-circle"></i>
                                        </span></p>
                                        <ReactTooltip />
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8">
                                        {selectedAttachment.txMessage}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <p data-tip="Description of the certificate">
                                        <span style={labelStyle}>DESCRIPTION &nbsp;
                                        <i className="fas fa-info-circle"></i></span></p>
                                        <ReactTooltip />
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8">
                                        {selectedAttachment.s3FileDescription}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <p data-tip="Name of the certificate recipient or owner">
                                        <span style={labelStyle}>RECIPIENT/OWNER NAME &nbsp;
                                        <i className="fas fa-info-circle"></i></span></p>
                                        <ReactTooltip />
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8">
                                        {selectedAttachment.txRecipientName}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <p data-tip="Email of the certificate recipient or owner">
                                        <span style={labelStyle}>RECIPIENT/OWNER EMAIL &nbsp;
                                        <i className="fas fa-info-circle"></i></span></p>
                                        <ReactTooltip />
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8">
                                        {selectedAttachment.txRecipientEmail}
                                    </Col>
                                </Row>
                                <Row className="pt-2" />
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <p data-tip="Share this link with anyone who can execute the veritication process for this certificate">
                                        <span style={labelStyle}>Verification link &nbsp;
                                        <i className="fas fa-info-circle"></i></span></p>
                                        <ReactTooltip />
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8">
                                        <a href={href} target="blank">
                                            {href}
                                        </a>
                                    </Col>
                                </Row>
                                <BlokchainDetails proof={selectedAttachment} />
                                <Row className="mb-2">
                                    <Col xs="12" sm="12" md="12" lg="7">
                                      <p data-tip="Original certificate will open in a new window">
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => downloadAttachementHandler(selectedAttachment)}
                                            className="mr-4 small-text gray"
                                        >

                                            View Original &nbsp;
                                            <i className="fas fa-info-circle"></i>
                                        </span></p>
                                        <ReactTooltip />
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col xs="12" sm="12" md="12" lg="7">
                                        <p data-tip="This will delete all certificate data from the system. Recovery is not possible">
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => onAttachmentDeleteButtonClick()}
                                            className="mr-4 small-text gray"
                                        >
                                            Delete Certificate &nbsp;

                                            <i className="fas fa-info-circle"></i>
                                        </span></p>
                                        <ReactTooltip />
                                    </Col>
                                </Row>

                                <div style={{
                                  paddingLeft: '1rem',

                                  paddingRight: '1.25rem',
                                }} className="row">

                              <span style={labelStyle}>SHARE</span>

                                <div style={{paddingLeft: '0.5rem', }} className="col-xs-4">
                                    <TwitterShareButton
                                    className="network__share-button"
                                    url={href}
                                    via="proofplum"
                                    hashtag="#blockchain"
                                    title= "I created a blockchain certificate in proofplum.com"
                                    >
                                    <TwitterIcon size={32} round={true} />
                                    </TwitterShareButton>
                                  </div>
                                  <div style={{paddingLeft: '0.5rem', }} className="col-xs-4">
                                    <FacebookShareButton
                                        className="network__share-button"
                                        url={href}
                                        hashtag="#proofplum"
                                        quote="I created a blockchain certificate in proofplum.com "
                                        >

                                        <FacebookIcon size={32} round={true} />

                                        </FacebookShareButton>
                                    </div>
                                    <div style={{paddingLeft: '0.5rem', }} className="col-xs-4">
                                      <LinkedinShareButton
                                          className="network__share-button"
                                          url={href}
                                          hashtag="#proofplum"
                                          quote="I created a blockchain certificate in proofplum.com"
                                          >

                                          <LinkedinIcon size={32} round={true} />

                                          </LinkedinShareButton>
                                      </div>
                                      <div style={{paddingLeft: '0.5rem', }} className="col-xs-4">
                                        <TelegramShareButton
                                            className="network__share-button"
                                            url={href}
                                            hashtag="#proofplum"
                                            quote="I created a blockchain certificate in proofplum.com"
                                            >

                                            <TelegramIcon size={32} round={true} />

                                            </TelegramShareButton>
                                        </div>
                                  </div>


                                <Row>
                                    <Col className="text-center">
                                        <Button color="primary" onClick={() => viewCertificate(selectedAttachment)}>
                                            View Certificate
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </ModalBody>
                </Modal>
            ) : (
                ''
            )}

            {showUploadAttachmentDetail ? (
                <Modal toggle={showAttachmentUploadModalToggle} isOpen={true} size="lg">
                    <i
                        onClick={showAttachmentUploadModalToggle}
                        className="float-right fas fa-times popup-close-icon"
                    />

                    <ModalBody>
                        <span style={labelStyle}> INPUT DOCUMENT DETAILS</span>
                        <div
                            style={{
                                borderTop: '1px solid rgba(0,0,0,0.2)',
                                marginTop: '1rem',
                                marginBottom: '1rem',
                            }}
                        >
                            <Container className="mt-4 py-4 px-4">
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="12">
                                        <Row>
                                            <Col xs="12" sm="12" md="12" lg="4">
                                                <p data-tip="Type descriptive name for the blockchain certificate">
                                                <span style={labelStyle}>CERTIFICATE NAME &nbsp;
                                                <i className="fas fa-info-circle"></i>
                                                </span></p>
                                                <ReactTooltip />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="8" className="text-truncate">
                                                <Input
                                                    value={attachmentGivenName.value}
                                                    onChange={attachmentGivenName.onChange}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="pt-2" />
                                        <Row>
                                            <Col xs="12" sm="12" md="12" lg="4">
                                                <p data-tip="Include additional information about the certificate">
                                                <span style={labelStyle}>DESCRIPTION &nbsp;
                                                <i className="fas fa-info-circle"></i>
                                                </span></p>
                                                <ReactTooltip />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="8">
                                                <textarea
                                                    className="form-control mb-2"
                                                    style={{
                                                        height: 110,
                                                    }}
                                                    value={attachmentDescription.value}
                                                    onChange={attachmentDescription.onChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="pt-2" />
                                        <Row>
                                            <Col xs="12" sm="12" md="12" lg="4">
                                              <p data-tip="Defaults to current user email. Change it to recipient email address if required">
                                                <span style={labelStyle}>Recipient/owner email &nbsp;
                                                <i className="fas fa-info-circle"></i>
                                                </span></p>
                                                <ReactTooltip />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="8">
                                                <Input
                                                    className="form-control mb-2"
                                                    value={recipientOwnerEmail.value}
                                                    onChange={recipientOwnerEmail.onChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="pt-2" />
                                        <Row>
                                            <Col xs="12" sm="12" md="12" lg="4">
                                                <p data-tip="Defaults to current user full name. Change it to recipient first and last name if required">
                                                <span style={labelStyle}>Recipient/owner name &nbsp;
                                                <i className="fas fa-info-circle"></i>
                                                </span></p>
                                                <ReactTooltip />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="8">
                                                <Input
                                                    className="form-control mb-2"
                                                    value={recipientOwnerName.value}
                                                    onChange={recipientOwnerName.onChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="pt-2" />
                                        <Row>
                                            <Col xs="12" sm="12" md="12" lg="4">
                                                <p data-tip="Optional meta data to reflect the validity and document physical attributes">
                                                <span style={labelStyle}>Additional data &nbsp;
                                                <i className="fas fa-info-circle"></i>
                                                </span></p>
                                                <ReactTooltip />
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="8" />
                                        </Row>
                                        <div className="text-left">
                                            {additionalData.map((field, idx) => {
                                                return (
                                                    <Row className="my-3 ml-4" key={`${field}-${idx}`}>
                                                        <Col xs="12" sm="12" md="5" lg="5">
                                                            <CreatableSelect
                                                                isClearable
                                                                onChange={(e: any) => handleTitleChange(idx, e)}
                                                                //onInputChange={(e: any) => handleTitleChange(idx, e)}
                                                                options={options}
                                                            />
                                                        </Col>
                                                        <Col xs="12" sm="12" md="5" lg="5">
                                                            <Input
                                                                type="text"
                                                                placeholder="Enter text"
                                                                value={field.value || ''}
                                                                onChange={e => handleValueChange(idx, e)}
                                                            />
                                                        </Col>
                                                        <Col xs="6" sm="6" md="2" lg="2" className="text-right mt-2">
                                                            <Button
                                                                className="btn btn-danger"
                                                                onClick={() => handleRemove(idx)}
                                                            >
                                                                <i className="fas fa-minus" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </div>
                                        <div className="text-right mt-2">
                                            <Button onClick={() => handleAdd()} className="btn btn-primary">
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="pt-3">
                                    <Col className="text-center">
                                        <span
                                            style={{
                                                color: '#2F80ED',
                                            }}
                                        >
                                            $1 will be debited from your account{' '}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="pt-3">
                                    <Col className="text-center">
                                        {attachmentUploadButtonDisabled ? (
                                            <Button color="primary" disabled onClick={onAttachmentUploadClick}>
                                                Upload
                                            </Button>
                                        ) : (
                                            <Button color="primary" onClick={onAttachmentUploadClick}>
                                                Upload
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </ModalBody>
                </Modal>
            ) : (
                ''
            )}

            {showNotEnoughFundsPopup ? <NotEnoughFundsPopup /> : ''}

            {showLoaderMask ? (
                <div className="attachment-mask">
                    <div className="text-center fa-5x px-2 pt-1">
                        <i className="fas fa-circle-notch fa-spin primary blue-text" />
                    </div>
                </div>
            ) : (
                ''
            )}

            {attachmentUploadedPopup ? (
                <Modal toggle={attachmentUploadedPopupModalToggle} isOpen={true} size="xs">
                    <ModalBody>
                        <div className="container justify-content-center">
                            <div className="row justify-content-center">
                                <i
                                    className="fas fa-check text-success"
                                    style={{
                                        fontSize: '60px',
                                    }}
                                />
                            </div>
                            <div
                                className="row justify-content-center"
                                style={{
                                    fontSize: '24px',
                                }}
                            >
                                Upload Successful. You will receive confirmation email within next 30 mins.
                            </div>
                            <div className="row mt-2 justify-content-center">
                                <Button color="primary" onClick={attachmentUploadedPopupModalToggle}>
                                    Got It!
                                </Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            ) : (
                ''
            )}

            {showDeleteAttachmentPopup ? (
                <Modal toggle={attachmentDeleteModalToggle} isOpen={true} size="xs">
                    <ModalBody>
                        <div className="container justify-content-center">
                            <div className="row justify-content-center mb-2">
                                <span>This will permanently delete the original </span>
                                <span> document and share document</span>
                            </div>
                            <div className="text-center">
                                <span className="font-weight-bold">Are you sure?</span>
                            </div>

                            <div className="row mt-2 justify-content-center">
                                <Button
                                    onClick={attachmentDeleteConfirmation}
                                    className="btn grid-btn-red mr-3 border-0"
                                >
                                    Delete
                                </Button>
                                <Button className="btn gray-background-btn" onClick={attachmentDeleteModalToggle}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            ) : (
                ''
            )}
        </>
    );
}
