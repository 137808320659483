import React from 'react';
import MyDevices from './my-devices';
import WithHeaderAndFooter from '../WithHeaderAndFooter';
import { AttachmentsOfProofs } from '../AttachmentsOfProofs';
export default function MyProofs() {
    return (
        <WithHeaderAndFooter>
            {/* <MyDevices /> */}
            <AttachmentsOfProofs/>
        </WithHeaderAndFooter>
    );
}
