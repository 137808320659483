import React, {useContext, useState} from 'react';
import {withRouter} from 'react-router';
import Col from 'reactstrap/lib/Col';
import {Link} from 'react-router-dom';
import Input from 'reactstrap/lib/Input';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import {getSearchresults} from '../../api';
import appLoginDispatcher from '../../contexts/app-dispatch';
import {Row} from "reactstrap";
import logo from "../../images/proofbox-logo-cropped.svg";
import UserContext from "../../contexts/user";

function useFormField(initialValue: string = '') {
    const [value, setValue] = useState(initialValue);

    function onChange(event: any) {
        setValue(event.target.value);
    }

    return {
        value,
        onChange,
    };
}

function Footer(footerProps: any) {
    const search = useFormField();
    const dispatch = useContext(appLoginDispatcher)!;
    const user = useContext(UserContext);

    async function onSubmit(event: any) {
        event.preventDefault();
        try {
            const result = await getSearchresults(search.value);
            if (!!result.Count) {
                footerProps.history.push(`/search/${search.value}`);
            } else {
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: {message: `Unable to get device with ${search.value} Proof Id`, error: true},
                });
                return;
            }
        } catch (e) {
        }
    }

    return (
      <>
          <div className="mt-5 d-xl-none d-lg-none d-md-none d-sm-none"></div>
          <footer className="container-fluid mx-auto py-5 gray-background">
              <div className="container d-none d-sm-none d-md-block d-lg-block ">
                  <Row>
                      <Col xs="1" className="pt-1 text-right">
                          <img className="mb-1 logo" src={logo}/>
                      </Col>
                      <Col className="outro" xs="12" sm="12" md="4" lg="2">
                          <span className="brand"> Proofplum</span>
                          <p className="pt-2">Easiest way to create blockchain certificate.</p>
                          {/* <p>Read the deployed Ethereum <a href="https://etherscan.io/address/0xc9ca68b489706cd31fb888bd771e3c4dd84251ae">SmartContract.</a></p> */}
                          <p><a href="https://proofplum.com/terms.html">Terms</a> <a href="https://proofplum.com/privacy.html"> Privacy</a> </p>
                      </Col>
                      <Col xs="12" sm="12" md="4" lg="2">
                          {!user ? <Link to="/login" className="footerLinks sign-in">Log In</Link> : ''}
                      </Col>
                      <Col xs="12" sm="12" md="3" lg="2">
                        <a href="https://help.proofplum.com/hc/en-us/sections/360007243792-FAQ" className="footerLinks">
                            FAQ
                        </a>

                        <p></p>
                        <a href="https://help.proofplum.com/hc/en-us/requests/new" className="footerLinks">
                             Contact us
                        </a>

                        <p></p>
                          <a href="https://twitter.com/proofplum" className="footerLinks">
                              Twitter
                          </a>
                            <p></p>
                          <a href="https://t.me/proofbox" className="footerLinks">
                            Telegram
                          </a>
                      </Col>

                      <Col xs="12" sm="12" md="12" lg="5">

                          <p className="outro">&copy; Proofplum is a product of Proofbox Inc, 5100 Buckeystown Pike, Suite 250, Frederick, MD, 21704. USA</p>
                          <p className="outro">Proofplum is proud to be part of <a href="https://www.mousebelt.com/"> Mousebelt </a> blockchain accelerator program.</p>
                          <p className="outro">Powered by Ethereum, Algorand and blockcerts.org.</p>
                          <p className="outro">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                            <a href="https://policies.google.com/terms"> Terms of Service</a> apply.</p>

                      </Col>
                  </Row>
              </div>
              <div className="container d-md-none d-lg-none d-sm-block d-block">
                  <Col xs="12" sm="12">
                    <Link to="/login" className="footerLinks sign-in">Log In</Link>
                   <p></p>
                   <a href="https://help.proofplum.com/hc/en-us/sections/360007243792-FAQ" className="footerLinks">
                       FAQ
                   </a>

                   <p></p>
                   <a href="https://help.proofplum.com/hc/en-us/requests/new" className="footerLinks">
                   Contact us
                    </a>
                   <p></p>
                   <a href="https://twitter.com/proofplum" className="footerLinks">
                       Twitter
                   </a>
                   <p></p>
                   <a href="https://t.me/proofbox" className="footerLinks">
                   Telegram
                   </a>
                  </Col>
                  <Col xs="12" sm="12" className="pt-5">
                      <p className="outro">&copy; Proofplum is a product of Proofbox Inc, 5100 Buckeystown Pike, Suite 250, Frederick, MD, 21704. USA</p>
                      <p className="outro">Proofplum is proud to be part of <a href="https://www.mousebelt.com/"> Mousebelt </a> blockchain accelerator program.</p>
                      <p className="outro">Powered by Ethereum, Algorand and blockcerts.org.</p>
                      <p className="outro">This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                        <a href="https://policies.google.com/terms"> Terms of Service</a> apply.</p>
                  </Col>


                  <Col className="outro pt-4" xs="12" sm="12" md="4" lg="2">
                      <span className="brand"><img className="mb-1 logo" style={{width: '24px', height: '24px'}} src={logo}/> Proofplum</span>
                      <p className="pt-2">Easiest way to create blockchain certificate.</p>
                      {/* <p>Read the deployed Ethereum <a href="https://etherscan.io/address/0xc9ca68b489706cd31fb888bd771e3c4dd84251ae">SmartContract.</a></p> */}
                      <p><a href="https://proofplum.com/terms.html">Terms</a></p>
 <p><a href="https://proofplum.com/privacy.html">Privacy</a></p>
                  </Col>

              </div>
          </footer>
      </>
    );
}

export default withRouter(Footer);
