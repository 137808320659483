import { Button } from 'reactstrap';
import React from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { BlokchainDetails } from '../BlockchainDetails';

export interface IDeviceDetailsProps {
    modalToggle?: () => void;
    okClick?: () => void;
    name: string;
}
export function DeviceRequestPopup({ modalToggle, okClick, name }: IDeviceDetailsProps) {
    return (
        <Modal toggle={modalToggle} isOpen={true} size="xs">
            <ModalBody>
                <div className="container justify-content-center">
                    <div className="row justify-content-center">
                        <i
                            className="fas fa-check text-success"
                            style={{
                                fontSize: '60px',
                            }}
                        />
                    </div>
                    <div
                        className="row justify-content-center"
                        style={{
                            fontSize: '24px',
                        }}
                    >
                        We’ve just notified the owner of device ID
                        <span
                            className="px-1"
                            style={{
                                fontWeight: 700,
                            }}
                        >
                            {name}
                        </span>
                        about your request
                    </div>
                    <div
                        className="row mt-2 justify-content-center"
                        style={{
                            fontSize: '16px',
                        }}
                    >
                        We’ll notify you through email about updates on this request.
                    </div>
                    <div className="row mt-2 justify-content-center">
                        <Button
                            color="primary"
                            onClick={() => (okClick ? okClick() : modalToggle ? modalToggle() : null)}
                        >
                            Great, Got It!
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}
