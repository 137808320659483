import { Button, Form, FormGroup, Label, Input, FormText, Row, Col } from 'reactstrap';
import React, { useState, useContext } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import { Attachment } from '../Attachment';
import { AlgochainDetails } from '../AlgochainDetails';
import { getUserCredits, updateProofParams } from '../../api';
import appLoginDispatcher from '../../contexts/app-dispatch';
import { labelStyle } from '../inline-styles';
import Transactions from '../Transactions';


import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
} from 'react-share';



export interface IDeviceDetailsProps {
    modalToggle?: () => void;
    proof: any;

    proofToUpdateName?: (arg:any) => void;
}

function useFormField(initialValue: string = '') {
    const [value, setValue] = useState(initialValue);

    function onChange(event: any) {
        setValue(event.target.value);
    }

    return {
        value,
        onChange,
    };
}

let timeout: any;
export function TextDetails(props: IDeviceDetailsProps) {
    const proofName = useFormFieldWithDeferName(props.proof.txName);
    const proofDetails = useFormFieldWithDeferDetails(props.proof.txDetails);
    const dispatch = useContext(appLoginDispatcher)!;
    const [uploadFiles, setUploadFiles] = useState();

    async function onSubmit(changedParams:any) {
        // event.preventDefault();
        const params = {
            txMessage: props.proof.txMessage,
            timestamp: props.proof.timestamp,
            username: props.proof.username,
            txStatus: props.proof.txStatus,
            txOwner: props.proof.txOwner,
        };
        let merged = {...changedParams, ...params};
        try {
            updateProofParams(merged);
            props.proofToUpdateName!( {...props.proof, ...merged});
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Device successfully updated', error: false },
            });
        } catch (e) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Problem while updated device', error: true },
            });
        }
        // props.modalToggle!();
        const userCredit = await getUserCredits();
        dispatch({ type: 'SET_USER_CREDITS', payload: userCredit });
    }

    function useFormFieldWithDeferName(initialValue: string = '') {
        const [value, setValue] = useState(initialValue);

        function onChange(event: any) {
            setValue(event.target.value);
            const val = event.target.value;
            clearTimeout(timeout);
            timeout = setTimeout(function() {
                console.log('Input Value:', val);
                const params ={
                    txName: val,
                    txDetails: proofDetails.value,
                }

                onSubmit(params);
            }, 3000);
        }

        return {
            value,
            onChange,
        };
    }

    function useFormFieldWithDeferDetails(initialValue: string = '') {
        const [value, setValue] = useState(initialValue);

        function onChange(event: any) {
            setValue(event.target.value);
            const val = event.target.value;
            clearTimeout(timeout);
            timeout = setTimeout(function() {

                console.log('Input Value:', val);
                const params ={
                    txName: proofName.value,
                    txDetails: val,
                }
                onSubmit(params);
            }, 3000);
        }

        return {
            value,
            onChange,
        };
    }

    function donothing() {}

    return (
        <Modal toggle={props.modalToggle} isOpen={true} size="lg">
            <Form>

            <ModalHeader className="asd">


                <i onClick={props.modalToggle} className="float-right fas fa-times popup-close-icon" />
            </ModalHeader>

                <ModalBody
                    style={{
                        marginTop: '2rem',
                        paddingLeft: '1.25rem',
                        paddingRight: '1.25rem',
                    }}
                >

                    <FormGroup>
                        <Label style={labelStyle}>text</Label>
                        <textarea
                            className="form-control"

                            value={props.proof.txMessage}
                        />
                    </FormGroup>

                    <AlgochainDetails proof={props.proof} />

                    <div
                      style={{

                        marginTop: '1rem',
                        paddingRight: '1.25rem',
                      }}>


                        <Label style={labelStyle}>share</Label>
                      <div style={{
                        paddingLeft: '1rem',

                        paddingRight: '1.25rem',
                      }} className="row">

                      <div className="col-xs-4">
                          <TwitterShareButton
                          className="network__share-button"
                          url={"https://algoexplorer.io/tx/" + props.proof.txHash}
                          via="proofplum"
                          hashtag="#blockchain"
                          title= "I created a blockchain transaction in proofplum.com"
                          >
                          <TwitterIcon size={32} round={true} />
                          </TwitterShareButton>
                        </div>
                        <div style={{paddingLeft: '1rem', }} className="col-xs-4">
                          <FacebookShareButton
                              className="network__share-button"
                              url="https://proofplum.com"
                              hashtag="#proofplum"
                              quote={"I created a blockchain transaction in proofplum.com https://algoexplorer.io/tx/" + props.proof.txHash}
                              >

                              <FacebookIcon size={32} round={true} />

                              </FacebookShareButton>
                          </div>
                          <div style={{paddingLeft: '1rem', }} className="col-xs-4">
                            <LinkedinShareButton
                                className="network__share-button"
                                url="https://proofplum.com"
                                hashtag="#proofplum"
                                quote={"I created a blockchain transaction in proofplum.com https://algoexplorer.io/tx/" + props.proof.txHash}
                                >

                                <LinkedinIcon size={32} round={true} />

                                </LinkedinShareButton>
                            </div>
                            <div style={{paddingLeft: '1rem', }} className="col-xs-4">
                              <TelegramShareButton
                                  className="network__share-button"
                                  url="https://proofplum.com"
                                  hashtag="#proofplum"
                                  quote={"I created a blockchain transaction in proofplum.com https://algoexplorer.io/tx/" + props.proof.txHash}
                                  >

                                  <TelegramIcon size={32} round={true} />

                                  </TelegramShareButton>
                              </div>
                        </div>
                        </div>

                </ModalBody>
                <div
                    style={{
                        borderTop: '1px solid rgba(0,0,0,0.2)',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                    }}
                />
                <ModalBody
                    style={{
                        paddingLeft: '1.25rem',
                        paddingRight: '1.25rem',
                    }}
                >

                </ModalBody>
            </Form>
        </Modal>
    );
}
