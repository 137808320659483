import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import React, { useState, useContext, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { uploadAttachments, getUserCredits, downloadAttachment } from '../../api';
import appLoginDispatcher from '../../contexts/app-dispatch';
import UserProofsContext from '../../contexts/user-proofs';
import { labelStyle } from '../inline-styles';
import UserCreditContext from '../../contexts/user-credit';
import NotEnoughFundsPopup from '../NotEnoughFundsPopup';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ModalBody from 'reactstrap/lib/ModalBody';
import Modal from 'reactstrap/lib/Modal';
import { NETWORK } from '../../constants';

export interface IAttachmentProps {
    modalToggle?: () => void;
    proofId: string;
    uploadFiles?: any;
}

TimeAgo.locale(en);

function useFormField(initialValue: string = '') {
    const [value, setValue] = useState(initialValue);

    function onChange(event: any) {
        setValue(event.target.value);
    }

    return {
        value,
        onChange,
    };
}

export function Attachment(props: IAttachmentProps) {
    const userData = useContext(UserProofsContext);
    const credit = useContext(UserCreditContext);
    const attachmentDescription = useFormField();
    const [attachmentName, setAttachmentName] = useState();
    const [uploadFiles, setUploadFiles] = useState();
    const dispatch = useContext(appLoginDispatcher)!;
    const [showNotEnoughFundsPopup, setShowNotEnoughFundsPopup] = useState(false);
    const [showLoaderMask, setShowLoaderMask] = useState(false);
    const [showAttachmentDetails, setShowAttachmentDetails] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState();
    const [showUploadAttachmentDetail, setShowUploadAttachmentDetail] = useState(false);
    const [uploadingAttachmentName, setUploadingAttachmentName] = useState();
    const [attachmentUploadButtonDisabled, setAttachmentUploadButtonDisabled] = useState(false);
    const [attachmentError, setAttachmentError] = useState();
    const [attachmentUploadedPopup, setAttachmentUploadedPopup] = useState(false);

    async function onAttachmentUploadClick(event: any) {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        setAttachmentUploadButtonDisabled(true);

        setShowLoaderMask(true);
        try {
            await uploadAttachments(props.proofId, uploadFiles[0], attachmentDescription.value);
            setAttachmentUploadedPopup(true);
            setAttachmentUploadButtonDisabled(false);
            setShowLoaderMask(false);
        } catch (e) {
            setShowLoaderMask(false);
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Duplicate attachment exist', error: true },
            });
        }

        const userCredit = await getUserCredits();
        dispatch({ type: 'SET_USER_CREDITS', payload: userCredit });
    }

    function validateForm() {
        let isFormValid = true;
        if (attachmentDescription.value == '') {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'All fields are required', error: true },
            });
            isFormValid = false;
        }

        return isFormValid;
    }

    function onDrop(acceptedFiles: any) {
        if (credit.availableCredit! < acceptedFiles.length) {
            setShowNotEnoughFundsPopup(true);
        }
        setShowUploadAttachmentDetail(true);
        setShowAttachmentDetails(true);

        let reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        let type = acceptedFiles[0].name.substring(
            acceptedFiles[0].name.lastIndexOf('.') + 1,
            acceptedFiles[0].name.length
        );
        type = type.toLocaleLowerCase();
        setAttachmentError(null);

        let allowedExtensions = [
            'pdf',
            'png',
            'jpg',
            'gif',
            'jpeg',
            'txt'
        ] ;

        if (
          acceptedFiles[0].size > 10023317 ||
          allowedExtensions.indexOf(type.toLowerCase()) === -1
        ) {
            setAttachmentUploadButtonDisabled(true);
            setAttachmentError(<div className="text-center px-2 pt-1">Not allowed type or file size exceed 10Mb</div>);
            return;
        }
        setAttachmentUploadButtonDisabled(false);
        setTimeout(() => {
            setAttachmentName(reader.result);
            setUploadingAttachmentName(acceptedFiles[0].name);
        }, 1000);
        setUploadFiles(acceptedFiles);
    }

    const attachmentsData = useMemo(
        () =>
            userData.filter(proof => {
                return proof.txType === 'emitIpfsKey' && proof.txMessage === props.proofId;
            }),
        [userData]
    );

    function attachmentUploadedPopupModalToggle() {
        setAttachmentUploadedPopup(prevState => !prevState);
    }

    async function downloadAttachementHandler(attachment: any) {
        setShowLoaderMask(true);
        try {
            const url = await downloadAttachment({
                txMessage: attachment.txMessage,
                timestamp: attachment.timestamp,
                username: attachment.username,
                s3FileName: attachment.s3FileName,
            });
            console.log(url);
            setShowLoaderMask(false);
            const a = document.createElement('a');
            a.setAttribute('href', url as string);
            a.setAttribute('target', '_blank');
            a.click();
        } catch (e) {
            setShowLoaderMask(false);
        }
    }

    let attachmentType = '';
    if (uploadingAttachmentName) {
        attachmentType = uploadingAttachmentName.substring(
            uploadingAttachmentName.lastIndexOf('.') + 1,
            uploadingAttachmentName.length
        );
    }
    let img = (
        <div className="text-center fa-5x px-2 pt-1">
            <i className="fas fa-circle-notch fa-spin primary blue-text" />
        </div>
    );

    if (attachmentName) {
        switch (attachmentType.toLocaleLowerCase()) {
            case 'pdf':
                img = (
                    <div className="text-center px-2 pt-1">
                        <i className="far fa-file-pdf fa-7x" />
                    </div>
                );
                break;

            case 'png':
            case 'jpg':
            case 'bmp':
            case 'gif':
            case 'jpeg':
                img = (
                    <img
                        src={attachmentName}
                        alt="Image preview..."
                        style={{
                            maxWidth: '100%',
                            maxHeight: '122px',
                        }}
                    />
                );
                break;
            default:
                img = (
                    <div className="text-center px-2 pt-1">
                        <i className="far fa-file fa-7x" />
                    </div>
                );
        }
    }

    let href = '';
    if (selectedAttachment) {
        if (NETWORK === 'mainnet') {
            href = `https://etherscan.io/tx/${selectedAttachment.txHash}`;
        } else {
            href = `https://${NETWORK}.etherscan.io/tx/${selectedAttachment.txHash}`;
        }
    }

    function convertTime(timestamp: any) {
        const timeAgo = new TimeAgo('en-US');
        const time = timeAgo.format(timestamp);
        const fullDate = new Date(timestamp).toDateString();
        return `${time} (${fullDate})`;
    }

    return (
        <>
            <div style={labelStyle} className="pt-4">
                Attachments
            </div>

            <div className="container" />
            <div className="mt-1 container">
                <div
                    className="row"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                    }}
                >
                    {attachmentsData.map((attachment: any, index: number) => {
                        const a = attachment.s3FileName.substring(
                            attachment.s3FileName.lastIndexOf('.') + 1,
                            attachment.s3FileName.length
                        );
                        let icon;
                        if ((a as String).toLowerCase() === 'pdf') {
                            icon = <i className="far fa-file-pdf fa-7x grey-text" />;
                        } else {
                            icon = <i className="fa fa-file-image fa-7x grey-text" />;
                        }

                        return (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setShowAttachmentDetails(true);
                                    setShowUploadAttachmentDetail(false);
                                    setSelectedAttachment(attachment);
                                }}
                            >
                                {icon}
                                <span className="truncate-attachment-name text-center" style={labelStyle}>
                                    {attachment.s3FileName}
                                </span>
                            </div>
                        );
                    })}
                    <Dropzone onDrop={onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <section>
                                <div
                                    className="drop-zone grey-text"
                                    {...getRootProps({
                                        style: {
                                            height: 111,
                                            margin: '0 5px',
                                            border: '2px dashed #2F80ED',
                                            borderRadius: '5px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            width: '192px',
                                            cursor: 'pointer',
                                        },
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    <div>
                                        <span>Add attachement</span>
                                        <span
                                            className="badge-primary rounded-circle ml-2"
                                            style={{
                                                width: '1.125rem',
                                                height: '1.125rem',
                                                padding: '0.2rem',
                                            }}
                                        >
                                            $1
                                        </span>
                                        <div>
                                            <small className="grey-text">
                                                <i className="fas fa-upload" /> Drop or click to upload
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>

                <div className="col-4" />
            </div>
            {showAttachmentDetails ? (
                <div
                    style={{
                        borderTop: '1px solid rgba(0,0,0,0.2)',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                    }}
                >
                    <span style={labelStyle}>Attachment Details</span>

                    {showUploadAttachmentDetail ? (
                        <>
                            <Container className="mt-4 px-4">
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="7">
                                        <Row>
                                            <Col xs="12" sm="12" md="12" lg="4">
                                                <span style={labelStyle}>NAME</span>
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="8" className="text-truncate">
                                                {uploadingAttachmentName}
                                            </Col>
                                        </Row>
                                        <Row className="pt-2" />
                                        <Row>
                                            <Col xs="12" sm="12" md="12" lg="4">
                                                <span style={labelStyle}>DESCRIPTION</span>
                                            </Col>
                                            <Col xs="12" sm="12" md="12" lg="8">
                                                <textarea
                                                    className="form-control mb-2"
                                                    style={{
                                                        height: 110,
                                                    }}
                                                    value={attachmentDescription.value}
                                                    onChange={attachmentDescription.onChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="5">
                                        <div
                                            style={{
                                                border: '2px dashed #2F80ED',
                                                borderRadius: '5px',
                                                height: 143,
                                                width: 200,
                                            }}
                                            className="text-center p-2 grey-text"
                                        >
                                            {attachmentError || img}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="pt-3">
                                    <Col className="text-center">
                                        {attachmentUploadButtonDisabled ? (
                                            <Button color="primary" disabled onClick={onAttachmentUploadClick}>
                                                <i className="fas fa-upload" /> Upload
                                            </Button>
                                        ) : (
                                            <Button color="primary" onClick={onAttachmentUploadClick}>
                                                <i className="fas fa-upload" /> Upload
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    ) : (
                        <>
                            <Container className="mt-4 py-4 px-4">
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <span style={labelStyle}>NAME</span>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8" className="text-truncate">
                                        {selectedAttachment.s3FileName}
                                    </Col>
                                </Row>
                                <Row className="pt-2" />

                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <span style={labelStyle}>DESCRIPTION</span>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8">
                                        {selectedAttachment.s3FileDescription}
                                    </Col>
                                </Row>
                                <Row className="pt-2" />
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <span style={labelStyle}>DATE UPLOADED</span>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8">
                                        {convertTime(selectedAttachment.timestamp)}
                                    </Col>
                                </Row>
                                <Row className="pt-2" />
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <span style={labelStyle}>IPFSKEY</span>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8" className="truncate-hash">
                                        {selectedAttachment.ipfskey}
                                    </Col>
                                </Row>
                                <Row className="pt-2" />
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <span style={labelStyle}>TX HASH</span>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8" className="truncate-hash">
                                        {selectedAttachment.txHash}
                                    </Col>
                                </Row>
                                <Row className="pt-4" />
                                <Row>
                                    <Col xs="7" sm="7" md="7" lg="7">
                                        <a href={href} target="blank">
                                            <i className="fas fa-external-link-alt" /> More details on etherscan.io
                                        </a>
                                    </Col>
                                    <Col xs="5">
                                        <Button
                                            color="primary"
                                            onClick={() => downloadAttachementHandler(selectedAttachment)}
                                        >
                                            <i className="fas fa-eye" /> View File
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </>
                    )}
                </div>
            ) : (
                ''
            )}
            {showNotEnoughFundsPopup ? <NotEnoughFundsPopup /> : ''}

            {showLoaderMask ? (
                <div className="attachment-mask">
                    <div className="text-center fa-5x px-2 pt-1">
                        <i className="fas fa-circle-notch fa-spin primary blue-text" />
                    </div>
                </div>
            ) : (
                ''
            )}

            {attachmentUploadedPopup ? (
                <Modal toggle={attachmentUploadedPopupModalToggle} isOpen={true} size="xs">
                    <ModalBody>
                        <div className="container justify-content-center">
                            <div className="row justify-content-center">
                                <i
                                    className="fas fa-check text-success"
                                    style={{
                                        fontSize: '60px',
                                    }}
                                />
                            </div>
                            <div
                                className="row justify-content-center"
                                style={{
                                    fontSize: '24px',
                                }}
                            >
                                Upload Successful. You will receive confirmation email within next 30 mins.
                            </div>
                            <div className="row mt-2 justify-content-center">
                                <Button color="primary" onClick={attachmentUploadedPopupModalToggle}>
                                    Got It!
                                </Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            ) : (
                ''
            )}
        </>
    );
}
