import { Button, Form, FormGroup, Label, Input, FormText, Row, Col } from 'reactstrap';
import React, { useState, useContext } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import { Attachment } from '../Attachment';
import { BlokchainDetails } from '../BlockchainDetails';
import { getUserCredits, updateProofParams } from '../../api';
import appLoginDispatcher from '../../contexts/app-dispatch';
import { labelStyle, labelStyleBig } from '../inline-styles';
import Transactions from '../Transactions';

export interface IRequestedProofDetailsProps {
    modalToggle?: () => void;
    proof: any;
}

function useFormField(initialValue: string = '') {
    const [value, setValue] = useState(initialValue);

    function onChange(event: any) {
        setValue(event.target.value);
    }

    return {
        value,
        onChange,
    };
}

export function RequestedProofDetails(props: IRequestedProofDetailsProps) {
    const proofName = useFormField(props.proof.txName);
    const proofDetails = useFormField(props.proof.txDetails);
    const dispatch = useContext(appLoginDispatcher)!;
    const [uploadFiles, setUploadFiles] = useState();

    function donothing() {}

    return (
        <Modal toggle={props.modalToggle} isOpen={true} size="lg">
            <Form>
                <ModalHeader className="asd">
                    {props.proof.txName || props.proof.txMessage}

                    <i onClick={props.modalToggle} className="float-right fas fa-times popup-close-icon" />
                </ModalHeader>
                <ModalBody
                    style={{
                        marginTop: '2rem',
                        paddingLeft: '1.25rem',
                        paddingRight: '1.25rem',
                    }}
                >
                    <Row className="mb-3">
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Label style={labelStyleBig}>Name</Label>
                            <div>{proofName.value} </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Label style={labelStyleBig}>ID</Label>
                            <div>{props.proof.txMessage}</div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Label style={labelStyleBig}>Details</Label>
                            <div>{proofDetails.value}</div>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                            <Label style={labelStyleBig}>Owner Email</Label>
                            <div>{props.proof.approvedByEmail}</div>
                        </Col>
                    </Row>

                    <BlokchainDetails proof={props.proof} />
                    <Attachment proofId={props.proof.txMessage} />
                </ModalBody>
                <div
                    style={{
                        borderTop: '1px solid rgba(0,0,0,0.2)',
                        marginTop: '1rem',
                        marginBottom: '1rem',
                    }}
                />
                <ModalBody
                    style={{
                        paddingLeft: '1.25rem',
                        paddingRight: '1.25rem',
                    }}
                />
            </Form>
        </Modal>
    );
}
