import { Button, Form, FormGroup, Label, Input, FormText, Row, Col } from 'reactstrap';
import React, { useState, useContext, useMemo } from 'react';

import UserProofsContext from '../../contexts/user-proofs';
import { AttachmentsBulkUpload } from './AttachmentsBulkUpload';
import WithHeaderAndFooter from '../WithHeaderAndFooter';

let timeout: any;
export function AttachmentsBulk() {
    const userData = useContext(UserProofsContext);

    return (
        <>
            <WithHeaderAndFooter>
                <AttachmentsBulkUpload />
            </WithHeaderAndFooter>
        </>
    );
}
