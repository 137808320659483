import React from 'react';
import classnames from 'classnames';

import './styles.css';

export default function AuthPanel({
    title,
    label,
    children,
    notAuthRegisterMode,
    isPopup,
}: {
    title?: string;
    label?: string;
    children: any;
    notAuthRegisterMode?: boolean;
    isPopup?:boolean;
}) {
    //const authPanelWrapperClass = notAuthRegisterMode ? 'col-lg-12' : 'col-lg-5 mt-5';

    return (
        <div
            className={classnames('auth-panel', {
                'auth-panel-not-auth-register-mode': notAuthRegisterMode,
            })}
        >
            {title ? (
                <div className="text-center mb-4 auth-panel__title">
                    {title ? <h1 className="card-text" dangerouslySetInnerHTML={{ __html: title }} /> : ''}
                </div>
            ) : (
                ''
            )}
            <div className={isPopup ? "w-100 mx-auto auth-panel__body px-4" :"w-100 mx-auto auth-panel__body"}>
                {label ? (
                    <label className="gray text-center w-100">It has to be unique and up to 32 charactors long.</label>
                ) : (
                    ''
                )}
                {children}
            </div>
        </div>
    );
}
