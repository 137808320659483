import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import "./styles.css"

export default function Grid({ options, ...gridProperties }:any) {
    const gridOptions: any = {
        onFirstDataRendered: () => {
            const allColumnIds: any = [];
            gridOptions.columnApi.getAllColumns().forEach(function(column: any) {
                allColumnIds.push(column.colId);
            });
            if(gridOptions.columnApi){
                gridOptions.columnApi.autoSizeColumns(allColumnIds);
                gridOptions.api.sizeColumnsToFit();
            }
            
        },
        getRowHeight: function(params: any) {
            return 64;
        },
        headerHeight:64,
        rowClass:"app-grid-row",
        suppressCellSelection: true
    };

    options.columnDefs.forEach((item: any) => {
        item.cellStyle = { 'line-height': '50px' };
    });
    return (
        <AgGridReact
        className="app-grid"
            gridOptions={gridOptions}
            columnDefs={options.columnDefs}
            rowData={options.data}
            onGridReady={options.onGridReady}
            {...gridProperties}
        />
    );
}
