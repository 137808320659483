export const labelStyle: any = {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    color: '#BDBDBD',
};

export const labelStyleBig: any = {
    fontSize: '16px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    color: '#BDBDBD',
};
