import React, { useState, useContext } from 'react';
import Header from '../Header';
import WithHeaderAndFooter from '../WithHeaderAndFooter';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import { getSearchresults, attachmentSearch } from '../../api';
import { withRouter, RouteComponentProps } from 'react-router';
import appLoginDispatcher from '../../contexts/app-dispatch';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Container from 'reactstrap/lib/Container';
import Dropzone from 'react-dropzone';
import { sha256, sha224 } from 'js-sha256';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from '../../constants';
import UserContext from '../../contexts/user';
import ReactGA from 'react-ga';

loadReCaptcha(RECAPTCHA_SITE_KEY);

ReactGA.event({
  category: 'User',
  action: 'search'
});

function useFormField(initialValue: string = '') {
    const [value, setValue] = useState(initialValue);

    function onChange(event: any) {
        setValue(event.target.value);
    }

    return {
        value,
        onChange,
    };
}

interface ISearchPage extends RouteComponentProps {}
function SearchPage(props: ISearchPage) {
    const search = useFormField();
    const dispatch = useContext(appLoginDispatcher)!;
    const user = useContext(UserContext);
    const [recaptchaToken, setRecaptchaToken] = useState();
    const [showLoaderMask, setShowLoaderMask] = useState(false);

    let recaptchaInstance: any;

    const executeCaptcha = async function() {
        const a = await recaptchaInstance.execute(RECAPTCHA_SITE_KEY, { action: 'searchByDocument' });
    };

    function onLoadRecaptcha() {
        executeCaptcha();
    }

    async function verifyCallback(response: any) {
        setRecaptchaToken(response);
    }

    async function onSubmit(event: any) {
        event.preventDefault();
        try {
            const result = await getSearchresults(search.value);
            if (!!result.Count) {
                props.history.push(`/search/${search.value}`);
            } else {
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: { message: `device ID - ${search.value} is not found`, error: true },
                });
                return;
            }
        } catch (e) {}
    }

    async function onDrop(acceptedFiles: any) {
        if(user){
            setShowLoaderMask(true);
            let reader = new FileReader();
            reader.readAsArrayBuffer(acceptedFiles[0]);

            let type = acceptedFiles[0].name.substring(
                acceptedFiles[0].name.lastIndexOf('.') + 1,
                acceptedFiles[0].name.length
            );
            type = type.toLocaleLowerCase();

            let allowedExtensions = [
                'pdf',
                'png',
                'jpg',
                'gif',
                'jpeg',
                'txt'
            ] ;

            if (
                acceptedFiles[0].size > 10023317 ||
                allowedExtensions.indexOf(type.toLowerCase()) === -1
            ) {
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: { message: 'Not allowed type or file size exceed 10Mb', error: true },
                });
                return;
            }
            setTimeout(async () => {

                          var sha256Code = sha256(reader.result as string).toUpperCase();
                          try{
                              const result = await attachmentSearch(sha256Code, recaptchaToken);
                              console.log(result);
                              setShowLoaderMask(false);
                              props.history.push(`/search/${result}`);
                          }
                          catch(e){
                          setShowLoaderMask(false)
                          dispatch({
                              type: 'SHOW_MESSAGE',
                              payload: { message: 'No match found', error: true },
                          });

            }
            }, 1000);
        }else{
            props.history.push('/login')
        }

    }

    return (
        <>
            <WithHeaderAndFooter>
                <h2 className="px-5 pt-5 pb-2 text-center">Lookup ownership of any device</h2>
                <div className="mx-3 row justify-content-center">
                    <Form className="form col-lg-4 col-md-8 col-sm-12" onSubmit={onSubmit}>
                        <FormGroup className="d-flex">
                            <Input
                                placeholder="Device ID or Serial Number"
                                autoFocus
                                style={{
                                    color: '#333333',
                                    backgroundColor: '#E0E0E0',
                                }}
                                value={search.value}
                                onChange={search.onChange}
                            />
                            <Button type="submit" className="btn btn-primary ml-3">
                                Check
                            </Button>
                        </FormGroup>
                    </Form>

                </div>

                <Container className="text-center justify-content-end">
                    <Dropzone onDrop={onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <section>

                                <div
                                    className="mx-auto drop-zone grey-text"
                                    {...getRootProps({
                                        style: {
                                            height: 313,
                                            marginTop: '50px',
                                            margin: '0 5px',
                                            border: '2px dashed #2F80ED',
                                            borderRadius: '5px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            maxWidth: '679px',
                                            cursor: 'pointer',
                                        },
                                    })}
                                >
                                    <input {...getInputProps()} />
                                    <div>
                                        <h3 style={{color: '#2F80ED'}}>Drag and Drop any file to search</h3>

                                        <div>
                                            <span style={{color: '#2F80ED'}}>The file will not be uploaded</span>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <ReCaptcha
                        ref={(e: any) => (recaptchaInstance = e)}
                        sitekey={RECAPTCHA_SITE_KEY}
                        action="searchByDocument"
                        size="invisible"
                        render="explicit"
                        // size="normal"
                        badge="bottomleft"
                        onloadCallback={onLoadRecaptcha}
                        verifyCallback={verifyCallback}
                    />
                </Container>
                {showLoaderMask ? (
                <div className="attachment-mask">
                    <div className="text-center fa-5x px-2 pt-1">
                        <i className="fas fa-circle-notch fa-spin primary blue-text" />
                    </div>
                </div>
            ) : (
                ''
            )}
            </WithHeaderAndFooter>
        </>
    );
}

export default withRouter(SearchPage);
