import React, { useState, useEffect, useContext, useMemo, ReactElement } from 'react';
import Header from '../Header';
import { IProof } from '../../types';
import { getSearchresults, passwordlessSignup, putActionProof, requestDetails, requestTransfer } from '../../api';
import Table from 'reactstrap/lib/Table';
import UserContext from '../../contexts/user';
import Transactions from '../Transactions';
import Attachments from '../Attachments';
import Messages from '../Messages';
import appLoginDispatcher from '../../contexts/app-dispatch';
import Footer from '../Footer';
import { BlokchainDetails } from '../BlockchainDetails';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import { AuthContainerSelfControlled } from '../Auth';
import WithHeaderAndFooter from '../WithHeaderAndFooter';
import { DeviceRegisteredPopup } from '../DeviceRegisteredPopup';
import { DeviceRequestPopup } from '../DeviceRequestPopup';
import NotEnoughFundsPopup from '../NotEnoughFundsPopup';
import { PROOF_ACTION_PRICE, RECAPTCHA_SITE_KEY } from '../../constants';
import UserCreditContext from '../../contexts/user-credit';
import ModalBody from 'reactstrap/lib/ModalBody';
import FormGroup from 'reactstrap/lib/FormGroup';
import Form from 'reactstrap/lib/Form';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';

loadReCaptcha(RECAPTCHA_SITE_KEY);

export default function ProofIdSearch({ match }: { match: any }) {
    const [proofIdData, setProofIdData] = useState<IProof[]>([]);
    const user = useContext(UserContext);
    const credit = useContext(UserCreditContext);
    const dispatch = useContext(appLoginDispatcher)!;
    const [refreshResults, setRefreshResults] = useState(false);
    const [canRequest, setCanRequest] = useState(true);
    const [showRegistrationPopup, setShowRegistrationPopup] = useState(false);
    const [chalangingUser, setChalangingUser] = useState();
    const [requestClickButton, setRequestClickButton] = useState<'detail' | 'transfer' | ''>('');
    const [showDeviceRegisteredPopup, setShowDeviceRegisteredPopup] = useState(false);
    const [showNotEnoughFundsPopup, setShowNotEnoughFundsPopup] = useState(false);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [requestTypeButton, setRequestTypeButton] = useState<'detail' | 'transfer' | ''>('');
    const [recaptchaTokenDetail, setRecaptchaTokenDetail] = useState();
    const [recaptchaTokenTransfer, setRecaptchaTokenTransfer] = useState();
    const [showLoaderMask, setShowLoaderMask] = useState(false);

    console.log('---', proofIdData);

    let recaptchaInstanceDetail: any;
    let recaptchaInstanceTransfer: any;

    const executeCaptchaDetail = async function(action:string) {
        await recaptchaInstanceDetail.execute(RECAPTCHA_SITE_KEY, { action: action });
    };

    const executeCaptchaTransfer = async function(action:string) {
        await recaptchaInstanceDetail.execute(RECAPTCHA_SITE_KEY, { action: action });
    };

    function onLoadRecaptchaDetail() {
        executeCaptchaDetail('requestDetail');
    }
    function onLoadRecaptchaTransfer() {
        executeCaptchaTransfer('requestTransfer');
    }

    async function verifyCallbackDetail(response: any) {
        setRecaptchaTokenDetail(response);
    }

    async function verifyCallbackTransfer(response: any) {
        setRecaptchaTokenTransfer(response);
    }

    function toggle() {
        setShowNotificationModal(prevState => !prevState);
    }

    const registerProof = useMemo(
        () =>
            proofIdData.filter(proof => proof.txType === 'registerProof')[0] || {
                publicAddress: '',
                timestamp: new Date(),
            },
        [proofIdData]
    );
    const { publicAddress, timestamp } = registerProof;

    const isProofOwner = useMemo(() => {
        if (user && registerProof) {
            return registerProof.username === user.username;
        }
        return false;
    }, [registerProof, user]);
    const proofId = decodeURIComponent(match.params.id);

    useEffect(() => {
        if (requestClickButton === 'detail' && user) {
            toggleRegistrationModal();
            onRequestDetailsClick();
            setRequestClickButton('');
        } else if (requestClickButton === 'transfer' && user) {
            toggleRegistrationModal();
            onRequestTransferClick();
            setRequestClickButton('');
        }
    }, [user, requestClickButton]);

    useEffect(() => {
        (async () => {
            try {
                console.log(`Proof id ${proofId}`);
                const result = await getSearchresults(proofId);
                setProofIdData((result.Items || []) as IProof[]);
                const pendingTransactions = (result.Items as IProof[]).filter(proof => {
                    return (
                        proof.txStatus === 'requestProof' ||
                        proof.txStatus === 'requestTransfer' ||
                        proof.txStatus === 'processing'
                    );
                });
                if (pendingTransactions.length === 0) {
                    setCanRequest(true);
                } else {
                    setCanRequest(false);
                }
            } catch (e) {
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: { message: 'Error while getting data', error: true },
                });
                console.log('error', e);
            }
        })();
    }, [proofId, refreshResults]);

    function toggleRegistrationModal() {
        setShowRegistrationPopup(value => !value);
    }

    function dataUpdate() {
        setRefreshResults(prevState => !prevState);
    }

    async function onRequestDetailOrTransferAprove(event: any) {
        event.preventDefault();
        if (credit.availableCredit! < PROOF_ACTION_PRICE) {
            setShowNotEnoughFundsPopup(true);
            return;
        }
        if (requestTypeButton === 'detail') {
            setShowNotificationModal(false);
            setCanRequest(false);
            setShowLoaderMask(true);
            try {
                  await requestDetails({
                    publicAddress,
                    txStatus: 'requestProof',
                    txMessage: proofId,
                    reToken: recaptchaTokenDetail,
                });
                setShowLoaderMask(false)
                setShowDeviceRegisteredPopup(true);
            } catch (e) {
                setShowLoaderMask(false)
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: { message: 'Something wrong while requesting proof details', error: true },
                });
            }
        } else if (requestTypeButton === 'transfer') {
            setCanRequest(false);
            setShowNotificationModal(false);
            setShowLoaderMask(true)
            try {
                await requestTransfer({
                    publicAddress: publicAddress,
                    txStatus: 'requestTransfer',
                    txMessage: proofId,
                    reToken: recaptchaTokenTransfer,
                });
                setShowLoaderMask(false)
                setShowDeviceRegisteredPopup(true);
            } catch (e) {
                setShowLoaderMask(false)
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: { message: 'Something wrong while requesting device transfer', error: true },
                });
            }
        }
    }

    function onRequestDetailsClick() {
        if (user) {
            setShowNotificationModal(true);
            setRequestTypeButton('detail');
        } else {
            setRequestClickButton('detail');
            setShowRegistrationPopup(true);
        }
    }

    function onRequestTransferClick() {
        if (user) {
            setShowNotificationModal(true);
            setRequestTypeButton('transfer');
        } else {
            setRequestClickButton('transfer');
            setShowRegistrationPopup(true);
        }
    }

    let requestButtons;
    if (!isProofOwner) {
        requestButtons = (
            <div className="container row mt-4">
                {canRequest ? (
                    <>
                        <div>
                            <Button className="btn gray-background-btn mr-4" onClick={onRequestDetailsClick}>
                                Request Details
                            </Button>
                            <ReCaptcha
                                ref={(e: any) => (recaptchaInstanceDetail = e)}
                                sitekey={RECAPTCHA_SITE_KEY}
                                action="requestDetail"
                                size="invisible"
                                render="explicit"
                                // size="normal"
                                badge="bottomleft"
                                onloadCallback={onLoadRecaptchaDetail}
                                verifyCallback={verifyCallbackDetail}
                            />
                        </div>
                        <div>
                            <Button className="btn gray-background-btn" onClick={onRequestTransferClick}>
                                Request Transfer
                            </Button>
                            <ReCaptcha
                                ref={(e: any) => (recaptchaInstanceTransfer = e)}
                                sitekey={RECAPTCHA_SITE_KEY}
                                action="requestTransfer"
                                size="invisible"
                                render="explicit"
                                // size="normal"
                                badge="bottomleft"
                                onloadCallback={onLoadRecaptchaTransfer}
                                verifyCallback={verifyCallbackTransfer}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <Button disabled className="btn gray-background-btn mr-4" onClick={onRequestDetailsClick}>
                            Request Details
                        </Button>
                        <Button disabled className="btn gray-background-btn" onClick={onRequestTransferClick}>
                            Request Transfer
                        </Button>
                    </>
                )}
            </div>
        );
    } else {
        requestButtons = '';
    }

    return (
        <>
            <WithHeaderAndFooter>
                <div className="container-fluid proof pt-5">
                    <div className="container">
                        <div className="search-page-text mb-2">Device {proofId} is found.</div>
                        <BlokchainDetails proof={registerProof} />
                        {requestButtons}
                    </div>
                </div>
            </WithHeaderAndFooter>
            {showNotificationModal ? (
                <Modal toggle={toggle} isOpen={true}>
                    <ModalBody>
                        <div className="pt-3 text-center">$1 will be debited from your account.</div>
                        <Form onSubmit={onRequestDetailOrTransferAprove} className="w-100 mx-auto text-center mt-4">
                            <FormGroup>
                                <Button type="submit" className="btn grid-btn-green mr-3">
                                    Request
                                </Button>
                                <Button className="btn gray-background-btn" onClick={toggle}>
                                    Cancel
                                </Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            ) : (
                ''
            )}
            {showRegistrationPopup ? (
                <Modal toggle={toggleRegistrationModal} isOpen={true}>
                    <AuthContainerSelfControlled
                        isPopup
                        modalToggle={toggleRegistrationModal}
                        notAuthRegisterMode
                        defaultState="signup"
                        returnedUser={chalangingUser}
                        shouldRedirectAfterSignUp={false}
                    />
                </Modal>
            ) : (
                ''
            )}
            {showDeviceRegisteredPopup ? (
                <DeviceRequestPopup name={proofId} modalToggle={() => setShowDeviceRegisteredPopup(false)} />
            ) : (
                ''
            )}
            {showNotEnoughFundsPopup ? <NotEnoughFundsPopup /> : ''}
            {showLoaderMask ? (
                <div className="attachment-mask">
                    <div className="text-center fa-5x px-2 pt-1">
                        <i className="fas fa-circle-notch fa-spin primary blue-text" />
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
}
