import { Button, Form, FormGroup, Label, Input, FormText, Row, Col } from 'reactstrap';
import React, { useState, useContext, useMemo } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import { Attachment } from '../Attachment';
import FirstTimeMask from '../FirstTimeMask';
import { BlokchainDetails } from '../BlockchainDetails';
import { getUserCredits, updateProofParams } from '../../api';
import appLoginDispatcher from '../../contexts/app-dispatch';
import { labelStyle } from '../inline-styles';
import Transactions from '../Transactions';
import UserProofsContext from '../../contexts/user-proofs';
import { MY_PROOFS_FILTER } from '../../constants';
import { AttachmentsForAllProofs } from './AttachmentsForAllProofs';


let timeout: any;
export function AttachmentsOfProofs() {
    const userData = useContext(UserProofsContext);
    const dispatch = useContext(appLoginDispatcher)!;
    const [uploadFiles, setUploadFiles] = useState();
    const [showFirstTimeMask, setShowFirstTimeMask] = useState(true);

    const myProofs = useMemo(() => {
        const proofs = userData.filter(proof => {
            return (
                proof.txOwner === MY_PROOFS_FILTER ||
                proof.txStatus === 'created' ||
                proof.txStatus === 'processing' ||
                proof.txStatus === 'retry' ||
                proof.txStatus === 'Error'
            );
        });
        proofs.forEach(proof => {
            const txMessage = proof.txMessage;
            const attachments = userData.filter(d => d.txMessage === txMessage && d.txType === 'emitIpfsKey');
            proof.attachments = attachments;
            if (proof.attachments.length > 0 || proof.txName || proof.txDetails) {
                setShowFirstTimeMask(false);
            }
        });
        if (myProofs) {
            console.log(myProofs[0].txStatus);
        }

        return proofs;
    }, [userData]);

    function firstTimeModalToggle() {
        setShowFirstTimeMask(prevState => !prevState);
    }

    return (
        <>


            <AttachmentsForAllProofs />
        </>
    );
}
