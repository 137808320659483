import React, { useState, useContext, useMemo } from 'react';
import { saveText, getSearchresults, getUserProofs, getUserCredits } from '../../api';
import Button from 'reactstrap/lib/Button';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import Input from 'reactstrap/lib/Input';
import Modal from 'reactstrap/lib/Modal';
import { labelStyle } from '../inline-styles';
import ShadowTitle from '../ShadowText';
import Grid from '../Grid';
import UserProofsContext from '../../contexts/user-proofs';
import { MY_PROOFS_FILTER, PROOF_ACTION_PRICE } from '../../constants';
import AuthPanel from '../Auth/panel';
import appLoginDispatcher from '../../contexts/app-dispatch';
import { withRouter, RouteComponentProps } from 'react-router';
import { RowClickedEvent, CellMouseOverEvent, CellClickedEvent, GridApi } from 'ag-grid-community';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { IProof } from '../../types';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import UserContext from '../../contexts/user';
import CanRegisterProofContext from '../../contexts/can-register-proof';
import { TextDetails } from '../TextDetails';
import { DeviceRegisteredPopup } from '../DeviceRegisteredPopup';
import UserCreditContext from '../../contexts/user-credit';
import NotEnoughFundsPopup from '../NotEnoughFundsPopup';
import AttachmentCellRenderer from './attachment-cell-renderer';
import FirstTimeMask from '../FirstTimeMask';
import ReactGA from 'react-ga';
import ReactTooltip from 'react-tooltip';


function useFormField(initialValue: string = '') {
    const [value, setValue] = useState(initialValue);
    function onChange(event: any) {
        setValue(event.target.value);
    }
    return {
        value,
        onChange,
    };
}
TimeAgo.locale(en);
let timeout: any;

function MyText({ history }: RouteComponentProps) {
    const userData = useContext(UserProofsContext);
    const credit = useContext(UserCreditContext);
    let canRegisterProof = useContext(CanRegisterProofContext);
    const dispatch = useContext(appLoginDispatcher)!;
    const [registerNewDeviceModal, setRegisterNewDeviceModal] = useState(false);
    const [deviceDetailModal, setDeviceDetailModal] = useState(false);
    const registrationProofId = useFormFieldReg();
    const postPublicFlag = usePostPublicFlag();
    const [showCheck, setShowCheck] = useState(false);
    const [canRegister, setCanRegister] = useState(canRegisterProof);
    const [showAddon, setShowAddon] = useState(false);
    const [attachmnetGridApi, setAttachmnetGridApi] = useState<GridApi | null>(null);
    const filterInput = useFormFieldFilter();
    const [selectedProof, setSelectedProof] = useState(null);
    const [showDeviceRegisteredPopup, setShowDeviceRegisteredPopup] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [showNotEnoughFundsPopup, setShowNotEnoughFundsPopup] = useState(false);
    const [rowIndexOfSelectedProof, setRowIndexOfSelectedProof] = useState();
    const [showFirstTimeMask, setShowFirstTimeMask] = useState(false);
    const [showLoaderMask, setShowLoaderMask] = useState(false);

    ReactGA.event({
      category: 'User',
      action: 'myText'
    });

    const myProofsColumnDefs = [



        {
            headerName: 'Text',
            field: 'txMessage',
            cellRendererFramework: function TxMessageCellRenderer(props: any) {
                const value = props.getValue();
                return (
                    <div style={{ maxWidth: '200px' }} className="text-truncate">
                        {value}
                    </div>
                );
            },
        },
        {
            headerName: '',
            field: 'txStatus',
            cellRendererFramework: function pendingCellRenderer(props: any) {
                const value = props.getValue();
                if (value === 'processing' || value === 'created') {
                    return <div className="grid-request-info orange mt-3">PENDING</div>;
                }
                return <div style={{ width: '20px' }} />;
            },
        },
        {
            headerName: 'TRANSACTION Hash',
            field: 'txHash',
            cellRendererFramework: function TxHashCellRenderer(props: any) {
                const value = props.getValue();
                return (
                    <div style={{ maxWidth: '200px' }} className="truncate-hash">
                        {value}
                    </div>
                );
            },
        },
        {
            headerName: 'STATUS',
            field: 'txStatus',
        },
    ];

    function onGridReady(params: any) {
        setAttachmnetGridApi(params.api);
    }

    const myProofs = useMemo(() => {
        const proofs = userData.filter(proof => {
            return (
                proof.txType === 'publishMessage'
            );
        });
        proofs.forEach(proof => {
            const txMessage = proof.txMessage;
            const attachments = userData.filter(d => d.txMessage === txMessage && d.txType === 'emitIpfsKey');
            proof.attachments = attachments;
            if (proof.attachments.length > 0 || proof.txMessage || proof.txDetails) {
                setShowFirstTimeMask(false);
            }
        });
        if (myProofs) {
            console.log(myProofs[0].txStatus);
        }

        return proofs;
    }, [userData]);

    function deviceModalToggle() {
        setDeviceDetailModal(prevState => !prevState);
    }

    function firstTimeModalToggle(){
        setShowFirstTimeMask(prevState => !prevState);
    }

    function updateProofName(proof: any) {
        const rowNode = attachmnetGridApi!.getDisplayedRowAtIndex(rowIndexOfSelectedProof);
        rowNode.setData(proof);
        // attachmnetGridApi!.updateRowData(proof)
    }

    function toggle() {
        if (!registerNewDeviceModal && credit.availableCreditDecimal! < PROOF_ACTION_PRICE) {
            setShowNotEnoughFundsPopup(true);
            return;
        }
        setRegisterNewDeviceModal(prevState => !prevState);
    }

    async function onProofRegisterSubmit(event: any) {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }
        try {
            dispatch({ type: 'SHOW_LOADER' });
            setShowDeviceRegisteredPopup(true);
            await saveText({
                postPublic: postPublicFlag.value,
                txMessage: registrationProofId.value,
            });
            const userCredit = await getUserCredits();
            dispatch({ type: 'SET_USER_CREDITS', payload: userCredit });
            const newProof: IProof = {
                publicAddress: '',
                txMessage: registrationProofId.value,
                network : 'algomain',
                txHash: 'Pending submission',
                timestamp: new Date(),
                txType: 'publishMessage',
                txStatus: 'created',
            };
            userData.unshift(newProof);
            const myClonedArray = Object.assign([], userData);
            dispatch({ type: 'SET_USER_PROOFS', payload: myClonedArray });
            //setCanRegister(false);
            toggle();
            setShowLoaderMask(false);

        } catch (e) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: "Something went wrong...Please contact support", error: true },
            });
            toggle();
        }
    }

    function useFormFieldFilter(initialValue: string = '') {
        const [value, setValue] = useState(initialValue);

        function onChange(event: any) {
            setValue(event.target.value);
            if (!attachmnetGridApi) {
                return;
            }
            attachmnetGridApi.setQuickFilter(event.target.value);
        }

        return {
            value,
            onChange,
        };
    }

    function usePostPublicFlag(initialValue: boolean = false) {
        const [value, setValue] = useState(initialValue);

        function onChange(event: any) {
          if (event.target.checked) {
                setValue(true);
                } else {
                    setValue(false);
                }

          }

        return {
            value,
            onChange,
        };
    }

    function useFormFieldReg(initialValue: string = '') {
        const [value, setValue] = useState(initialValue);

        function onChange(event: any) {
            setValue(event.target.value);
            const val = event.target.value;
            clearTimeout(timeout);
            setShowAddon(false);
            setShowSpinner(true);
            timeout = setTimeout(function() {
                console.log('Input Value:', val);
                doSearch(val);
            }, 2000);
        }

        return {
            value,
            onChange,
        };
    }

    async function doSearch(proofId: string) {
        if (proofId == '') {
            setShowAddon(false);
            setShowSpinner(false);
            return;
        }
        //const searchResult = await getSearchresults(proofId);
        setShowCheck(true);
        setShowSpinner(false);
        setShowAddon(true);
    }

    function validateForm() {
        let isFormValid = true;
        if (registrationProofId.value == '') {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'All fields are required', error: true },
            });
            isFormValid = false;
        }

        return isFormValid;
    }

    const options = {
        columnDefs: myProofsColumnDefs,
        data: myProofs,
    };

    return (
        <>
            <div className="container-fluid proof">
                <div className="container pt-5">
                    <div className="row  border-bottom">
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <p data-tip="Create and save plain or signed text directly on the blockchain">
                            <h5>My Text  <i className="fas fa-info-circle"></i></h5></p>
                            <ReactTooltip />
                        </Col>

                        <Col className="text-right align-self-center row" lg={6} md={6} sm={12} xs={12}>
                            <Input
                                className="border-0 col-4 ml-auto"
                                type="text"
                                id="filter-text-box"
                                placeholder="Search"
                                value={filterInput.value}
                                onChange={filterInput.onChange}
                            />
                            {canRegister ? (
                                <Button onClick={toggle} className="btn btn-primary  pr-2">
                                    <i className="fas fa-plus" /> New
                                </Button>
                            ) : (
                                <Button disabled onClick={toggle} className="btn btn-primary ml-auto pr-2">
                                    <i className="fas fa-plus" /> New
                                </Button>
                            )}

                            <Button
                                onClick={() => dispatch({ type: 'LOAD_USER_PROOFS' })}
                                className="btn btn-primary ml-3"
                            >
                                <i className="fas fa-sync" />
                            </Button>
                        </Col>
                    </div>
                    <div
                        className="ag-theme-balham mb-3 mt-3"
                        style={{
                            height: '500px',
                        }}
                    >
                        <Grid
                            options={options}
                            onGridReady={onGridReady}
                            onRowClicked={(event: RowClickedEvent) => {
                                setRowIndexOfSelectedProof(event.rowIndex);
                                setSelectedProof(event.data);
                                deviceModalToggle();
                            }}
                        />
                    </div>
                </div>
            </div>
            {registerNewDeviceModal ? (
                <Modal toggle={toggle} isOpen={true}>
                    <i onClick={toggle} className="float-right fas fa-times popup-close-icon" />
                    <AuthPanel notAuthRegisterMode title="New Text">
                        <Form onSubmit={onProofRegisterSubmit} className="w-95 pt-4 mx-auto">
                            <FormGroup>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                    <InputGroup>


                                        <textarea
                                            className="form-control mb-2"
                                            name="proofId"
                                            style={{
                                                height: 110,
                                            }}
                                            placeholder="Enter text up to 1000 characters. By default the text will be signed and signature will be saved on the blockchain. If you want to save it as plain text, check the box below. "
                                            value={registrationProofId.value}
                                            onChange={registrationProofId.onChange}
                                            maxLength={1000}
                                        />

                                        {showSpinner ? (
                                            <div className="fa-x px-2 pt-1">
                                                <i className="fas fa-sync fa-spin primary" />
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        {showAddon ? (
                                            <>
                                                {showCheck ? (
                                                    <InputGroupAddon className="align-self-center" addonType="append">
                                                        <i className="fas fa-check green-icon" />
                                                    </InputGroupAddon>
                                                ) : (
                                                    <InputGroupAddon className="align-self-center" addonType="append">
                                                        <i className="fas fa-times-circle red-icon" />
                                                    </InputGroupAddon>
                                                )}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </InputGroup>

                                      <span style={labelStyle}>Save in plain text ? </span>
                                      <input
                                           type="checkbox"
                                           name="isPublic"
                                           onChange={postPublicFlag.onChange}


                                         />


                                      </Col>
                            </FormGroup>
                            <FormGroup className="text-center">
                                <span style={{
                                    color: '#2F80ED',
                                }}>5¢($0.05) will be debited from your balance.</span>
                                <Col className="mt-2" xs={12} sm={12} md={12} lg={12}>
                                    {showCheck ? (
                                        <Button type="submit" className="btn btn-primary w-100 mx-auto">
                                            Save
                                        </Button>
                                    ) : (
                                        <Button disabled type="submit" className="btn btn-primary w-100 mx-auto">
                                            Save
                                        </Button>
                                    )}
                                </Col>
                            </FormGroup>
                        </Form>
                    </AuthPanel>
                </Modal>
            ) : (
                ''
            )}
            {showFirstTimeMask ? <FirstTimeMask firstTimeModalToggle={firstTimeModalToggle}/> : ''}
            {deviceDetailModal ? (
                <TextDetails
                    proofToUpdateName={updateProofName}
                    proof={selectedProof}
                    modalToggle={deviceModalToggle}
                />
            ) : (
                ''
            )}
            {showDeviceRegisteredPopup ? (
                <DeviceRegisteredPopup
                    name={registrationProofId.value}
                    modalToggle={() => setShowDeviceRegisteredPopup(false)}
                />
            ) : (
                ''
            )}
            {showNotEnoughFundsPopup ? <NotEnoughFundsPopup /> : ''}
        </>
    );
}

export default withRouter(MyText);
