import { IUser, IProof, ITokens, ICognitoResult, ICredit, IAttachment } from '../types';
import {
    REGION,
    USER_POOL_ID,
    APP_CLIENT_ID,
    POOL_DATA,
    IDENTYTY_POOL_ID,
    NETWORK,
    TIME_STAMP,
    TABLE_NAME,
    INDEX_NAME,
    USER_POOL_WEB_CLIENT_ID,
} from '../cognitoConf';
import AWS from 'aws-sdk';
import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserSession,
    CognitoAccessToken,
    CognitoIdToken,
    CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import toastr from 'toastr';
import { String } from 'aws-sdk/clients/sqs';
import Amplify, { Auth } from 'aws-amplify';
import axios from 'axios';
import { resolve } from 'dns';
import { Credentials } from '@aws-amplify/core';
import { BASE_API_URL } from '../constants';
import { sha256, sha224 } from 'js-sha256';

Amplify.configure({
    Auth: {
        production: false,
        region: 'us-east-1',
        userPoolId: 'us-east-1_HeMQEOrYm',
        userPoolWebClientId: 'djokedflq9hnbn48l7n9iujb2',
        IdentityPoolId: 'us-east-1:3dde3b2e-3bc3-4861-bbba-fa8bdc88404e',
        federatedPoolId: 'us-east-1:3dde3b2e-3bc3-4861-bbba-fa8bdc88404e',
    },
});

function refreshToken() {
    return new Promise(async (resolve, reject) => {
        const data = {
            token: '',
            expires_at: '',
            identity_id: '',
        };
        resolve(data);
    });
}

Auth.configure({
    refreshHandlers: {
        developer: refreshToken,
    },
});

export async function passwordlessSignin(email: string) {
    const newUser = await Amplify.Auth.signIn(email);
    return newUser;
}

export async function passwordlessSignup(email: string, fullName?: string) {
    return new Promise(async (resolve, reject) => {
        const randomValues = new Uint8Array(30);
        window.crypto.getRandomValues(randomValues);

        const randomString = Array.from(randomValues)
            .map(intToHex)
            .join('');

        const params = {
            username: email,
            password: randomString,
            attributes: {
                name: fullName,
            },
        };

        try {
            await Amplify.Auth.signUp(params);
            const returnedUser = await passwordlessSignin(email);
            resolve(returnedUser);
        } catch (e) {
            reject(e);
            return;
        }
    });
}

function intToHex(nr: number) {
    return nr.toString(16).padStart(2, '0');
}

export async function answerCustomChallenge(answer: string, cognitoUser: any) {
    return new Promise(async (resolve, reject) => {
        try {
            const newUser = await Amplify.Auth.sendCustomChallengeAnswer(cognitoUser, answer);
            resolve(newUser);
        } catch (e) {
            reject(e);
            return;
        }
        return;
    });
}

export async function updateUserData(email: string, fullName: string) {
    return new Promise(async (resolve, reject) => {
        Auth.currentAuthenticatedUser()
            .then(async (cognitoUser: any) => {
                try {
                    const result = await Amplify.Auth.updateUserAttributes(cognitoUser, {
                        email: email,
                        full_name: fullName,
                    });
                } catch (e) {}
            })
            .catch(err => {
                console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
                reject(err);
            });
    });
}

async function isAuthenticated() {
    try {
        await Amplify.Auth.currentSession();
        return true;
    } catch {
        return false;
    }
}

export function getMe(email: string): Promise<{ user: IUser }> {
    return new Promise((resolve, reject) => {
        Auth.currentAuthenticatedUser({ bypassCache: true })
            .then((cognitoUser: any) => {
                resolve({
                    user: {
                        email: cognitoUser.getUsername(),
                        username: cognitoUser.attributes.sub,
                        fullName: cognitoUser.attributes.name,
                    },
                });
            })
            .catch(err => {
                console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
                reject(err);
            });
    });
}

export function login(email: string, password: string): Promise<{ user: IUser; token: ITokens }> {
    return new Promise((resolve, reject) => {
        const authenticationData = {
            Username: email,
            Password: password,
        };
        const authenticationDetails = new AuthenticationDetails(authenticationData);

        const userPool = new CognitoUserPool(POOL_DATA);

        const userData = {
            Username: email,
            Pool: userPool,
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.sendCustomChallengeAnswer(authenticationDetails, {
            onSuccess: function(result) {
                const tokens = {
                    accessToken: result.getAccessToken().getJwtToken(),
                    idToken: result.getIdToken().getJwtToken(),
                    refreshToken: result.getRefreshToken().getToken(),
                };

                const payload = result.getAccessToken().payload;

                resolve({ user: { email: email, username: payload.username }, token: tokens });
            },

            onFailure: function(err) {
                reject(err);
            },
        });
    });
}

export function userRegistration(email: string, password: string) {
    return new Promise((resolve, reject) => {
        const userPool = new CognitoUserPool(POOL_DATA);

        //const attributeList = [];
        const attributeList: CognitoUserAttribute[] = [];
        const dataEmail = {
            Name: 'email',
            Value: email,
        };

        let attributeEmail = new CognitoUserAttribute(dataEmail);

        attributeList.push(attributeEmail);

        userPool.signUp(email, password, attributeList, [], function(err, result) {
            if (err) {
                reject(err);
            }
            if (result) {
                const cognitoUser = result.user;
                resolve(result);
                console.log('user name is ' + cognitoUser.getUsername());
            }
        });
    });
}

export function forgotPassword(email: string) {
    const userPool = new CognitoUserPool(POOL_DATA);

    const userData = {
        Username: email,
        Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
        onSuccess: function(result) {
            console.log('call result: ' + result);
        },
        onFailure: function(err) {
            alert(err);
        },
    });
}

export function comfirmPassword(email: string, verificationCode: string, newPassword: string): Promise<{}> {
    const userPool = new CognitoUserPool(POOL_DATA);

    const userData = {
        Username: email,
        Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onFailure: function(err) {
                console.log('rejected');
                reject(err);
            },
            onSuccess: function() {
                resolve();
            },
        });
    });
}

export function changePassword(email: string, oldPassword: string, newPassword: string): Promise<{}> {
    return new Promise((resolve, reject) => {
        const userPool = new CognitoUserPool(POOL_DATA);

        var cognitoUser = userPool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession(function(err: any, session: any) {
                if (err) {
                    console.log(err);
                    return;
                }
            });

            cognitoUser.changePassword(oldPassword, newPassword, (error, success) => {
                if (success) {
                } else {
                }
            });
        }
    });
}

export function logOut() {
    Auth.currentAuthenticatedUser()
        .then((cognitoUser: CognitoUser) => {
            Auth.signOut();
        })
        .catch(err => {
            console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
        });
    localStorage.clear();
}

export function getLatestEvents(): Promise<{ Items: {} } | AWS.DynamoDB.DocumentClient.QueryOutput> {
    return new Promise((resolve, reject) => {
        if (!AWS.config.credentials) {
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
            });
        }
        AWS.config.update({ region: REGION });
        var network = NETWORK;

        var timestampColumn = TIME_STAMP;

        var docClient = new AWS.DynamoDB.DocumentClient();
        var params = {
            TableName: TABLE_NAME,
            IndexName: INDEX_NAME,

            KeyConditionExpression: 'network = :network',

            ExpressionAttributeValues: {
                ':network': network,
            },
            Limit: 20,
            ScanIndexForward: false,
        };
        docClient.query(params, function(err, data) {
            if (err) {
                console.log('Unable to read item: ' + JSON.stringify(err, undefined, 2));
                reject(err);
            } else {
                resolve(data);
                console.log(data);
            }
        });
    });
}

function getJwtToken() {
    return new Promise(async (resolve, reject) => {
        Auth.currentAuthenticatedUser().then((cognitoUser: any) => {
            resolve(cognitoUser.signInUserSession.getIdToken().getJwtToken());
        });
    });
}

export function getUserProofs(userName: string): Promise<IProof[]> {
    return new Promise(async (resolve, reject) => {
        const token: any = await getJwtToken();

        AWS.config.update({
            region: 'us-east-1',
        });

        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': token,
                },
            }),
        });
        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                const db = new AWS.DynamoDB.DocumentClient({
                    credentials: AWS.config.credentials,
                    region: AWS.config.region,
                });
                let params = {
                    TableName: 'Usertransaction2',

                    KeyConditionExpression: 'username = :username',

                    ExpressionAttributeValues: {
                        ':username': userName || cognitoUser.attributes.sub,
                    },
                    ScanIndexForward: false,
                };

                db.query(params, function(err, data) {
                    if (err) {
                        console.log('Unable to read item: ' + JSON.stringify(err, undefined, 2));
                        reject(err);
                    } else {
                        resolve(data.Items as IProof[]);
                    }
                });
            })
            .catch(err => {
                console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
                reject(err);
            });
    });
}

export function getSearchresults(
    searchText: string
): Promise<ICognitoResult | AWS.DynamoDB.DocumentClient.QueryOutput> {
    return new Promise(async (resolve, reject) => {
        AWS.config.update({
            region: 'us-east-1',
        });
        Auth.currentAuthenticatedUser()
            .then(async (cognitoUser: any) => {
                try {
                    const data = await doSearchCall(searchText);
                    resolve(data);
                } catch (e) {
                    reject(e);
                }
            })
            .catch(async err => {
                AWS.config.update({
                    credentials: new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: IDENTYTY_POOL_ID,
                    }),
                });
                try {
                    const data = await doSearchCall(searchText);
                    resolve(data);
                } catch (e) {
                    reject(e);
                }
            });
    });
}

async function doSearchCall(searchText: string): Promise<ICognitoResult | AWS.DynamoDB.DocumentClient.QueryOutput> {
    return new Promise(async (resolve, reject) => {
        const db = new AWS.DynamoDB.DocumentClient({
            credentials: AWS.config.credentials,
            region: AWS.config.region,
        });

        var params = {
            TableName: 'Usertransaction2',
            IndexName: 'txMessage-timestamp-index',
            KeyConditionExpression: 'txMessage = :txMessage',
            ExpressionAttributeValues: {
                ':txMessage': searchText,
            },
            ScanIndexForward: false,
        };
        db.query(params, function(err, data) {
            if (err) {
                console.log('Unable to read item: ' + JSON.stringify(err, undefined, 2));
                reject(err);
            } else {
                resolve(data);
                console.log(data);
            }
        });
    });
}

export function uploadAttachments(txMessage: String, file: File, desctription: string) {
    return new Promise(async (resolve, reject) => {
        const token: any = await getJwtToken();

        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                var s3 = new AWS.S3({
                    apiVersion: '2006-03-01',
                });

                if (!(file instanceof File)) {
                    toastr.options = {
                        positionClass: 'toast-top-right',
                        hideDuration: 300,
                        timeOut: 6000,
                    };
                    toastr.clear();
                    setTimeout(() => toastr.error(`Please choose a file to upload first.`), 300);
                    return;
                }

                const fileName = file.name;
                s3.upload(
                    {
                        Key: fileName,
                        Body: file,
                        Bucket: 'csqnc-jcsda-mvqvg-ugrao-emzck-sfgxg-kkhdi-dxahz-kudwf-hlloq',
                    },
                    function(err: any, data: any) {
                        if (err) {
                            toastr.options = {
                                positionClass: 'toast-top-right',
                                hideDuration: 300,
                                timeOut: 6000,
                            };
                            toastr.clear();
                            setTimeout(
                                () => toastr.error(`There was an error uploading your file: '${err.message}`),
                                300
                            );
                            return;
                        }
                    }
                );

                var fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);
                fileReader.onload = function() {
                    var sha256Code = sha256(fileReader.result as string).toUpperCase();
                    console.log(sha256Code);
                    var dataJson = JSON.stringify({
                        timestamp: Date.now(),
                        txMessage: txMessage,
                        txStatus: 'addAttachment',
                        s3FileName: fileName,
                        s3FileDescription: desctription,
                        username: cognitoUser.attributes.sub,
                        s3FileSHA256Code: sha256Code,
                        network: NETWORK,
                    });

                    var settings = {
                        async: true,
                        crossDomain: true,
                        url: BASE_API_URL + 'addAttachment',
                        method: 'POST',
                        headers: {
                            Authorization: token,
                            'Content-Type': 'application/json',
                        },
                        processData: false,
                        data: dataJson,
                    };

                    axios(settings)
                        .then(function(response) {
                            resolve(response);
                        })
                        .catch(err => {
                            if (err.response.status == 400) {
                                reject(err.response);
                            }
                        });
                };
            })
            .catch(err => {
                console.log("Couldn't get current user: " + err, err.stack);
                reject(err);
            });
    });
}

export function registerProof(paramItem: any) {
    return new Promise(async (resolve, reject) => {
        const token: any = await getJwtToken();

        AWS.config.update({
            region: 'us-east-1',
        });

        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': token,
                },
            }),
        });

        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                var data = JSON.stringify({
                    ...paramItem,
                    username: cognitoUser.attributes.sub,
                    timestamp: new Date().getTime(),
                    network: NETWORK,
                });

                console.log(data);
                var settings = {
                    async: true,
                    crossDomain: true,
                    url: BASE_API_URL + 'register',
                    method: 'POST',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                    },
                    processData: false,
                    data: data,
                };

                axios(settings).then(function(response) {
                    console.log(response);
                    resolve(data);
                });
            })
            .catch(err => {
                console.log("Request: Couldn't request proof or transfer: " + err, err.stack);
                reject(err);
            });
    });
}

export function requestDetails(paramItem: any) {
    return new Promise(async (resolve, reject) => {
        const token: any = await getJwtToken();

        AWS.config.update({
            region: 'us-east-1',
        });

        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': token,
                },
            }),
        });

        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                var data = JSON.stringify({
                    ...paramItem,
                    username: cognitoUser.attributes.sub,
                    timestamp: new Date().getTime(),
                    network: NETWORK,
                });
                console.log(data);
                var settings = {
                    async: true,
                    crossDomain: true,
                    url: BASE_API_URL + 'update/request/proof',
                    method: 'POST',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                    },
                    processData: false,
                    data: data,
                };

                axios(settings).then(function(response) {
                    console.log(response);
                    resolve(data);
                });
            })
            .catch(err => {
                console.log("Request: Couldn't request proof or transfer: " + err, err.stack);
                reject(err);
            });
    });
}

export function requestTransfer(paramItem: any) {
    return new Promise(async (resolve, reject) => {
        const token: any = await getJwtToken();

        AWS.config.update({
            region: 'us-east-1',
        });

        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': token,
                },
            }),
        });

        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                var data = JSON.stringify({
                    ...paramItem,
                    username: cognitoUser.attributes.sub,
                    timestamp: new Date().getTime(),
                    network: NETWORK,
                });
                console.log(data);
                var settings = {
                    async: true,
                    crossDomain: true,
                    url: BASE_API_URL + 'update/request/transfer',
                    method: 'POST',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                    },
                    processData: false,
                    data: data,
                };

                axios(settings).then(function(response) {
                    console.log(response);
                    resolve(data);
                });
            })
            .catch(err => {
                console.log("Request: Couldn't request proof or transfer: " + err, err.stack);
                reject(err);
            });
    });
}

export function putActionProof(paramItem: Partial<IProof>) {
    return new Promise(async (resolve, reject) => {
        const token: any = await getJwtToken();

        console.log(NETWORK);

        AWS.config.update({
            region: 'us-east-1',
        });

        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': token,
                },
            }),
        });

        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                var data = JSON.stringify({
                    ...paramItem,
                    username: cognitoUser.attributes.sub,
                    timestamp: new Date().getTime(),
                    network: NETWORK,
                });

                console.log(data);
                var settings = {
                    async: true,
                    crossDomain: true,
                    url: BASE_API_URL + 'register',
                    method: 'POST',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                    },
                    processData: false,
                    data: data,
                };

                axios(settings).then(function(response) {
                    console.log(response);
                    resolve(data);
                });
            })
            .catch(err => {
                console.log("Request: Couldn't request proof or transfer: " + err, err.stack);
                reject(err);
            });
    });
}

export function saveText(paramItem: Partial<IProof>) {
    return new Promise(async (resolve, reject) => {
        const token: any = await getJwtToken();

        console.log(NETWORK);

        AWS.config.update({
            region: 'us-east-1',
        });

        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': token,
                },
            }),
        });

        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                var data = JSON.stringify({
                    ...paramItem,
                    username: cognitoUser.attributes.sub,
                    timestamp: new Date().getTime(),
                    network: NETWORK,
                });

                console.log(data);
                var settings = {
                    async: true,
                    crossDomain: true,
                    url: BASE_API_URL + 'savetext',
                    method: 'POST',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                    },
                    processData: false,
                    data: data,
                };

                axios(settings).then(function(response) {
                    console.log(response);
                    resolve(data);
                });
            })
            .catch(err => {
                console.log("Save text: Issue with saving text: " + err, err.stack);
                reject(err);
            });
    });
}

export async function updateProof(paramItem: Partial<IProof>) {
    const token: any = await getJwtToken();
    const data = JSON.stringify({
        ...paramItem,
        network: NETWORK,
    });
    var settings = {
        async: true,
        crossDomain: true,
        url: BASE_API_URL + 'update/approve/proof',
        method: 'POST',
        'Access-Control-Allow-Origin': '*',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        processData: false,
        data: data,
    };
    axios(settings).then(function(response) {
        if (response.status === 200) {
            // resolve(response);
        } else {
            // reject(response);
        }
        console.log(response);
    });
}

export async function updateTransfer(paramItem: Partial<IProof>) {
    const token: any = await getJwtToken();
    const data = JSON.stringify({
        ...paramItem,
        network: NETWORK,
    });
    var settings = {
        async: true,
        crossDomain: true,
        url: BASE_API_URL + 'update/approve/transfer',
        method: 'POST',
        'Access-Control-Allow-Origin': '*',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        processData: false,
        data: data,
    };

    axios(settings).then(function(response) {
        if (response) {
            // reject(response);
        } else {
            // resolve(response);
        }
        console.log(response);
    });
}

export async function downloadAttachment(paramItem: Partial<IProof>) {
    return new Promise(async (resolve, reject) => {
        const token: any = await getJwtToken();

        AWS.config.update({
            region: 'us-east-1',
        });

        const idPool = {
            IdentityPoolId: IDENTYTY_POOL_ID,
            Logins: {
                'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': token,
            },
        };

        AWS.config.credentials = await new AWS.CognitoIdentityCredentials(idPool);

        Auth.currentAuthenticatedUser().then((cognitoUser: any) => {
            const data = JSON.stringify(paramItem);

            var settings = {
                async: true,
                crossDomain: true,
                url: BASE_API_URL + 'getpubattachment',
                method: 'POST',
                'Access-Control-Allow-Origin': '*',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                processData: false,
                data: data,
            };

            axios(settings).then(async function(response: any) {
                console.log(response);
                const s3Path = response.data.s3path;

                var s3 = new AWS.S3({
                    signatureVersion: 'v4',
                    apiVersion: '2006-03-01',
                    params: { Bucket: 'csqnc-jcsda-mvqvg-ugrao-emzck-sfgxg-kkhdi-dxahz-kudwf-hlloq' },
                });

                var fileName = 'getfromipfs/' + s3Path + '/' + paramItem.s3FileName;
                const url = await s3.getSignedUrl(
                    'getObject',
                    {
                        Key: fileName,
                        Expires: 60,
                    },
                    (err, url) => {
                        if (err) {
                            reject();
                        }
                        resolve(url);
                    }
                );
            });
        });
    });
}

export async function getBillingHistory() {
    return new Promise(async (resolve, reject) => {
        const token: any = await getJwtToken();

        AWS.config.update({
            region: 'us-east-1',
        });

        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': token,
                },
            }),
        });

        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                const db = new AWS.DynamoDB.DocumentClient({
                    credentials: AWS.config.credentials,
                    region: AWS.config.region,
                });
                let params = {
                    TableName: 'userBilling',
                    KeyConditionExpression: 'username = :username',
                    ExpressionAttributeValues: {
                        ':username': cognitoUser.attributes.sub,
                    },
                    ScanIndexForward: false,
                };

                db.query(params, function(err, data) {
                    if (err) {
                        console.log('Unable to read item: ' + JSON.stringify(err, undefined, 2));
                        reject(err);
                    } else {
                        resolve(data.Items);
                    }
                });
            })
            .catch(err => {
                console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
                reject(err);
            });
    });
}

export async function getUserCredits() {
    return new Promise<ICredit>(async (resolve, reject) => {
        const token: any = await getJwtToken();

        AWS.config.update({
            region: 'us-east-1',
        });

        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': token,
                },
            }),
        });

        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                const db = new AWS.DynamoDB.DocumentClient({
                    credentials: AWS.config.credentials,
                    region: AWS.config.region,
                });
                let params = {
                    TableName: 'userCredit',

                    KeyConditionExpression: 'username = :username',

                    ExpressionAttributeValues: {
                        ':username': cognitoUser.attributes.sub,
                    },

                    ScanIndexForward: false,
                };

                db.query(params, function(err, data) {
                    if (err) {
                        console.log('Unable to read item: ' + JSON.stringify(err, undefined, 2));
                        reject(err);
                    } else {
                        resolve(data!.Items![0] as ICredit);
                    }
                });
            })
            .catch(err => {
                console.log("UserLoginService: Couldn't get the session: " + err, err.stack);
                reject(err);
            });
    });
}

export async function updateProofParams(paramItem: Partial<IProof>) {
    const token: any = await getJwtToken();
    const data = JSON.stringify(paramItem);
    var settings = {
        async: true,
        crossDomain: true,
        url: BASE_API_URL + 'update/txndetails',
        method: 'POST',
        'Access-Control-Allow-Origin': '*',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        processData: false,
        data: data,
    };

    axios(settings).then(function(response) {
        if (response) {
            // reject(response);
        } else {
            // resolve(response);
        }
        console.log(response);
    });
}

export function attachmentSearch(s3FileSHA256Code: string, reToken: string) {
    return new Promise(async (resolve, reject) => {
        const token: any = await getJwtToken();

        AWS.config.update({
            region: 'us-east-1',
        });

        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': token,
                },
            }),
        });

        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                var data = JSON.stringify({
                    s3FileSHA256Code: s3FileSHA256Code,
                    reToken: reToken,
                    username: cognitoUser.attributes.sub,
                    timestamp: new Date().getTime(),
                });
                console.log(data);
                var settings = {
                    async: true,
                    crossDomain: true,
                    url: BASE_API_URL + 'get/byDocument',
                    method: 'POST',
                    headers: {
                        Authorization: token,
                        'Content-Type': 'application/json',
                    },
                    processData: false,
                    data: data,
                };

                axios(settings)
                    .then(function(response) {
                        resolve(response.data.txMessage);
                    })
                    .catch(err => {
                        reject(err.response);
                    });
            })
            .catch(err => {
                console.log("Request: Couldn't request proof or transfer: " + err, err.stack);
                reject(err);
            });
    });
}

export function recaptchaCheck(siteKey: string, token: string) {
    return new Promise(async (resolve, reject) => {
        const apiKey = '1wQM2fCFFW7fWqXs10yeaaNNOwIbxrHZ7TzRsxAD';
        var data = JSON.stringify({
            reToken: token,
            siteKey: siteKey,
        });

        var settings = {
            async: true,
            crossDomain: true,
            url: BASE_API_URL + 'recaptcha',
            method: 'POST',
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json',
            },
            processData: true,
            data: data,
        };

        axios(settings)
            .then(function(response) {
                resolve(response.data.txMessage);
            })
            .catch(err => {
                reject(err.response);
            });
    });
}

export function creditUpdate(orderID: string, token: string) {
    return new Promise(async (resolve, reject) => {
        const authToken: any = await getJwtToken();
        var dataInput = JSON.stringify({
            orderId: orderID,
            reToken: token,
        });

        var settings = {
            async: true,
            crossDomain: true,
            url: BASE_API_URL + 'update/credit',
            method: 'POST',
            headers: {
                Authorization: authToken,
                'Content-Type': 'application/json',
            },
            processData: false,
            data: dataInput,
        };
        axios(settings)
            .then(function(response) {
                resolve(response.data.message);
            })
            .catch(err => {
                reject(err.response);
            });
    });
}

export function userUpdate(firstName: string, lastName: string) {
    return new Promise(async (resolve, reject) => {
        const authToken: any = await getJwtToken();
        var dataInput = JSON.stringify({
            name: `${firstName} ${lastName}`,
        });

        var settings = {
            async: true,
            crossDomain: true,
            url: BASE_API_URL + 'user/updatename',
            method: 'POST',
            headers: {
                Authorization: authToken,
                'Content-Type': 'application/json',
            },
            processData: false,
            data: dataInput,
        };
        axios(settings)
            .then(function(response) {
                resolve(response.data);
            })
            .catch(err => {
                reject(err.response);
            });
    });
}

export function deleteAttachment(txMessage: string) {
    return new Promise<Response>(async (resolve, reject) => {
        const authToken: any = await getJwtToken();

        AWS.config.update({
            region: 'us-east-1',
        });

        AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': authToken,
                },
            }),
        });

        Auth.currentAuthenticatedUser().then((cognitoUser: any) => {
            var dataInput = JSON.stringify({
                username: cognitoUser.attributes.sub,
                timestamp: new Date().getTime(),
                txMessage: txMessage,
            });

            var settings = {
                async: true,
                crossDomain: true,
                url: BASE_API_URL + 'deleteattachment',
                method: 'POST',
                headers: {
                    Authorization: authToken,
                    'Content-Type': 'application/json',
                },
                processData: false,
                data: dataInput,
            };
            axios(settings)
                .then(function(response) {
                    resolve(response.data.message);
                })
                .catch(err => {
                    reject(err.response);
                });
        });
    });
}

export function uploadAttachmentNew(
    txName: string,
    file: File,
    desctription: string,
    recipientOwnerEmail: string,
    recipientOwnerName: string,
    additionalData :{title:string, value:string}[]
) {
    return new Promise(async (resolve, reject) => {
        const token: any = await getJwtToken();

        const configUpdate = await AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': token,
                },
            }),
        });

        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                var s3 = new AWS.S3({
                    apiVersion: '2006-03-01',
                });

                if (!(file instanceof File)) {
                    toastr.options = {
                        positionClass: 'toast-top-right',
                        hideDuration: 300,
                        timeOut: 6000,
                    };
                    toastr.clear();
                    setTimeout(() => toastr.error(`Please choose a file to upload first.`), 300);
                    return;
                }

                const fileName = file.name;
                s3.upload(
                    {
                        Key: fileName,
                        Body: file,
                        Bucket: 'csqnc-jcsda-mvqvg-ugrao-emzck-sfgxg-kkhdi-dxahz-kudwf-hlloq',
                    },
                    function(err: any, data: any) {
                        if (err) {
                            toastr.options = {
                                positionClass: 'toast-top-right',
                                hideDuration: 300,
                                timeOut: 6000,
                            };
                            toastr.clear();
                            setTimeout(
                                () => toastr.error(`There was an error uploading your file: '${err.message}`),
                                300
                            );
                            return;
                        }
                    }
                );

                var fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);
                fileReader.onload = function() {
                    var sha256Code = sha256(fileReader.result as string).toUpperCase();
                    console.log(sha256Code);
                    var dataJson = JSON.stringify({
                        timestamp: Date.now(),
                        txName: txName,
                        txStatus: 'publishAttachment',
                        s3FileName: fileName,
                        s3FileDescription: desctription,
                        username: cognitoUser.attributes.sub,
                        txRecipientName: recipientOwnerName,
                        txRecipientEmail: recipientOwnerEmail,
                        txAddData:additionalData
                        // s3FileSHA256Code: sha256Code,
                        // network: NETWORK,
                    });

                    var settings = {
                        async: true,
                        crossDomain: true,
                        url: BASE_API_URL + 'pubattachment',
                        method: 'POST',
                        headers: {
                            Authorization: token,
                            'Content-Type': 'application/json',
                        },
                        processData: false,
                        data: dataJson,
                    };

                    axios(settings)
                        .then(function(response) {
                            resolve(response.data.message);
                        })
                        .catch(err => {
                            if (err.response.status == 400) {
                                reject(err.response);
                            }
                        });
                };
            })
            .catch(err => {
                console.log("Couldn't get current user: " + err, err.stack);
                reject(err);
            });
    });
}

export function getAttachmentsNew(): Promise<any> {
    return new Promise(async (resolve, reject) => {
        const authToken: any = await getJwtToken();
        var settings = {
            async: true,
            crossDomain: true,
            url: BASE_API_URL + 'get/devicesbyuser',
            method: 'POST',
            headers: {
                Authorization: authToken,
                'Content-Type': 'application/json',
            },
            processData: false,
        };
        axios(settings)
            .then(function(response) {
                resolve(response.data);
            })
            .catch(err => {
                reject(err.response);
            });
    });
}

export function attachmentsBulkUpload(files: File[]) {
    return new Promise(async (resolve, reject) => {
        const token: any = await getJwtToken();

        const configUpdate = await AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': token,
                },
            }),
        });

        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                var s3 = new AWS.S3({
                    apiVersion: '2006-03-01',
                });
                const username = cognitoUser.attributes.sub;
                for (let i in files) {
                    const fileName = files[i].name;
                    s3.upload(
                        {
                            Key: username + '/' + fileName,
                            Body: files[i],
                            Bucket: 'csqnc-jcsda-mvqvg-ugrao-emzck-sfgxg-kkhdi-dxahz-kudwf-hlloq',
                        },
                        function(err: any, data: any) {
                            if (err) {
                                toastr.options = {
                                    positionClass: 'toast-top-right',
                                    hideDuration: 300,
                                    timeOut: 6000,
                                };
                                toastr.clear();
                                setTimeout(
                                    () => toastr.error(`There was an error uploading your file: '${err.message}`),
                                    300
                                );
                                return;
                            }
                            else{
                                resolve(data);
                            }
                        }
                    );
                }
            })
            .catch(err => {
                console.log("Couldn't get current user: " + err, err.stack);
                reject(err);
            });
    });
}

export function getAttachmentsByUser(): Promise<any> {
    return new Promise(async (resolve, reject) => {
        const authToken: any = await getJwtToken();

        const configUpdate = await AWS.config.update({
            credentials: new AWS.CognitoIdentityCredentials({
                IdentityPoolId: IDENTYTY_POOL_ID,
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_HeMQEOrYm': authToken,
                },
            }),
        });

        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                var s3 = new AWS.S3({
                    signatureVersion: 'v4',
                    apiVersion: '2006-03-01',
                });
                const username = cognitoUser.attributes.sub;
                var params = {
                    Bucket: 'csqnc-jcsda-mvqvg-ugrao-emzck-sfgxg-kkhdi-dxahz-kudwf-hlloq',
                    Prefix: username + '/',

                };
                s3.listObjectsV2(params, function(err, data) {
                    if (err) {
                        reject(err);
                    } else {
                        console.log(data);
                        resolve(data);
                    }
                });
            })
            .catch(err => {
                console.log("Couldn't get current user: " + err, err.stack);
                reject(err);
            });
    });
}

export function deleteAttachmentFromBulkUpload(key:string){
    return new Promise(async (resolve, reject) => {
        const authToken: any = await getJwtToken();

        Auth.currentAuthenticatedUser()
            .then((cognitoUser: any) => {
                var s3 = new AWS.S3({
                    signatureVersion: 'v4',
                    apiVersion: '2006-03-01',
                });
                const username = cognitoUser.attributes.sub;
                var params = {
                    Bucket: 'csqnc-jcsda-mvqvg-ugrao-emzck-sfgxg-kkhdi-dxahz-kudwf-hlloq',
                    Key: key,
                };
                s3.deleteObject(params, function(err, data) {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(data);
                    }
                });
            })
            .catch(err => {
                console.log("Couldn't get current user: " + err, err.stack);
                reject(err);
            });
    });
}
