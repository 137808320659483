import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import React, { useState, useContext, useMemo, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import {
    uploadAttachments,
    getUserCredits,
    downloadAttachment,
    deleteAttachment,
    uploadAttachmentNew,
    getAttachmentsNew,
    attachmentsBulkUpload,
    getAttachmentsByUser,
    deleteAttachmentFromBulkUpload,
} from '../../api';
import validator from 'email-validator';
import appLoginDispatcher from '../../contexts/app-dispatch';
import UserProofsContext from '../../contexts/user-proofs';
import { labelStyle } from '../inline-styles';
import UserCreditContext from '../../contexts/user-credit';
import NotEnoughFundsPopup from '../NotEnoughFundsPopup';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ModalBody from 'reactstrap/lib/ModalBody';
import Modal from 'reactstrap/lib/Modal';
import { NETWORK, PROOF_ACTION_PRICE } from '../../constants';
import { BlokchainDetails } from '../BlockchainDetails';
import { IAttachment } from '../../types';
import UserContext from '../../contexts/user';
import ReactTooltip from 'react-tooltip';

export interface IAttachmentProps {
    modalToggle?: () => void;
    // proofId: string;
    uploadFiles?: any;
}

TimeAgo.locale(en);

function useFormField(initialValue: string = '') {
    const [value, setValue] = useState(initialValue);

    function onChange(event: any) {
        setValue(event.target.value);
    }

    return {
        value,
        onChange,
    };
}

export function AttachmentsBulkUpload(props: IAttachmentProps) {
    const user = useContext(UserContext);
    const userData = useContext(UserProofsContext);
    const credit = useContext(UserCreditContext);
    const attachmentDescription = useFormField();
    const attachmentGivenName = useFormField();
    const [attachmentName, setAttachmentName] = useState();
    const [uploadFiles, setUploadFiles] = useState();
    const dispatch = useContext(appLoginDispatcher)!;
    const [showNotEnoughFundsPopup, setShowNotEnoughFundsPopup] = useState(false);
    const [updateAttachments, setUpdateAttachments] = useState(false);
    const [showLoaderMask, setShowLoaderMask] = useState(false);
    const [showAttachmentDetails, setShowAttachmentDetails] = useState(false);
    const [showDeleteAttachmentPopup, setShowDeleteAttachmentPopup] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState();
    const [uploadFilesCount, setUploadFilesCount] = useState();
    const [showUploadAttachmentDetail, setShowUploadAttachmentDetail] = useState(false);
    const [uploadingAttachmentName, setUploadingAttachmentName] = useState();
    const [attachmentUploadButtonDisabled, setAttachmentUploadButtonDisabled] = useState(false);
    const [attachmentError, setAttachmentError] = useState();
    const [attachmentUploadedPopup, setAttachmentUploadedPopup] = useState(false);
    const [onlyAttachments, setOnlyAttachments] = useState();
    const [attachmentsData, setAttachmentsData] = useState<IAttachment[]>([]);
    const [attachmentsInitialData, setAttachmentsInitialData] = useState<IAttachment[]>([]);
    const recipientOwnerEmail = useFormField(user!.email || '');
    const recipientOwnerName = useFormField(user!.fullName || '');
    const filterInput = useFormFieldFilter();
    const ONE_HOUR = 60 * 60 * 1000; /* ms */

    async function onAttachmentBulkUploadClick(event: any) {
        event.preventDefault();

        // if (!validateForm()) {
        //     return;
        // }
        setAttachmentUploadButtonDisabled(true);

        setShowLoaderMask(true);
        try {
            await attachmentsBulkUpload(uploadFiles);
            setAttachmentUploadedPopup(true);
            setAttachmentUploadButtonDisabled(false);
            setShowLoaderMask(false);
            showAttachmentUploadModalToggle();
            setUpdateAttachments(prevState => !prevState);
            setAttachmentsData(attachmentsData);
        } catch (e) {
            setShowLoaderMask(false);
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Something went wrong', error: true },
            });
        }

        const userCredit = await getUserCredits();
        dispatch({ type: 'SET_USER_CREDITS', payload: userCredit });
    }

    function validateForm() {
        let isFormValid = true;
        if (
            attachmentDescription.value == '' ||
            attachmentGivenName.value == '' ||
            recipientOwnerEmail.value == '' ||
            recipientOwnerName.value == ''
        ) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'All input fields are required', error: true },
            });
            isFormValid = false;
        }
        if (!validator.validate(recipientOwnerEmail.value)) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Invalid Email', error: true },
            });
            isFormValid = false;
        }

        return isFormValid;
    }

    function useFormFieldFilter(initialValue: string = '') {
        const [value, setValue] = useState(initialValue);

        function onChange(event: any) {
            setValue(event.target.value);

            let filteredAttachments = attachmentsInitialData.filter((attachment: IAttachment) => {
                if (attachment && attachment.Key) {
                    return attachment.Key.includes(event.target.value);
                }
            });
            setAttachmentsData(filteredAttachments);
        }

        return {
            value,
            onChange,
        };
    }

    function onDrop(acceptedFiles: any) {
        if (credit.availableCredit! < acceptedFiles.length) {
            setShowNotEnoughFundsPopup(true);
            return;
        }

        setShowUploadAttachmentDetail(true);

        for (let i in acceptedFiles) {
            let reader = new FileReader();
            reader.readAsDataURL(acceptedFiles[i]);
            let type = acceptedFiles[i].name.substring(
                acceptedFiles[i].name.lastIndexOf('.') + 1,
                acceptedFiles[i].name.length
            );
            type = type.toLocaleLowerCase();
            setAttachmentError(null);

            let allowedExtensions = ['pdf', 'png', 'jpg', 'gif', 'jpeg', 'xls', 'xlsx'];

            if (allowedExtensions.indexOf(type.toLowerCase()) === -1) {
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: {
                        message: 'Only PDF, JPEG or PNG files are allowed. Please try again with valid file. ',
                        error: true,
                    },
                });
                setShowUploadAttachmentDetail(false);
                return;
            }

            if (acceptedFiles[i].size > 10023317) {
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: {
                        message: 'File size cannot exceed 10Mb. Please resize the file and try again. ',
                        error: true,
                    },
                });
                setShowUploadAttachmentDetail(false);
                return;
            }

            setAttachmentUploadButtonDisabled(false);
            setTimeout(() => {
                setAttachmentName(reader.result);
                setUploadingAttachmentName(acceptedFiles[i].name);
            }, 1000);
        }
        setUploadFiles(acceptedFiles);
        setUploadFilesCount(acceptedFiles.length);
        // setTimeout(function() {
        //     onAttachmentBulkUploadClick();
        // }, 900);
    }

    useEffect(() => {
        (async () => {
            try {
                setShowLoaderMask(true);
                let dataResult = await getAttachmentsByUser();
                let prefix = dataResult.Prefix;
                let attachmentsArr = dataResult.Contents;
                //let attachmentsArr = dataResult.Contents.sort(o => o.Size);
                for (let i in attachmentsArr) {
                    attachmentsArr[i].shortKey = attachmentsArr[i].Key.replace(prefix, '');
                }
                setShowLoaderMask(false);

                setAttachmentsData(attachmentsArr);
                setAttachmentsInitialData(attachmentsArr);
                setOnlyAttachments(dataResult);
            } catch (e) {
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: { message: 'Something went wrong.', error: true },
                });
            }
        })();
    }, [updateAttachments]);

    function attachmentUploadedPopupModalToggle() {
        setAttachmentUploadedPopup(prevState => !prevState);
    }

    function showAttachmentDetailModalToggle() {
        if (credit.availableCredit! < PROOF_ACTION_PRICE) {
            setShowNotEnoughFundsPopup(true);
            return;
        }
        setShowAttachmentDetails(false);
    }

    function showAttachmentUploadModalToggle() {
        setShowUploadAttachmentDetail(false);
    }

    function attachmentDeleteModalToggle() {
        setShowDeleteAttachmentPopup(false);
    }

    function onAttachmentDeleteButtonClick() {
        setShowDeleteAttachmentPopup(true);
    }

    async function attachmentDeleteConfirmation() {
        attachmentDeleteModalToggle();
        showAttachmentDetailModalToggle();
        try {
            var resultMessage = await deleteAttachmentFromBulkUpload(selectedAttachment.Key);
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: resultMessage, error: false },
            });
        } catch (e) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Something went wrong.', error: true },
            });
        }
    }

    function viewCertificate(attachment: IAttachment) {
        const a = document.createElement('a');
        a.setAttribute('href', `https://verify.proofplum.com/${attachment.txMessage}.pdf`);
        a.setAttribute('target', '_blank');
        a.click();
    }

    async function downloadAttachementHandler(attachment: any) {
        setShowLoaderMask(true);
        try {
            const url = await downloadAttachment({
                txMessage: attachment.txMessage,
                timestamp: attachment.timestamp,
                username: attachment.username,
                s3FileName: attachment.s3FileName,
            });
            console.log(url);
            setShowLoaderMask(false);
            const a = document.createElement('a');
            a.setAttribute('href', url as string);
            a.setAttribute('target', '_blank');
            a.click();
        } catch (e) {
            setShowLoaderMask(false);
        }
    }

    let attachmentType = '';
    if (uploadingAttachmentName) {
        attachmentType = uploadingAttachmentName.substring(
            uploadingAttachmentName.lastIndexOf('.') + 1,
            uploadingAttachmentName.length
        );
    }
    let img = (
        <div className="text-center fa-5x px-2 pt-1">
            <i className="fas fa-circle-notch fa-spin primary blue-text" />
        </div>
    );

    if (attachmentName) {
        switch (attachmentType.toLocaleLowerCase()) {
            case 'pdf':
                img = (
                    <div className="text-center px-2 pt-1">
                        <i className="far fa-file-pdf fa-7x" />
                    </div>
                );
                break;

            case 'png':
            case 'jpg':
            case 'bmp':
            case 'gif':
            case 'jpeg':
                img = (
                    <img
                        src={attachmentName}
                        alt="Image preview..."
                        style={{
                            maxWidth: '100%',
                            maxHeight: '122px',
                        }}
                    />
                );
                break;
            default:
                img = (
                    <div className="text-center px-2 pt-1">
                        <i className="far fa-file fa-7x" />
                    </div>
                );
        }
    }

    let href;
    if (selectedAttachment) {
        href = `https://verify.proofplum.com/${selectedAttachment.txMessage}`;
    }

    function convertTime(timestamp: any) {
        const timeAgo = new TimeAgo('en-US');
        const time = timeAgo.format(timestamp);
        const fullDate = new Date(timestamp).toDateString();
        return `${time} (${fullDate})`;
    }

    return (
        <>
            <div className="container-fluid proof landing-page-bottom">
                <div className="container pt-5">
                    <div className="row  border-bottom">
                        <Col lg={6} md={6} sm={6} xs={6}>
                        <p data-tip="Create blockchain certificate in bulk. Read instructions before upload">
                        <h5>Bulk Upload  <i className="fas fa-info-circle"></i></h5></p>
                        <ReactTooltip />

                            <span className="small-text blue-text" >

                               Please read the instructions  <a href="https://help.proofplum.com/hc/en-us/articles/360036410271-Instructions-for-bulk-upload-certificates">here.</a>
                           </span>
                        </Col>

                        <Col className="text-right align-self-center row" lg={6} md={6} sm={12} xs={12}>
                            <Input
                                className="border-0 col-4 ml-auto"
                                type="text"
                                id="filter-text-box"
                                placeholder="Search"
                                value={filterInput.value}
                                onChange={filterInput.onChange}
                            />

                            <Button
                                onClick={() => setUpdateAttachments(prevState => !prevState)}
                                className="btn btn-primary ml-3"
                            >
                                <i className="fas fa-sync" />
                            </Button>
                        </Col>
                    </div>

                    <div style={labelStyle} className="pt-4" />

                    <div className="container" />
                    <div className="mt-1 container">
                        <div
                            className="row"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                            }}
                        >
                            {attachmentsData.map((attachment: any, index: number) => {
                                if (attachment && attachment.shortKey) {
                                    const a = attachment.shortKey.substring(
                                        attachment.shortKey.lastIndexOf('.') + 1,
                                        attachment.shortKey.length
                                    );

                                    let icon;
                                    if ((a as String).toLowerCase() === 'pdf') {
                                        icon = <i className="far fa-file-pdf fa-7x grey-text" />;
                                    } else {
                                        icon = <i className="fa fa-file-image fa-7x grey-text" />;
                                    }
                                    let pending = <div />;
                                    if (attachment.txStatus !== 'Success') {
                                        pending = <div className="grid-request-info orange mt-1">PENDING</div>;
                                    }

                                    if (attachment.txStatus === 'Error') {
                                        pending = <div className="grid-request-info red mt-1">ERROR</div>;
                                    }

                                    if (
                                        attachment.txStatus === 'Success' &&
                                        Date.now() - attachment.timestamp < ONE_HOUR
                                    ) {
                                        pending = <div className="grid-request-info green mt-1">SUCCESS</div>;
                                    }

                                    return (
                                        <div
                                            className="mt-4"
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexDirection: 'column',
                                                cursor: 'pointer',
                                            }}
                                            onClick={event => {
                                                if (attachment.txStatus === 'processing') {
                                                    event.preventDefault();
                                                    dispatch({
                                                        type: 'SHOW_MESSAGE',
                                                        payload: {
                                                            message: 'Wait until the processing is complete',
                                                            error: true,
                                                        },
                                                    });
                                                } else {
                                                    setSelectedAttachment(attachment);
                                                    onAttachmentDeleteButtonClick();
                                                }
                                            }}
                                        >
                                            {icon}
                                            <span className="truncate-attachment-name text-center" style={labelStyle}>
                                                {attachment.shortKey}
                                            </span>
                                            {pending}
                                        </div>
                                    );
                                }
                            })}
                            <Dropzone onDrop={onDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <section>
                                        <div
                                            className="drop-zone grey-text"
                                            {...getRootProps({
                                                style: {
                                                    height: 111,
                                                    margin: '0 5px',
                                                    border: '2px dashed #2F80ED',
                                                    borderRadius: '5px',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    display: 'flex',
                                                    width: '162px',
                                                    cursor: 'pointer',
                                                    marginTop: '30px',
                                                },
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <div>
                                                <span>Add Certificate</span>
                                                <span
                                                    className="badge-primary rounded-circle ml-2"
                                                    style={{
                                                        width: '1.125rem',
                                                        height: '1.125rem',
                                                        padding: '0.2rem',
                                                    }}
                                                >
                                                    $1
                                                </span>
                                                <div>
                                                    <small className="grey-text">
                                                        <i className="fas fa-upload" /> Drop or click to upload
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </div>

                        <div className="col-4" />
                    </div>
                </div>
            </div>

            {showAttachmentDetails ? (
                <Modal toggle={showAttachmentDetailModalToggle} isOpen={true} size="lg">
                    <i
                        onClick={showAttachmentDetailModalToggle}
                        className="float-right fas fa-times popup-close-icon"
                    />

                    <ModalBody>
                        <span style={labelStyle}> {selectedAttachment.shortKey}</span>
                        <div
                            style={{
                                borderTop: '1px solid rgba(0,0,0,0.2)',
                                marginTop: '1rem',
                                marginBottom: '1rem',
                            }}
                        >
                            <Container className="mt-4 py-4 px-4">
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <span style={labelStyle}>DOCUMENT ID</span>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8">
                                        {selectedAttachment.txMessage}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <span style={labelStyle}>DESCRIPTION</span>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8">
                                        {selectedAttachment.s3FileDescription}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <span style={labelStyle}>RECIPIENT/OWNER NAME</span>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8">
                                        {selectedAttachment.txRecipientName}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <span style={labelStyle}>RECIPIENT/OWNER EMAIL</span>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8">
                                        {selectedAttachment.txRecipientEmail}
                                    </Col>
                                </Row>
                                <Row className="pt-2" />
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="4">
                                        <span style={labelStyle}>Verification link</span>
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="8">
                                        <a href={href} target="blank">
                                            {href}
                                        </a>
                                    </Col>
                                </Row>
                                {/* <BlokchainDetails proof={selectedAttachment} /> */}
                                <Row className="mb-2">
                                    <Col xs="12" sm="12" md="12" lg="7">
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => downloadAttachementHandler(selectedAttachment)}
                                            className="mr-4 small-text gray"
                                        >
                                            View Original
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col xs="12" sm="12" md="12" lg="7">
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => onAttachmentDeleteButtonClick()}
                                            className="mr-4 small-text gray"
                                        >
                                            Delete Certificate
                                        </span>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="text-center">
                                        <Button color="primary" onClick={() => viewCertificate(selectedAttachment)}>
                                            View Certificate
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </ModalBody>
                </Modal>
            ) : (
                ''
            )}

            {showUploadAttachmentDetail ? (
                <Modal toggle={showAttachmentUploadModalToggle} isOpen={true} size="sm">
                    <i
                        onClick={showAttachmentUploadModalToggle}
                        className="float-right fas fa-times popup-close-icon"
                    />

                    <ModalBody>

                        <div
                            style={{
                                borderTop: '1px solid rgba(0,0,0,0.2)',
                                marginTop: '1rem',
                                marginBottom: '1rem',
                            }}
                        >
                            <Container className="mt-4 py-4 px-4">
                                <Row className="pt-3">
                                    <Col className="text-center">
                                        <span
                                            style={{
                                                color: '#2F80ED',
                                            }}
                                        >
                                            ${uploadFilesCount} will be debited from your account{' '}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="pt-3">
                                    <Col className="text-center">
                                        {attachmentUploadButtonDisabled ? (
                                            <Button color="primary" disabled onClick={onAttachmentBulkUploadClick}>
                                                Upload
                                            </Button>
                                        ) : (
                                            <Button color="primary" onClick={onAttachmentBulkUploadClick}>
                                                Upload
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </ModalBody>
                </Modal>
            ) : (
                ''
            )}

            {showNotEnoughFundsPopup ? <NotEnoughFundsPopup /> : ''}

            {showLoaderMask ? (
                <div className="attachment-mask">
                    <div className="text-center fa-5x px-2 pt-1">
                        <i className="fas fa-circle-notch fa-spin primary blue-text" />
                    </div>
                </div>
            ) : (
                ''
            )}

            {attachmentUploadedPopup ? (
                <Modal toggle={attachmentUploadedPopupModalToggle} isOpen={true} size="xs">
                    <ModalBody>
                        <div className="container justify-content-center">
                            <div className="row justify-content-center">
                                <i
                                    className="fas fa-check text-success"
                                    style={{
                                        fontSize: '60px',
                                    }}
                                />
                            </div>
                            <div
                                className="row justify-content-center"
                                style={{
                                    fontSize: '24px',
                                }}
                            >
                                Upload Successful. You will receive confirmation email within next 30 mins.
                            </div>
                            <div className="row mt-2 justify-content-center">
                                <Button color="primary" onClick={attachmentUploadedPopupModalToggle}>
                                    Got It!
                                </Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            ) : (
                ''
            )}

            {showDeleteAttachmentPopup ? (
                <Modal toggle={attachmentDeleteModalToggle} isOpen={true} size="xs">
                    <ModalBody>
                        <div className="container justify-content-center">
                            <div className="row justify-content-center mb-2">
                                <span>This will permanently delete the original </span>
                                <span> document and share document</span>
                            </div>
                            <div className="text-center">
                                <span className="font-weight-bold">Are you sure?</span>
                            </div>

                            <div className="row mt-2 justify-content-center">
                                <Button
                                    onClick={attachmentDeleteConfirmation}
                                    className="btn grid-btn-red mr-3 border-0"
                                >
                                    Delete
                                </Button>
                                <Button className="btn gray-background-btn" onClick={attachmentDeleteModalToggle}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            ) : (
                ''
            )}
        </>
    );
}
