import React, { useReducer, useEffect, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import toastr from 'toastr';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import { getMe, getUserProofs, logOut, putActionProof, getUserCredits } from './api';

import { Home } from './components/Home';
import AppLoginDispatcherContext from './contexts/app-dispatch';
import UserContext from './contexts/user';

import './App.css';
import MyProofs from './components/MyProofs';
import MyText from './components/MyText';
import ProofIdSearch from './components/SearchResult';
import { AuthContainerSelfControlled } from './components/Auth';
import { IUser, IProof, ICredit } from './types';
import UserProofsContext from './contexts/user-proofs';
import CanRegisterProofContext from './contexts/can-register-proof';
import MyPendingRequests from './components/Requests';
import AccountSettings from './components/AccountSettings';
import UserCreditContext from './contexts/user-credit';
import RequestCountContext from './contexts/request-count';
import WithHeaderAndFooter from './components/WithHeaderAndFooter';
import SearchPage from './components/SearchPage';
import ReactGA from 'react-ga';
import { BlockchainVerify } from './components/BlockchainVerify';
import { AttachmentsBulk } from './components/AttachmentsBulk/index';
import signupForm from './components/Auth/signup-form';
import SignUpFormNew from './components/Auth/SignUpFormNew';

function getToken() {
    return localStorage.getItem('accessToken');
}

function getUser() {
    return localStorage.getItem('user');
}

function initializeReactGA() {
    ReactGA.initialize('UA-140735327-1');
    ReactGA.pageview('/homepage');
    console.log('ga-initialized');
}

function PrivateRoute({
    Component,
    user,
    componentProps = {},
    ...rest
}: {
    Component: any;
    user: any;
    path: string;
    exact: any;
    componentProps?: any;
}) {
    return (
        <Route
            {...rest}
            render={props =>
                !!user ? (
                    <Component {...props} {...componentProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

interface State {
    user: IUser | null;
    isAuthenticating: boolean;
    loginErrorMessage: string;
    userProofs: IProof[];
    showLoading: boolean | string;
    showMessage: { message: string; error: boolean };
    loadUserProofs: number;
    canRegisterProof: boolean;
    credit: ICredit;
    requestCount: number;
}

const initialState: State = {
    isAuthenticating: true,
    user: null,
    loginErrorMessage: '',
    userProofs: [],
    showLoading: 'user_proofs',
    showMessage: { message: '', error: false },
    loadUserProofs: 0,
    canRegisterProof: true,
    credit: { availableCreditDecimal: 0 },
    requestCount: 0,
};

function appReducer(state: State, action: any): State {
    switch (action.type) {
        case 'LOGIN': {
            return {
                ...state,
                user: action.payload,
                isAuthenticating: false,
                loginErrorMessage: '',
            };
        }
        case 'SET_USER_PROOFS': {
            return {
                ...state,
                userProofs: action.payload,
            };
        }
        case 'SET_USER_CREDITS': {
            return {
                ...state,
                credit: action.payload,
            };
        }

        case 'SET_REQUEST_COUNT': {
            return {
                ...state,
                requestCount: action.payload,
            };
        }
        case 'SHOW_LOADER': {
            return {
                ...state,
                showLoading: typeof action.payload === 'string' ? action.payload : true,
            };
        }
        case 'HIDE_LOADER': {
            return {
                ...state,
                showLoading: false,
            };
        }
        case 'SHOW_MESSAGE': {
            return {
                ...state,
                showLoading: false,
                showMessage: action.payload,
            };
        }
        case 'LOGIN_ERROR': {
            return {
                ...state,
                user: null,
                isAuthenticating: false,
                showLoading: false,
                loginErrorMessage: action.payload,
            };
        }
        case 'LOGOUT': {
            return {
                ...initialState,
                isAuthenticating: false,
                showLoading: false,
            };
        }
        case 'LOAD_USER_PROOFS': {
            return {
                ...state,
                loadUserProofs: ++state.loadUserProofs,
            };
        }
        case 'CAN_REGISTER_PROOF': {
            return {
                ...state,
                canRegisterProof: action.payload,
            };
        }
        case 'UPDATE_USER': {
            return {
                ...state,
                user: action.payload,
            };
        }
        default:
            throw new Error(`invalid Action Type ${action.type}`);
    }
}

function App() {
    const [state, dispatch] = useReducer(appReducer, initialState);
    const {
        user,
        credit,
        isAuthenticating,
        requestCount,
        userProofs,
        showLoading,
        showMessage,
        loadUserProofs,
        canRegisterProof,
    } = state;
    function _logout() {
        logOut();
        localStorage.clear();
        dispatch({ type: 'LOGOUT' });
    }

    useEffect(() => {
        if (showMessage && showMessage.message) {
            let fn = showMessage.error ? 'error' : 'success';
            toastr[fn](showMessage.message, '', {
                onHidden: () => {
                    dispatch({ type: 'SHOW_MESSAGE', payload: { message: '', error: false } });
                },
            });
        }
    }, [showMessage]);

    useEffect(() => {
        (async () => {
            const token = getToken();
            const email = getUser();
            if (email) {
                try {
                    const userCheck = await getMe(email);
                    dispatch({ type: 'LOGIN', payload: userCheck.user });
                    const userCredit = await getUserCredits();
                    dispatch({ type: 'SET_USER_CREDITS', payload: userCredit });
                } catch (e) {
                    dispatch({ type: 'LOGIN_ERROR', payload: 'unable to fetch user' });
                }
            } else {
                _logout();
            }
        })();
    }, []);

    async function _getUserProofs(username: string, blockApp: boolean) {
        dispatch({ type: 'SHOW_LOADER', payload: blockApp ? 'user_proofs' : true });
        try {
            const userProofs = await getUserProofs(username);
            const requestProofs = userProofs.filter(proof => {
                return (
                    (proof.txType === 'requestProof' || proof.txType === 'requestTransfer') &&
                    (proof.txStatus === 'proofRequested' || proof.txStatus === 'transferRequested')
                );
            });

            dispatch({ type: 'SET_USER_PROOFS', payload: userProofs });
            dispatch({ type: 'SET_REQUEST_COUNT', payload: requestProofs.length });

            dispatch({ type: 'HIDE_LOADER' });
            const proofData = userProofs.filter(proof => {
                return (
                    proof.txStatus === 'created' ||
                    proof.txStatus === 'processing' ||
                    proof.txStatus === 'retry' ||
                    proof.txStatus === 'Sucess' ||
                    proof.txStatus === 'error'
                );
            });
            if (proofData.length === 0) {
                dispatch({ type: 'CAN_REGISTER_PROOF', payload: true });
            } else {
                dispatch({ type: 'CAN_REGISTER_PROOF', payload: false });
            }
            console.log(userProofs);
        } catch (e) {
            _logout();
            return;
        }
    }

    useEffect(() => {
        if (!user) {
            return;
        }
        (async () => {
            try {
                _getUserProofs(user.username!, true);
            } catch (e) {
                // _logout();
            }
        })();
    }, [user]);

    useEffect(() => {
        if (loadUserProofs === 0 || !user) {
            return;
        }
        (async () => {
            try {
                _getUserProofs(user.username!, false);
            } catch (e) {
                // _logout();
            }
        })();
    }, [loadUserProofs]);

    if (isAuthenticating || showLoading === 'user_proofs') {
        return <div className="preloader" />;
    }

    const loaderDiv = <div className="preloader" />;

    return (
        <AppLoginDispatcherContext.Provider value={dispatch}>
            {showLoading ? loaderDiv : ''}
            <UserContext.Provider value={user}>
                <UserCreditContext.Provider value={credit}>
                    <UserProofsContext.Provider value={userProofs}>
                        <RequestCountContext.Provider value={requestCount}>
                            <CanRegisterProofContext.Provider value={canRegisterProof}>
                                <Switch>
                                    <Route
                                        path="/login"
                                        render={(props: any) => {
                                            if (user) {
                                                return (
                                                    <Redirect
                                                        to={{
                                                            pathname: props.location.state
                                                                ? props.location.state.from.pathname
                                                                : '/',
                                                        }}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <WithHeaderAndFooter>
                                                        <div className="d-flex justify-content-center align-items-center container align-middle h-100">
                                                            <AuthContainerSelfControlled defaultState="signin" />
                                                        </div>
                                                    </WithHeaderAndFooter>
                                                );
                                            }
                                        }}
                                    />
                                    <Route exact path="/" component={Home} />
                                    <Route exact path="/search/:id" component={ProofIdSearch} />
                                    <Route exact path="/search" component={SearchPage} />

                                    <Route exact path="/verify/:id" component={BlockchainVerify} />
                                    <Route exact path="/sign-up" component={SignUpFormNew}/>

                                    <PrivateRoute
                                        exact
                                        path="/certificates-bulk"
                                        Component={AttachmentsBulk}
                                        user={user}
                                    />

                                    <PrivateRoute exact path="/my-proofs" Component={MyProofs} user={user} />
                                      <PrivateRoute exact path="/my-text" Component={MyText} user={user} />
                                    <PrivateRoute exact user={user} path="/proofs/:id" Component={ProofIdSearch} />
                                    <PrivateRoute exact user={user} path="/requests" Component={MyPendingRequests} />
                                    <PrivateRoute exact user={user} path="/settings" Component={AccountSettings} />
                                    <PrivateRoute
                                        exact
                                        user={user}
                                        path="/account"
                                        Component={AccountSettings}
                                        componentProps={{
                                            activeTab: 1,
                                        }}
                                    />
                                    <PrivateRoute
                                        exact
                                        user={user}
                                        path="/billing"
                                        Component={AccountSettings}
                                        componentProps={{
                                            activeTab: 2,
                                        }}
                                    />
                                </Switch>
                            </CanRegisterProofContext.Provider>
                        </RequestCountContext.Provider>
                    </UserProofsContext.Provider>
                </UserCreditContext.Provider>
            </UserContext.Provider>
        </AppLoginDispatcherContext.Provider>
    );
}

export default App;
