import React, { useContext, useState } from 'react';
import {
    Collapse,
    NavbarToggler,
    Nav,
    NavbarBrand,
    Navbar,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    Badge,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';

import 'toastr/build/toastr.min.css';
import { logOut, getSearchresults, changePassword } from '../../api';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import AppLogoutDispatcherContext from '../../contexts/app-dispatch';
import UserContext from '../../contexts/user';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';

import logo from '../../images/proofbox-logo-cropped.svg';
import userIcon from '../../images/user-placeholder.svg';
import appLoginDispatcher from '../../contexts/app-dispatch';
import UserCreditContext from '../../contexts/user-credit';
import RequestCountContext from '../../contexts/request-count';
import ReactGA from 'react-ga';

function useFormField(initialValue: string = '') {
    const [value, setValue] = useState(initialValue);

    function onChange(event: any) {
        setValue(event.target.value);
    }

    return {
        value,
        onChange,
    };
}

interface IHeaderProps extends RouteComponentProps {}

function Header(headerProps: IHeaderProps) {
    const logoutDispatcher = useContext(AppLogoutDispatcherContext)!;
    const user = useContext(UserContext);
    const credit = useContext(UserCreditContext);
    const requestCount = useContext(RequestCountContext);
    const search = useFormField();
    const dispatch = useContext(appLoginDispatcher)!;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    function toggle() {
        setIsOpen(!isOpen);
    }

    ReactGA.initialize('UA-140735327-1');

    function onLoginClick(this: any) {
        headerProps.history.push('/');
    }

    function onSignOutClick() {
        logOut();
        headerProps.history.push('/');
        logoutDispatcher({ type: 'LOGOUT' });
    }

    async function onSubmit(event: any) {
        event.preventDefault();
        try {
            const result = await getSearchresults(search.value);
            if (!!result.Count) {
                headerProps.history.push(`/search/${encodeURIComponent(search.value)}`);
            } else {
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: { message: `device ID - ${search.value} is not found`, error: true },
                });
                return;
            }
        } catch (e) {}
    }

    let userLogin;
    let navButtons;
    let mobileNavButtons;
    let mobileUserLogin;
    if (user) {
        mobileUserLogin = (
            <>
                <NavItem className="col-12">
                    <hr />
                </NavItem>
                <NavItem className="col-12 py-3">{user.email}</NavItem>
                <NavItem className="col-12 py-3">
                    <NavLink activeClassName="active" to="/account">
                        Account
                    </NavLink>
                </NavItem>
                <NavItem className="col-12 py-3">
                    <NavLink activeClassName="active" to="/billing">
                        Billing
                    </NavLink>
                </NavItem>
                <NavItem className="col-12 py-3">
                    <Nav value={user.email} onClick={onSignOutClick}>
                        Sign Out
                    </Nav>
                </NavItem>
            </>
        );
        userLogin = (
            <div className="align-self-center pl-3 vertical-line ml-4">
                <div className="row align-items-center">
                    <NavLink activeClassName="active" to="/billing">
                        <div className="px-3">
                            <span className="small-gray small-text gray">
                                ${credit ? credit.availableCreditDecimal!.toFixed(2) : '0.00'}
                            </span>
                        </div>
                    </NavLink>

                    <div className="user-ellypse d-flex rounded-circle">
                        <div className="row justify-content-center align-self-center mx-auto">
                            <NavLink activeClassName="active" to="/account">
                                <div className="rounded-circle text-center user-ellypse">
                                    <img src={userIcon} />
                                </div>
                            </NavLink>
                        </div>
                    </div>
                    <div className="align-self-center user" />
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle className="align-self-center" nav caret />
                        <DropdownMenu right>
                            <DropdownItem>{user.email}</DropdownItem>
                            <DropdownItem>
                                <NavLink activeClassName="active" to="/account">
                                    Account
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink activeClassName="active" to="/billing">
                                    Billing
                                </NavLink>
                            </DropdownItem>

                            <DropdownItem divider />
                            <DropdownItem value={user.email} onClick={onSignOutClick}>
                                Sign Out
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div>
        );
        navButtons = (
            <>
                <NavItem className="align-self-center">
                    <NavLink className="mr-4 small-text gray" to="/my-proofs" activeClassName="active">
                        My Certificates
                    </NavLink>
                </NavItem>


                <NavItem className="align-self-center">
                    <NavLink className="mr-4 small-text gray" to="/my-text" activeClassName="active">
                        My Text
                    </NavLink>
                </NavItem>



                <NavItem className="align-self-center">
                    <NavLink className="mr-4 small-text gray" to="/certificates-bulk" activeClassName="active">
                        Bulk Upload
                    </NavLink>
                </NavItem>

                <NavItem className="align-self-center">
                    <a className="mr-4 small-text gray" href="https://help.proofplum.com">
                        Help
                    </a>
                </NavItem>
            </>
        );
        mobileNavButtons = (
            <>
                <NavItem className="col-12 py-3 pt-5">
                    <NavLink className="mr-4 small-text gray" to="/my-proofs" activeClassName="active">
                        My Certificates
                    </NavLink>
                </NavItem>

                <NavItem className="align-self-center">
                    <NavLink className="mr-4 small-text gray" to="/my-text" activeClassName="active">
                        My Text
                    </NavLink>
                </NavItem>

            </>
        );
    } else {
        mobileUserLogin = (
            <>
                <NavItem className="col-12">
                    <hr />
                </NavItem>
                <NavItem className="col-12 py-3">
                    <NavLink activeClassName="active" to="/sign-up">
                        Sign Up
                    </NavLink>
                    <NavLink activeClassName="active" to="/login">
                        Log In
                    </NavLink>
                </NavItem>
            </>
        );
        userLogin = (
            <div className="pl-5 align-self-center">
                <div className="row align-items-center">
                    <div className="align-self-center user">
                        <Link to="/sign-up" className="sign-in mr-4">
                            Sign Up
                        </Link>
                        <Link to="/login" className="sign-in">
                            Log In
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div>
            <div className="header">
                <Navbar className="navbar navbar-light  navbar-proof bg-proof" expand="md">
                    <div className="container">
                        <NavbarBrand className="navbar-brand" href="/">
                            <img className="mb-1 logo mr-3" src={logo} /> Proofplum
                        </NavbarBrand>
                        <NavbarToggler onClick={toggle} />
                        <Collapse isOpen={isOpen} navbar>
                            <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block ml-auto">
                                <Nav className="ml-auto justify-content-end">
                                    {navButtons}

                                    {userLogin}
                                </Nav>
                            </div>

                            <div className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
                                <Nav>
                                    <NavItem className="col-12">
                                        <hr />
                                    </NavItem>
                                    {mobileNavButtons}
                                    <NavItem className="col-12" />

                                    {mobileUserLogin}
                                    <NavItem className="col-12">
                                        <hr />
                                    </NavItem>
                                    <NavItem className="col-12 py-3">
                                        <a href="">FAQ</a>
                                    </NavItem>
                                    <NavItem className="col-12 py-3">
                                        <a href="">Twitter</a>
                                    </NavItem>
                                    <NavItem className="col-12 py-3">
                                        <a href="">Email Us</a>
                                    </NavItem>
                                </Nav>
                            </div>
                        </Collapse>
                    </div>
                </Navbar>
            </div>
        </div>
    );
}

export default withRouter(Header);
