import { Button, Form, FormGroup, Input } from 'reactstrap';
import React, { useState, useContext } from 'react';
import { login, passwordlessSignin, answerCustomChallenge, getUserCredits, recaptchaCheck } from '../../api';
import AppLoginDispatcherContext from '../../contexts/app-dispatch';

import Col from 'reactstrap/lib/Col';
import ViewState from './types';
import validator from 'email-validator';
import toastr from 'toastr';
import Label from 'reactstrap/lib/Label';
import { IUser } from '../../types';
import { RouteComponentProps, withRouter } from 'react-router';
import { labelStyle } from '../inline-styles';
import { RECAPTCHA_SITE_KEY } from '../../constants';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';


function useFormField(initialValue: string = '') {
    const [value, setValue] = useState(initialValue);
    function onChange(event: any) {
        setValue(event.target.value);
    }
    return {
        value,
        onChange,
    };
}

interface ILoginProps extends RouteComponentProps {
    initialEmail?: string;
}

function LoginForm(loginProps: ILoginProps) {
    const email = useFormField(loginProps.initialEmail || '');
    const dispatch = useContext(AppLoginDispatcherContext)!;
    const verificationCode = useFormField();
    const [beforeVerificationMailSend, setBeforeVerificationMailSend] = useState(true);
    const [returnedUser, setReturnedUser] = useState();
    const [showError, setShowError] = useState(false);
    const [disableContinueButton, setDisableContinueButton] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState();

    let recaptchaInstance: any;
    const executeCaptcha = async function() {
        const a = await recaptchaInstance.execute(RECAPTCHA_SITE_KEY, { action: 'Login' });
    };

    function onLoadRecaptcha() {
        executeCaptcha();
    }

    async function verifyCallback(response: any) {
        setRecaptchaToken(response);
    }


    async function onSubmit(event: any) {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        if (beforeVerificationMailSend) {
            try {
                dispatch({ type: 'SHOW_LOADER' });
                setDisableContinueButton(true);
                try{
                    const checkRecaptcha = await recaptchaCheck(RECAPTCHA_SITE_KEY!,recaptchaToken);
                    const result = await passwordlessSignin(email.value);
                    setReturnedUser(result);
                    setBeforeVerificationMailSend(false);
                    setDisableContinueButton(false);
                } catch(e){
                    dispatch({ type: 'SHOW_MESSAGE', payload: { message: "Your Email does not exist. Please signup before logging in. ", error: true } });
                }

            } catch (e) {
                dispatch({ type: 'SHOW_MESSAGE', payload: { message: e.message, error: true } });
            }
        } else {
            try {
                const result: any = await answerCustomChallenge(verificationCode.value, returnedUser);
                console.log(result);
                const user: IUser = { email: result.username, username: result.attributes.sub, fullName:result.attributes.name };
                localStorage.setItem('user', user.email ? user.email : '');
                setBeforeVerificationMailSend(true);
                dispatch({ type: 'LOGIN', payload: user });
                dispatch({ type: 'HIDE_LOADER' });
                const userCredit = await getUserCredits();
                dispatch({ type: 'SET_USER_CREDITS', payload: userCredit });
                loginProps.history.push('/my-proofs');
            } catch (e) {
                setShowError(true);
                return;
            }
        }
    }

    function validateForm() {
        let isFormValid = true;
        if (!validator.validate(email.value)) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Invalid Email', error: true },
            });
            isFormValid = false;
        }
        return isFormValid;
    }

    return (
        <Form onSubmit={onSubmit} noValidate>
            <FormGroup row>
                <Col xs={12} sm={12} md={12} lg={12}>
                    <Label style={labelStyle}>Email</Label>
                    <Input
                        name="email"
                        // placeholder="Email"
                        autoFocus
                        type="email"
                        value={email.value}
                        style={{
                            color: '#333333',
                            backgroundColor: '#E0E0E0',
                        }}
                        onChange={email.onChange}
                    />
                </Col>
            </FormGroup>
            {!beforeVerificationMailSend ? (
                <>
                    <div className="info-text">
                        We just sent you a temporary login code.
                        <br /> Please check your inbox.
                    </div>
                    <FormGroup>
                        <Input
                            className={showError ? 'input-error' : ''}
                            value={verificationCode.value}
                            onChange={verificationCode.onChange}
                            autoFocus
                        />
                    </FormGroup>
                    {showError ? (
                        <div className="error-text">This code is invalid. Try to copy and paste it again.</div>
                    ) : (
                        ''
                    )}
                </>
            ) : (
                ''
            )}

            <FormGroup>
                {disableContinueButton ? (
                    <Button disabled type="submit" className="btn btn-primary w-100 mx-auto">
                        Continue
                    </Button>
                ) : (
                    <>
                    <Button type="submit" className="btn btn-primary w-100 mx-auto">
                        Continue
                    </Button>
                    <ReCaptcha
                    ref={(e: any) => (recaptchaInstance = e)}
                    sitekey={RECAPTCHA_SITE_KEY}
                    action="registerProof"
                    size="invisible"
                    render="explicit"
                    // size="normal"
                    badge="bottomleft"
                    onloadCallback={onLoadRecaptcha}
                    verifyCallback={verifyCallback}
                />
                </>
                )}

            </FormGroup>
        </Form>
    );
}

export default withRouter(LoginForm);
