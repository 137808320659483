import { Button, Form, FormGroup, Input, FormText } from 'reactstrap';
import React, { useState, useContext } from 'react';
import {
    userRegistration,
    comfirmPassword,
    passwordlessSignup,
    answerCustomChallenge,
    putActionProof,
    getUserCredits,
} from '../../api';
import Col from 'reactstrap/lib/Col';
import ViewState from './types';
import validator from 'email-validator';
import toastr from 'toastr';
import appLoginDispatcher from '../../contexts/app-dispatch';
import Label from 'reactstrap/lib/Label';
import { IUser, IProof } from '../../types';
import { withRouter, RouteComponentProps } from 'react-router';
import { DeviceRegisteredPopup } from '../DeviceRegisteredPopup';

function useFormField(initialValue: string = '') {
    const [value, setValue] = useState(initialValue);
    function onChange(event: any) {
        setValue(event.target.value);
    }
    return {
        value,
        onChange,
    };
}

interface ISignUpProps extends RouteComponentProps {
    initialEmail?: string;
    changeState?: (state: ViewState) => void;
    modalToggle?: () => void;
    returnedUser?: any;
    afterSignupProofCreationParams?: Partial<IProof>;
    shouldRedirectAfterSignUp?: boolean;
}

function SignupForm(signUpProps: ISignUpProps) {
    const email = useFormField(signUpProps.initialEmail || '');
    const firstName = useFormField();
    const lastName = useFormField();
    const dispatch = useContext(appLoginDispatcher)!;
    const verificationCode = useFormField();
    const [showError, setShowError] = useState(false);
    const [beforeVerificationMailSend, setBeforeVerificationMailSend] = useState(!signUpProps.initialEmail);
    const [returnedUser, setReturnedUser] = useState();
    const [showDeviceRegisteredPopup, setShowDeviceRegisteredPopup] = useState(false);
    const [user, setUser] = useState();

    async function onDeviceMessageModalToggle() {
        setShowDeviceRegisteredPopup(false);
        await propogateUserToApp();
        signUpProps.history.push('/my-proofs');
    }

    async function propogateUserToApp(u = user) {
        dispatch({ type: 'LOGIN', payload: u });
        dispatch({ type: 'HIDE_LOADER' });
        const userCredit = await getUserCredits();
        if (userCredit) {
            dispatch({ type: 'SET_USER_CREDITS', payload: userCredit });
        }
    }

    async function onSubmit(event: any) {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }
        if (beforeVerificationMailSend) {
            try {
                dispatch({ type: 'SHOW_LOADER' });
                let fullName = firstName.value+' '+lastName.value;
                const result = await passwordlessSignup(email.value, fullName);
                setReturnedUser(result);
                setBeforeVerificationMailSend(false);
            } catch (e) {
                dispatch({
                    type: 'SHOW_MESSAGE',
                    payload: { message: 'Account with this eamil address already exist', error: true },
                });
            }
        } else {
            try {
                const result: any = await answerCustomChallenge(
                    verificationCode.value,
                    signUpProps.returnedUser || returnedUser
                );
                console.log(result);
                const user: IUser = {
                    email: result.username,
                    username: result.attributes.sub,
                    fullName: result.attributes.name,
                };
                localStorage.setItem('user', user.email ? user.email : '');
                setUser(user);
                setBeforeVerificationMailSend(true);
                if (signUpProps.afterSignupProofCreationParams) {
                    await putActionProof(signUpProps.afterSignupProofCreationParams);
                    setShowDeviceRegisteredPopup(true);
                } else {
                    await propogateUserToApp(user);
                    if (signUpProps.modalToggle) signUpProps.modalToggle();
                    if (signUpProps.shouldRedirectAfterSignUp !== false) {
                        signUpProps.history.push('/my-proofs');
                    }
                }
            } catch (e) {
                setShowError(true);
                dispatch({ type: 'SHOW_MESSAGE', payload: { message: e.message, error: true } });
            }
        }
    }

    function validateForm() {
        let isFormValid = true;
        if (!email.value || !firstName.value || !lastName.value) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'All fields are required', error: true },
            });
            isFormValid = false;
        }
        if (!validator.validate(email.value)) {
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: 'Invalid Email', error: true },
            });
            isFormValid = false;
        }

        return isFormValid;
    }

    return (
        <div className="">
            <Form onSubmit={onSubmit}>
                <FormGroup row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Label>Email</Label>
                        <Input
                            name="email"
                            placeholder="name@domain"
                            autoFocus
                            type="email"
                            style={{
                                color: '#333333',
                                backgroundColor: '#E0E0E0',
                            }}
                            value={email.value}
                            onChange={email.onChange}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Label>First Name</Label>
                        <Input
                            style={{
                                color: '#333333',
                                backgroundColor: '#E0E0E0',
                            }}
                            value={firstName.value}
                            onChange={firstName.onChange}
                        />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Label>Last Name</Label>
                        <Input
                            style={{
                                color: '#333333',
                                backgroundColor: '#E0E0E0',
                            }}
                            value={lastName.value}
                            onChange={lastName.onChange}
                        />
                    </Col>
                </FormGroup>
                {!beforeVerificationMailSend ? (
                    <>
                        <div>
                            We just sent you a temporary login code.
                            <br /> Please check your inbox.
                        </div>
                        <FormGroup row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Input
                                    placeholder="Paste login code"
                                    value={verificationCode.value}
                                    style={{
                                        color: '#333333',
                                        backgroundColor: '#E0E0E0',
                                    }}
                                    onChange={verificationCode.onChange}
                                    className={showError ? 'input-error' : ''}
                                />
                            </Col>
                        </FormGroup>

                        {showError ? (
                            <div className="error-text">This code is invalid. Try to copy and paste it again.</div>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    ''
                )}

                <FormGroup>
                    <Button type="submit" className="btn btn-primary w-100 mx-auto">
                        Continue
                    </Button>
                </FormGroup>
            </Form>
            {showDeviceRegisteredPopup ? (
                <DeviceRegisteredPopup
                    name={signUpProps.afterSignupProofCreationParams!.txMessage!}
                    modalToggle={onDeviceMessageModalToggle}
                />
            ) : (
                ''
            )}
        </div>
    );
}

export default withRouter(SignupForm);
