export const MY_PROOFS_FILTER = 1;

export const PROOF_ACTION_PRICE = 1;


export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const BASE_API_URL = process.env.REACT_APP_BASE_URL;

export const NETWORK = process.env.REACT_APP_NETWORK;

export const PAYPAL_CLIENTID = process.env.REACT_APP_PAYPAL_CLIENTID
