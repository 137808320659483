import React from 'react';
import MyTexts from './my-text';
import WithHeaderAndFooter from '../WithHeaderAndFooter';
import { AttachmentsOfProofs } from '../AttachmentsOfProofs';
export default function MyText() {
    return (
        <WithHeaderAndFooter>
            {/* <MyDevices /> */}
            <MyTexts/>
        </WithHeaderAndFooter>
    );
}
