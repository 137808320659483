import React from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { labelStyle } from '../inline-styles';
import { NETWORK } from '../../constants';

export interface IBlockchainDetailsProps {
    proof: any;
}
TimeAgo.locale(en);

export function AlgochainDetails(props: IBlockchainDetailsProps) {
    function convertTime(timestamp: any) {
        const timeAgo = new TimeAgo('en-US');
        const time = timeAgo.format(timestamp);
        const fullDate = new Date(timestamp).toDateString();
        return `${time} (${fullDate})`;
    }
    let href;
    if(NETWORK === "mainnet"){
         href = `https://algoexplorer.io/tx/${props.proof.txHash}`;
    }else{
         href = `https://${props.proof.network}.etherscan.io/tx/${props.proof.txHash}`;
    }

    return (
        <>
            <span style={labelStyle}>Blockchain details</span>
            <Container className="gray-background rounded pt-1 py-4 px-4">
                <Row>
                    <Col xs="12" sm="12" md="12" lg="5" >Transaction hash:</Col>
                    <Col xs="12" sm="12" md="12" lg="7"className="text-truncate">
                        {props.proof.txHash}
                    </Col>
                </Row>
                <Row className="pt-2">

                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="5">TimeStamp:</Col>
                    <Col xs="12" sm="12" md="12" lg="7"
                    >{convertTime(props.proof.timestamp)}</Col>
                </Row>
                <Row className="pt-2">

                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="5">Public Address:</Col>
                    <Col xs="12" sm="12" md="12" lg="7" className="text-truncate"
                    >{props.proof.publicAddress}</Col>
                </Row>
                <Row className="pt-2">

                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="7">
                        <a href={href} target="blank">
                            <i className="fas fa-external-link-alt"></i> More details on algoexplorer.io
                        </a>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
