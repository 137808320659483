import { Button } from 'reactstrap';
import React from 'react';

export function BlockchainVerify({ match }: { match: any }) {

    const certificateId = decodeURIComponent(match.params.id);
let src ='';
if(certificateId){
    src=`https://s3.amazonaws.com/pbox.link/${certificateId}.json`
}
 

    return (
        <blockcerts-verifier
            display-mode="card"
            allow-download
            src = {src}
            
        />
    );
}
