import React, { useState, useEffect, useContext } from 'react';
import Col from 'reactstrap/lib/Col';
import Form from 'reactstrap/lib/Form';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';
import Button from 'reactstrap/lib/Button';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import Grid from '../Grid';
import appLoginDispatcher from '../../contexts/app-dispatch';
import UserContext from '../../contexts/user';
import { getBillingHistory, creditUpdate, getUserCredits } from '../../api';
import UserCreditContext from '../../contexts/user-credit';
import { GridApi, GridOptions } from 'ag-grid-community';
import Row from 'reactstrap/lib/Row';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3';
import { RECAPTCHA_SITE_KEY, PAYPAL_CLIENTID } from '../../constants';
loadReCaptcha(RECAPTCHA_SITE_KEY);
import { PayPalButton } from 'react-paypal-button-v2';

// const script = document.createElement('script');

// // script.async = true;

// document.body.appendChild(script);

function useFormField(initialValue: number = 5) {
    const [value, setValue] = useState(initialValue);
    function onChange(event: any) {
        setValue(event.target.valueAsNumber as number);
    }
    return {
        value,
        onChange,
    };
}

export default function Billing() {
    const credit = useContext(UserCreditContext);
    const refillAmount = useFormField();
    const dispatch = useContext(appLoginDispatcher)!;
    const user = useContext(UserContext);
    const [billingGridData, setBillingGridData] = useState();
    const [billingGridOptions, setBillingGridOptions] = useState<GridOptions | null>(null);
    const [recaptchaToken, setRecaptchaToken] = useState();

    let recaptchaInstance: any;

    const billingHistoryColumnDefs: any = [
        {
            headerName: 'DATE',
            field: 'timestamp',
            cellRenderer: (params: any) => {
                const div = document.createElement('div');
                const time = new Date(params.value);
                div.textContent = time.toLocaleDateString();
                return div;
            },
        },
        {
            headerName: 'DESCRIPTION',
            field: 'txMessage',
            cellRenderer: (params: any) => {
                return `${params.value}-${params.data.txType}`;
            },
        },
        {
            headerName: 'AMOUNT',
            field: 'valueDecimal',
            cellRenderer: (params: any) => {
                return `$   ${params.value} `;
            },
        },
    ];

    const executeCaptcha = async function(action: string) {
        await recaptchaInstance.execute(RECAPTCHA_SITE_KEY, { action: action });
    };

    function onLoadRecaptcha() {
        executeCaptcha('paypalCredit');
    }

    async function verifyCallback(response: any) {
        setRecaptchaToken(response);
    }

    // useEffect(() => {
    //     script.innerHTML = `paypal.Buttons({ createOrder: function(data, actions) {return actions.order.create({ purchase_units: [{amount: {value: ${refillAmount} }}]});},onApprove: function(data, actions) {return actions.order.capture().then(function(details) {debugger;});}}).render('#paypal-button-container');`

    //   });

    const client = {
        sandbox: PAYPAL_CLIENTID,
    };

    async function onSuccess(details: any, data:any) {
        try{
            const creditUpdateMessage = await creditUpdate(data.orderID, recaptchaToken);
            const userCredit = await getUserCredits();
            dispatch({ type: 'SET_USER_CREDITS', payload: userCredit });
            dispatch({
                type: 'SHOW_MESSAGE',
                payload: { message: creditUpdateMessage, error: false },
            });
        }
        catch(e){

        }
    }

    const style = {
        color: 'silver',
        shape: 'rect',
        label: 'paypal',
        height: 40,
    };

    useEffect(() => {
        (async () => {
            if (user) {
                try {
                    const billHistory = await getBillingHistory();
                    setBillingGridData(billHistory);
                    billingGridOptions && billingGridOptions.api!.sizeColumnsToFit();
                } catch (e) {}
            }
        })();
    }, []);

    function onSubmit(event: any) {
        event.preventDefault();
    }

    function onGridReady(params: any) {
        setBillingGridOptions(params.options);
    }

    const billingHistory = {
        data: billingGridData,
        columnDefs: billingHistoryColumnDefs,
        onGridReady: onGridReady,
    };

    return (
        <>
            <Row>
                <Col sm="12" md="6" lg="5">
                    <div className="row border-bottom mt-5 mb-4">
                        <Col sm="6">
                            <span className="billing-large-text">Your credit</span>
                        </Col>
                        <Col sm="6" className="text-center">
                            <span className="billing-large-text bold">${credit.availableCreditDecimal!.toFixed(2)}</span>
                        </Col>
                    </div>
                    <div>
                        <div className="billing-large-text bold mb-3">PayPal</div>
                        <span className="small-gray">
                            Add PayPal credit to your account. This is a one-time payment and may take up to five
                            minutes to process.
                        </span>
                        <Form onSubmit={onSubmit} className="mb-5 mt-3">
                            <FormGroup>
                                <Label className="grid-request-info gray">AMOUNT</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                    <Input
                                        min={5}
                                        max={100}
                                        type="number"
                                        step="5"
                                        value={refillAmount.value}
                                        onChange={refillAmount.onChange}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <div id="paypal-button-container" />
                                <ReCaptcha
                                    ref={(e: any) => (recaptchaInstance = e)}
                                    sitekey={RECAPTCHA_SITE_KEY}
                                    action="paypalCredit"
                                    size="invisible"
                                    render="explicit"
                                    // size="normal"
                                    badge="bottomleft"
                                    onloadCallback={onLoadRecaptcha}
                                    verifyCallback={verifyCallback}
                                />
                                <PayPalButton
                                    style={{
                                        color: 'silver',
                                        shape: 'rect',
                                        label: 'paypal',
                                        height: 40,
                                    }}
                                    client={client}
                                    currency={'USD'}
                                    amount={refillAmount.value}
                                    onSuccess={onSuccess}
                                    options={{
                                        sandbox: PAYPAL_CLIENTID,

                                      }}
                                />

                                {/* <Button color="primary" type="submit" className="w-100">
                                    PayPal
                                </Button> */}
                            </FormGroup>
                        </Form>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <div className="container">
                        <span className="row billing-large-text bold">Billing History</span>
                        <div className="row pt-3">
                            <div
                                className="ag-theme-balham mt-3"
                                style={{
                                    height: '200px',
                                }}
                            >
                                <Grid options={billingHistory} />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}
